import { database } from '../Auth/firebase_config'

const hasPractitioner =  (patientId) =>{

    return new Promise(async (resolve, reject) => {
        try{
            const patientRef = database.ref(`/users/${patientId}/practitioner`)
            patientRef.on(
            'value',
            (snapshot) => {
                const data = snapshot.val()
                console.log('data', data)
                if (!snapshot.exists() || !data) {
                    resolve({ practitioner: null })
                    return
                }else{
                    resolve({ practitioner: Object.keys(data)[0]})
                    return
                }
            })

        }
        catch(err){
            reject(err)
        }
        
    })
    

}

export default hasPractitioner