import React, { useState, useEffect } from 'react';
import { makeStyles, Typography, Button, Checkbox } from '@material-ui/core'
import clsx from 'clsx'
import { useParams } from 'react-router-dom';
import { baseUrl } from '../../lib/config'

import axios from 'axios'
import ErrorNoCourse from '../../elements/ErrorNoCourse';
import Questions from './questions';

import sortArrayToPosition from '../../helper/sortArrayToPosition';
import generateRangeArray from '../../helper/generateRangeArray';

import LoadingSpinner from '../../elements/LoadingSpinner'
import AuthModal from './authModal';
import AlreadyCompleteModal from './alreadyCompleteModal';

import Notification from '../../elements/Notification'
import ErrorSignUp from '../../elements/ErrorAuth';
import ErrorSnackbar from '../../elements/ErrorSnackbar/ErrorSnackbar';

import {
    setPersistence,
    signin,
    signout,
    getCurrentUser,
} from '../../lib/Auth/index'
import {
    submitSurvey,
    checkSurveyComplete,
    fileDataExtraction,
    addIntakeFormFile
} from '../../lib/IntakeForm/index'

import { getPatientData } from '../../lib/Patient/index'


const useStyles = makeStyles((theme) => ({
    box:{
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
        justifyContent:"flex-start",
        [theme.breakpoints.down(500)]: {
            justifyContent:'center'
        },

    },
    creators:{
       color:"grey"
    },
    selected:{
       backgroundColor:"#f6f2ff",
    },
    warning:{
        width:'100%',
        borderRadius:'20px',
        backgroundColor: '#ffecec',
        padding:'40px'
    }, 
    warningText:{
        marginTop:'20px',
        fontWeight:'bold',
    },
    container: {
        padding:'40px 120px 40px 120px',
        [theme.breakpoints.down(800)]: {
            padding:'40px'
        },
        [theme.breakpoints.up(1300)]: {
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
            justifyContent:'center',
            gap:"30px"
        }
    },
    notification:{
        backgroundColor:"#f6f2ff",
        borderRadius:'20px',
        padding:'20px',
        marginTop:'20px',
        marginBottom:'20px',
        [theme.breakpoints.down(500)]: {
            textAlign:'center'
        }
    },
    titleAndAction:{
        // border:'1px solid red',
        width:'100%',
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'start',
        [theme.breakpoints.up(1300)]: {
            maxWidth:'1300px'
        },
        [theme.breakpoints.down(1100)]: {
            flexDirection:'column',
            alignItems:'start',
            gap:'15px'
        },
        [theme.breakpoints.down(800)]: {
            alignItems:'center',
            justifyContent:'center',
        }

    },
    titleAndAuthor:{
       display:'flex',
       flexDirection:'column',
       justifyContent:'flex-start',
       alignItems:'start',
       gap:'10px',
       [theme.breakpoints.down(500)]: {
            alignItems:'center',
            justifyContent:'center',
        }
    },
    actions:{
       display:'flex',
       flexDirection:'row',
       justifyContent:'center',
       alignItems:'center',
       gap:'15px',
       [theme.breakpoints.down(500)]: {
        flexDirection:'column',
       }
    },
    backButton:{
        backgroundColor:"#fff",
        color:"#000",
        border:'1px solid #000'
    },
    assignButton:{
        backgroundColor:"#000",
        color:"#fff",
        "&:hover":{
            backgroundColor:"#000",
        }
    },
    disabledButton:{
        backgroundColor:"#dcdcdc",
        cursor: 'not-allowed'
    },
    button:{
        cursor:'pointer',
        padding:'5px 30px',
        width:'200px',
        borderRadius:'20px',
    },
    questionsContainer:{
    //    padding:'40px',
       width:'100%',
       display:'flex',
       flexDirection:'column',
       justifyContent:'center',
       alignItems:'center',
       gap:'30px',
       marginTop:'30px',
       boxSizing:'border-box',
       [theme.breakpoints.up(1300)]: {
            maxWidth:'1300px'
        },
    },
    titleAndDescriptionContainer:{
        width:"100%",
        display:'flex',
        flexDirection:'row',
        borderRadius:'20px',
        // height:'fit-content',
        border:'1px solid #929292',
        marginTop:'30px',
        // padding:'20px',
        gap:"30px",

    },
    titleAndDescriptionContent:{
        // border:'1px solid red',
        width:'100%',
        padding:'40px',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        gap:'20px'
    },
    titleBox:{
        width:'100%',
        border:'1px solid grey',
        borderRadius:'25px',
        padding:'20px',
        minHeight:'50px',

    },
    clarify:{
       backgroundColor:"#f6f2ff",
       display:'flex',
       flexDirection:'row',
       justifyContent:'center',
       alignItems:'center',
       gap:'10px',
       cursor:"pointer"
    }, 
    colorBar:{
        width:'20px',
        height:'auto',
        backgroundColor:"#482787",
        borderTopLeftRadius:'20px',
        borderBottomLeftRadius:'20px',
        [theme.breakpoints.down(500)]: {
            display:'none'
        }


    },
    addQuestion:{
        marginTop:'30px',
        width:'100%',
        display:'flex',
        flexdirection:'row',
        justifyContent:'center',

    },
    authOuterContainer:{
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        textAlign:'center',
        padding:'40px 120px 40px 120px',

    },
    authContainer:{
        width:'100%',
        display:"flex",
        flexDirection:"row",
        gap:'15px',
        alignItems:'center',
        justifyContent:'space-between',
        border:'1px solid #000',
        borderRadius:'20px',
        padding:'20px',
        // margin:'30px 120px 30px 120px',
        [theme.breakpoints.up(1300)]: {
            // width: '100%',
            maxWidth:'1300px'
        },
        [theme.breakpoints.down(800)]: {
            flexDirection:'column',
            justifyContent:'center',
            margin:'30px'
        }

    },
    authText:{
        [theme.breakpoints.down(800)]: {
            display:'flex',
            flexDirection:'column',
            textAlign:'center',
            justifyContent:'center',
            gap:'15px'

        }
    }
}))

const IntakeForm = ({ }) => {

    const classes = useStyles()
    const { username, surveyId } = useParams()
    const [isLoading, setIsLoading] = useState(true)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [error, setError] = useState(false)
    const [state, setState] = useState({})
    const [answers, setAnswers] = useState([])
    const [checked, setChecked] = useState(false)
    const [versionId, setVersionId] = useState('')
    const [patientInfo, setPatientInfo] = useState({})
    const [practitionerInfo, setPractitionerInfo] = useState({
        id:"",
        email:"",
        name:""
    })

    const [isNewsletterSubscribed, setIsNewsletterSubscribed] = useState(false)
    const [authPopUp, setAuthPopUp] = useState(true)
    const [alreadyCompletePopUp, setAlreadyCompletePopUp] = useState(false)
    const [auth, setAuth] = useState('SignUp')
    const [notification, setNotification] = useState("")
    const [authError, setAuthError] = useState("")   
    const [submitError, setSubmitError] = useState("")

    // Sign in and sign up state:
    const [signUpData, setSignUpData] = useState({
        FirstName: "",
        Email: "",
        ConfirmEmail: "",
        Password: "",
        ConfirmPassword: "",
        Birthday: ""
    })
    const [signInData, setSignInData] = useState({
        Email: "",
        Password: ""
    })

    // submit the survey when survey is completed:
    const handleSubmitSurvey = ()=>{

        if(!checkFields(answers)){
            setSubmitError("Please answer all the questions in this form.")
            return;
        }

        if(!checked){
            setSubmitError("Please check off the disclaimer on top of the form.")
            window.scrollTo({
                top: 0,
                behavior: "smooth"
              });
            return;
        }

        setSubmitLoading(true)


        // data that will be passed into recommendation table:
        const assignObj = {
            practitionerId: practitionerInfo?.id,
            surveyTitle: state.title,
            surveyDescription: state.description,
            isFreeForm: state.isFreeForm,
            surveyId:surveyId,
            versionId: versionId,
            answers: answers,
            completed: true,
            assignDate: 'None',
            completedDate: new Date().toISOString(),
            content:state.content
        };

        // data that will be used as email:
        const emailObj ={
            clientName:patientInfo?.user?.firstName,
            coachEmail: practitionerInfo?.email, 
            coachName: practitionerInfo?.name, 
            intakeFormName: state.title,
        }

        const params = {
            assignObj,
            emailObj
        }

        console.log('params', params)

        submitSurvey(patientInfo?.patientId, practitionerInfo?.id, params)
            .then((res) => {
                console.log('res', res)
                setNotification("Congratulations! the survey is completed.")
                setSubmitLoading(false)
                setAlreadyCompletePopUp(true)
            })
            .catch((err) => console.log('submitSurvey::error - ', err))


    }



    // user sign up:
    const signUpHandler = async () => {

        const { FirstName, Email, ConfirmEmail, Password, ConfirmPassword, Birthday } = signUpData

        console.log('signUpData', signUpData)
        if (FirstName.length &&
            Email.length &&
            ConfirmEmail.length &&
            Password.length &&
            ConfirmPassword.length &&
            Birthday.length) {

            let params = {
                email: ConfirmEmail,
                password: Password,
                data: { name: FirstName, birth: Birthday },
                isNewsletterSubscribed: isNewsletterSubscribed
            }

            await axios.post(
                `${baseUrl}/flokaLanding/newPaymentAccount`,
                params
            )
                .then((res) => {
                    console.log('res data', res)
                    setPatientInfo({
                        email: res?.data?.info?.email,
                        patientId: res?.data?.info?.id,
                        user:{
                            firstName:res?.data?.info?.name
                        }
                    })
                    setNotification('Account Successfully Created! ')
                    setAuth('auth')
                    setPersistence(Email, Password)

                })
                .catch((err) => {
                    console.log(err)
                    let errorMessage =
                        err?.response?.data?.error ||
                        'Account creation failed'

                    setAuthError(errorMessage)

                })

        } else {
            console.log('missing data')
            setAuthError('All fields are required')
            return;
        }

    }

    // user sign in:
    const signInHandler = () => {

        if (signInData.Email && signInData.Password) {

            signin(signInData.Email, signInData.Password)
                .then((res) => {

                    getPatientData(res)
                        .then(async response => {
                            console.log('response', response)
                            setNotification(`Sign in as ${signInData.Email}!`)
                            setAuth('auth')
                            const res = await checkSurveyComplete(response?.patientId,  {username, surveyId })
                            console.log('res', res)

                            if(res){
                                setAuth('auth')
                                setAuthPopUp(false)
                                setAlreadyCompletePopUp(true)
                            }

                            setPersistence(signInData.Email, signInData.Password)

                        })

                })
                .catch((error) => {
                    console.log(error)
                    setAuthError('Sign in error: check your email and password')
                })
        } else {
            console.log('missing data')
        }


    }

    // user sign out:
    const signOutHandler = () => {

        try {

            signout()
            setNotification('Sign out! Come back soon..')
            setAuth('SignIn')

        } catch (err) {
            setAuthError('error')

        }

    }

    // uploading file for question of type 'file' and will be saved into S3 bucket:
    const uploadFiles = async (file, index, name) =>{
        const { fileData } = await fileDataExtraction(file);
        console.log(index, name)

        addIntakeFormFile(patientInfo?.patientId, fileData)
            .then((res)=>{
                console.log('res', res)
                const fileObj = {
                    name:name,
                    url: res.data
                }
                fillAnswers(index, fileObj)
            })
            .catch((err) => console.log('add_intakeform_file::error - ', err))
    }


    // form validation before form submission:
    const checkFields = (answers) =>{
        if(answers.length === 0){
            return false
        }
        for(let i=0; i<answers.length; i++){
            if(answers[i] ==='' || answers[i] === null){
                return false
            }
        }
        return true
    }

    // fill answers array with user's answers:
    const fillAnswers = (index, value) =>{
        const newAnswers =
        answers.length>0? [...answers]: 
        new Array(state.content.length).fill('');
        newAnswers[index] = value
        setAnswers(newAnswers)
    }

    // loading the survey based on username and surveyId:
    const initialRequest = async () => {
        setIsLoading(true)

        await axios.get(
            `${baseUrl}/flokaLanding/survey/${username}/${surveyId}`
        )
            .then(res => {
                console.log('res', res)
                setState(res?.data?.survey)
                setPractitionerInfo(res?.data?.practitioner)
                setVersionId(res?.data?.versionId)
            })
            .catch(err => {
                console.log("error", err)
                if(err?.response?.data?.error === 'Username cannot be found' ||
                   err?.response?.data?.error === 'Survey cannot be found' 
                ){
                    setError(true)
                }
            })


        setIsLoading(false)
    }

    // persistence of signed in state when page refreshed:
    const handleRefreshUser = (data) => {
        console.log('data', data)
        if (data) {
            getPatientData(data.id)
                .then(async response => {
                    console.log('response', response)
                    setNotification(`Signed in as ${data.email}!`)
                    setPatientInfo({...response, email: data.email})

                    // check if a survey is already completed by the current user, if yes,
                    // user will not be able to edit the survey, and an pop up will be shown
                    const res = await checkSurveyComplete(response?.patientId, {username, surveyId})
                    console.log('res', res)

                    if(res){
                        setAuth('auth')
                        setAuthPopUp(false)
                        setAlreadyCompletePopUp(true)
                    }

                    
                    
                    setAuth('auth')
                    setPersistence(signInData.Email, signInData.Password)
                })
                .catch((err) => {
                    console.log('error',err)
                })
        }

    }

    useEffect(() => {
        initialRequest()
    }, [])

    useEffect(()=>{

        getCurrentUser(handleRefreshUser)

    }, [])

    console.log('patientInfo', patientInfo)

    if(error){
        return <ErrorNoCourse />
    }

    if(isLoading){
        return <LoadingSpinner />
    }

    return (
        <>
            <ErrorSnackbar error={submitError} onClose={()=>setSubmitError("")} />
            <ErrorSignUp text={authError} onClose={()=>setAuthError("")} />
            <Notification text={notification} onClose={()=>setNotification("")} />
            {
                authPopUp 
                    && 
                auth !== 'auth' 
                    &&
                <AuthModal 
                    title={state.title}
                    open={authPopUp}
                    auth={auth}
                    coachName={practitionerInfo?.name}
                    onClose={()=>setAuthPopUp(false)}
                    setAuth={setAuth} 
                    signUpData={signUpData}
                    signInData={signInData}
                    setSignUpData={setSignUpData}
                    setSignInData={setSignInData}
                    signUpHandler={signUpHandler}
                    signInHandler={signInHandler}
                    signOutHandler={()=>{}}
                    isNewsletterSubscribed={isNewsletterSubscribed}
                    setIsNewsletterSubscribed={setIsNewsletterSubscribed}
                />
            }
            {
                alreadyCompletePopUp
                    &&
                <AlreadyCompleteModal 
                    open={alreadyCompletePopUp}
                    redirectUrl={state.redirectUrl}
                    signOut={signOutHandler}
                    setAuthPopUp={setAuthPopUp}
                    onClose={()=>setAlreadyCompletePopUp(false)}
                    coachName={practitionerInfo?.name}
                />
            }
            {
                auth === 'auth'
                    &&
                <div className={classes.authOuterContainer}>
                    <div className={classes.authContainer}>
                        <div className={classes.authText}>Sign in as: &nbsp; <b>{patientInfo?.email}</b></div>
                        <Button
                            variant="contained"
                            color='primary'
                            onClick={signOutHandler}
                        >
                            Sign Out
                        </Button>

                    </div>
                </div>
            }
            <div className={classes.container}>
                <div className={classes.titleAndAction}>
                    <div className={classes.titleAndAuthor}>
                        <Typography variant="h4">{state.title}</Typography>
                        {/* <div className={classes.creators}> By {practitionerName}</div> */}
                    </div>
                     <div className={classes.actions}>
                        <Button
                           variant="contained"
                           className={ (!checkFields(answers) || !checked)?clsx(classes.button, classes.assignButton, classes.disabledButton): clsx(classes.button, classes.assignButton)}
                           onClick={handleSubmitSurvey}
                           disabled={submitLoading}
                        >
                          {submitLoading?  `Submitting`: `Submit Intake Form`}
                        </Button>
                    </div>
                </div>

                <div className={classes.titleAndDescriptionContainer}>
                    <div className={classes.colorBar}></div>
                    <div className={classes.titleAndDescriptionContent}>
                        <div className={classes.titleBox}>
                            {state.description}
                        </div>

                        <div className={clsx(classes.titleBox, classes.clarify)}
                            onClick={()=>{
                                setChecked(!checked)
                            }}
                        >
                            <Checkbox
                                checked={checked}
                                onChange={(e)=>{
                                    setChecked(e.target.checked)
                                }}
                            />
                            <div>I understand that once I complete and submit this intake form, I will 
                            be connected with the provider who has sent this form. I may disconnect from this provider
                            and stop sharing the information with this provider at any time. </div>
                        </div>

                    </div>
                </div>

                <div className={classes.questionsContainer}>
                    {state?.content?.map((item, index)=>{
                        return (
                           <Questions 
                               key={index}
                               item={item}
                               index={index}
                               answers={answers}
                               fillAnswers={fillAnswers}
                               sortArrayToPosition={sortArrayToPosition}
                               generateRangeArray={generateRangeArray}
                               uploadFiles={uploadFiles}
                           />
                        )
                    })}
                </div>

                <div className={classes.addQuestion}>
                        <Button
                           variant="contained"
                           className={ (!checkFields(answers) || !checked)?clsx(classes.button, classes.assignButton, classes.disabledButton): clsx(classes.button, classes.assignButton)}
                           onClick={handleSubmitSurvey}
                           disabled={submitLoading}
                        >
                            {submitLoading?  `Submitting`: `Submit Intake Form`}
                        </Button>

                </div>

            </div>


        </>
    )
}


export default IntakeForm
