import { projectAuth } from './firebase_config'

/**
 * Signin current login patient
 */
const signin = (email, pass) => {
    return new Promise((resolve, reject) => {
        projectAuth
            .signInWithEmailAndPassword(email, pass)
            .then((res) => {

                const { user } = res
                if(!user.emailVerified){
                    throw new Error('Please verify your account, check your email.')
                }



                
            
             
                resolve(user.uid)
            })
            .catch(function (error) {
                reject(error.message)
            })
    })
}

export default signin
