import React, {
    useEffect
} from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    grid: {
        alignItems: 'center',
        minHeight: '100vh',
    },
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    bottom: {
        color: '#AEBAF8',
    },
    top: {
        color: theme.palette.primary.main,
        animationDuration: '550ms',
        marginLeft: '-50px',
    },
    circle: {
        strokeLinecap: 'round',
        color: '#DBDBDB'
    },
    label: {
        color: '#AEBAF8',
        marginLeft: '15px',
        fontWeight: '600'
    },
}))

function Redirect(props) {
    const classes = useStyles()
    const { position, top, left } = props

    useEffect(() => {

        setTimeout(()=> {
            window.location.href = 'https://floka.co'
        }, "2500")
    }, [])

    return (
        <Grid
            container
            spacing={2}
            direction="column"
            justifyContent="center"
            className={classes.grid}
        >
            <div
                className={classes.root}
                style={{ position: position, top: top, left: left }}
            >
                <CircularProgress
                    variant="determinate"
                    className={classes.bottom}
                    size={50}
                    thickness={4}
                    value={100}
                />
                <CircularProgress
                    variant="indeterminate"
                    disableShrink
                    className={classes.top}
                    classes={{
                        circle: classes.circle,
                    }}
                    size={50}
                    thickness={4}
                />
                <Typography variant="h6" className={classes.label}>
                    Loading...
                </Typography>
            </div>
        </Grid>
    )
}

export default Redirect
