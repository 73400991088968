import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Box, Checkbox } from '@material-ui/core';
import './payment.css'

const useStyles = makeStyles((theme) => ({
    informationContainer: {
        // fontFamily:"Apercu",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: "20px",
        padding: '20px',
        [theme.breakpoints.down(600)]: {
            padding: "0px"
        },
    },
    sectionTitleContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start'
    },
    sectionTitle: {
        fontFamily: "Value Serif Pro",
        fontSize: '16px',
        fontWeight: "bold",
        color: 'black',
        textAlign: 'left',
        marginBottom: '15px',
    },
    inputContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
        marginBottom: '12px',
        // border:"1px solid red",

    },
    inputLabel: {
        textAlign: 'left',
        fontWeight: 'normal ',
        fontSize: "14px",
        marginBottom: '3px'
    },
    courseNameInput: {
        width: '90%',
        padding: '10px',
        fontSize: '13px',
        borderRadius: '5px',
        border: '1px #c5c5c5 solid',

        '&:focus': {
            outline: 'none',
            border: "1px #000 solid",
        }
    },
    checkBoxContainer: {
        width: '100%',
        display: "flex",
        flexDirection: "row",
        justifyContent: 'start',
        alignItems: 'center',
        fontSize: "12px",
        height: "20px",
        marginBottom: "40px",
        [theme.breakpoints.down(700)]: {
            flexDirection: "column",
            justifyContent: 'center',
            alignItems: 'flex-start',
            marginBottom: "60px"
        },
        [theme.breakpoints.down(400)]: {
            alignItems: 'center',
        },
    },
    checkbox: {
        paddingLeft: '0px',
        fontSize: "14px",
        textAlign: 'center',
        [theme.breakpoints.down(700)]: {
            paddingBottom: "0px"
        },
    },
    checkBoxText: {
        [theme.breakpoints.down(700)]: {
            textAlign: 'left'
        },
        [theme.breakpoints.down(400)]: {
            textAlign: 'center'
        },
    },
    unMatchWord: {
        fontSize: "12px",
        marginTop: "15px",
        color: "red"
    },
    buttonContainer: {
        width: "60%",
        marginTop: "30px",
        borderRadius: '25px',
        backgroundColor: "#000"
    },
    termOfService: {
        fontFamily: "Playfair Display",
        marginTop: '15px',
        fontSize: '15px',
        padding: "0px 15px 10px 15px"
    },
    root: {
        color: "#000",
        // textDecoration:'none'
    },

}))

const SignUpSection = ({
    isNewsletterSubscribed,
    setIsNewsletterSubscribed,
    inputHandlerFunction,
    saveAbandonedCartEmail,
    isEmailSame,
    isPasswordSame,
    signUpHandler,
    signUpData,
    auth
}) => {

    const classes = useStyles();
    const stepSignUp = ["First Name", "Confirm Email", "Password", 'Confirm Password', "Birthday"]
    const placeHolderObj = {
        "First Name": 'First and Last Name',
        "Email": 'example@gmail.com',
        "Confirm Email": 'example@gmail.com',
        "Password": 'Enter Password',
        'Confirm Password': 'Re-enter Password',
        "Birthday": 'Birthday'
    }

    return (
        <>
            <div className={classes.informationContainer}>
                <div className={classes.sectionTitleContainer}>
                    <div className={classes.sectionTitle}>
                        Contact Information
                    </div>
                </div>
                <div className={classes.inputContainer}>
                    <div className={classes.inputLabel}>
                        Email Address
                    </div>
                    <input
                        type={'text'}
                        className={classes.courseNameInput}
                        placeholder={"example@gmail.com"}
                        onChange={(e) => inputHandlerFunction(e.target.value, "Email", auth)}
                        onBlur={(e) => saveAbandonedCartEmail(e.target.value)}
                    />
                </div>

                <div className={classes.checkBoxContainer}>

                    <Checkbox
                        className={classes.checkbox}
                        onChange={(e) => {
                            setIsNewsletterSubscribed(e.target.checked)
                        }}
                    />

                    <div className={classes.checkBoxText}>
                        I would like to receive product update emails & newsletters from Flöka
                    </div>
                </div>




                <div className={classes.sectionTitleContainer}>
                    <div className={classes.sectionTitle}>
                        Account Information
                    </div>
                </div>

                {stepSignUp && stepSignUp.map((step, idx) => (

                    <div key={idx} className={classes.inputContainer}>
                        <div className={classes.inputLabel}>
                            {step}
                        </div>

                        <input
                            type={
                                step === 'Birthday' ?
                                    'date' :
                                    (step === 'Email' || step === "Confirm Email") ?
                                        'email' :
                                        (step === 'Password' || step === "Confirm Password") ?
                                            "password" : "text"
                            } className={classes.courseNameInput}
                            onChange={(input) => inputHandlerFunction(input.target.value, step.replace(" ", ""), auth)}
                            placeholder={placeHolderObj[step]}
                        />
                        {
                            step === 'Confirm Email'
                            &&
                            !isEmailSame()
                            &&
                            signUpData.Email
                            &&
                            signUpData.ConfirmEmail
                            &&
                            <div className={classes.unMatchWord}>The Email does not match</div>
                        }

                        {
                            step === 'Confirm Password'
                            &&
                            !isPasswordSame()
                            &&
                            signUpData.Password
                            &&
                            signUpData.ConfirmPassword
                            &&
                            <div className={classes.unMatchWord}>The Password does not match</div>
                        }

                    </div>

                ))}

                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => signUpHandler()}
                    aria-label="assign-program"
                    disabled={
                        !isEmailSame()
                        || !isPasswordSame()
                    }
                    className={classes.buttonContainer}
                >
                    Continue
                </Button>

                <Typography component="div" className={classes.termOfService}>
                    <Box
                        fontStyle="normal"
                        variant="body1"
                        align="center"
                        paragraph="true"
                    >
                        By proceeding to sign up and use the Flöka
                        Patient Portal, you are agreeing to
                        our&nbsp;
                        <a
                            href="https://www.floka.co/terms-of-use"
                            target="_blank"
                            className={classes.root}
                        >
                            Terms and Conditions,
                        </a>
                        &nbsp;and &nbsp;
                        <a
                            href="https://www.floka.co/privacy"
                            target="_blank"
                            className={classes.root}
                        >
                            Privacy Policy
                        </a>

                    </Box>
                </Typography>
            </div>

            <br />
            <br />
        </>
    )
}


export default SignUpSection