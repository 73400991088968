import axios from 'axios';
import { baseUrl } from '../config';

const DisconnectPractitoner =(practitionerId, patientId)=>{

    return new Promise(async (resolve, reject) => {

        let params = {
            practitionerId, 
            patientId
        };
        axios.post(`${baseUrl}/flokaLanding/disconnectPractitioner`,params)
            .then((res)=>{
                resolve(res)
            })
            .catch((err)=>{
                reject(err)
            })
    })

}


export default DisconnectPractitoner