import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { addEllipsis } from '../../helper/stringHelper';


const useStyles = makeStyles((theme) => ({

    module:{
        width:"500px",
        border:"1px solid #6e6f71",
        borderRadius:'15px',
        display:"flex",
        flexDirection:"row",
        justifyContent:'center',
        alignItems:"start",
        backgroundColor:"#fff",
        padding:'20px',
        gap:'20px',
        [theme.breakpoints.down(700)]: {
            flexDirection:"column",
            gap:'10px',
        },
        [theme.breakpoints.down(600)]: {
           width:"90%"
        },


    },
    moduleIndex:{
        fontFamily:"Poppins",
        fontSize:"22px",
        color:"#5d35a9",
        width:'120px',
        [theme.breakpoints.down(700)]: {
            fontSize:"20px",
        },
    },
    moduleDetail:{
        width:"350px",
        fontFamily:'Lora',
        [theme.breakpoints.down(700)]: {
            width:"95%",
        },

    },
    moduleName:{
        fontSize:"25px",
        fontWeight:'bold',
        // fontFamily:"Lora",
        [theme.breakpoints.down(700)]: {
            fontSize:"20px"
        },
    },
    moduleDescription:{
        fontFamily:"Poppins",
        marginTop:'10px',
        whiteSpace:"pre-wrap",
        [theme.breakpoints.down(700)]: {
            fontSize:"12.5px"
        },
    },
    expand:{
        color:"#6576cf",
        cursor:"pointer",
        marginLeft:"10px"
    }
}))


const Module = ({ module, moduleName, number }) =>{

    const classes = useStyles();
    const [isExpanded, setisExpanded] = useState(false)

    return (
        <div className={classes.module}>
            <div className={classes.moduleIndex}>
                Module {number}
            </div>
            <div className={classes.moduleDetail}>
                <div className={classes.moduleName}>{moduleName}</div>
                <div className={classes.moduleDescription}>
                    {
                    isExpanded?
                        module.moduleDescription:
                        addEllipsis(200,module.moduleDescription)   
                    }
                    {/* {module.moduleDescription.length>200
                      &&
                    <span 
                        className={classes.expand}
                        onClick={()=>setisExpanded(!isExpanded)}
                    >
                      {isExpanded? 'Close':  "See More"}
                    </span>
                    } */}
                </div>
            </div>

        </div>
    )
}

export default Module