import mixpanel from 'mixpanel-browser'

/** Initialize mixpanel object with web portal project token */
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN)

/** Only track events if it is in production mode */
// let isProdMode = process.env.REACT_APP_NODE_ENV === 'production'
let isProdMode = true

let actions = {
    identify: (id) => {
        if (isProdMode) mixpanel.identify(id)
    },
    alias: (id) => {
        if (isProdMode) mixpanel.alias(id)
    },
    track: (name, props) => {
        if (isProdMode) mixpanel.track(name, props)
    },
    people: {
        set: (props) => {
            if (isProdMode) mixpanel.people.set(props)
        },
    },
    reset: () => {
        if (isProdMode) mixpanel.reset()
    },
}

export let Mixpanel = actions
