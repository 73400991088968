import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { Divider, Typography } from '@material-ui/core'

import notfound from '../../assets/notfound.gif'
import flokaLogo from '../../assets/Logo-floka.png'

const useStyles = makeStyles((theme) => ({
    backgroundColor: {
        backgroundColor: '#000',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    errorText: {
        textAlign: 'center',
        fontFamily: "Value Serif Pro",
        fontSize: '30px',
        color: 'darkslategray'
    },
    subText: {
        fontSize: '10px'
    },
    header:{
        color:"#fff",
        fontSize:"50x",
        marginTop:""
    },
    directBtn:{
        color:"#000",
        backgroundColor:"#fff",
        borderRadius:"2px",
        cursor:'pointer',
        padding:"5px 10px",
        marginTop:"70px"

    },
    notify:{
        marginTop:"70px",
        color:"#fff",
        fontSize:"23px",
        textAlign:"center",
        [theme.breakpoints.down(800)]: {
            marginLeft:"15px",
            marginRight:"15px"
        },
    },
    img:{
        marginTop:"70px",
        cursor:"pointer",
        width:"60px"
    }
}))

function ErrorNoUsername({ username }) {
    const classes = useStyles()

    return (

        <div className={classes.backgroundColor}>

            <Typography variant='h4' className={classes.header}>
                Page Not found
            </Typography>

            <div 
                className={classes.directBtn} 
                onClick={()=>{
                    window.open('https://www.floka.co/')
                }}
            >
                <Typography variant='h5'>
                    Proceed to floka.co
                </Typography>
            </div>

            <div className={classes.notify}>
                Oops! There may be a typo in this URL- feel free to try again. 
            </div>

            <img 
                src={flokaLogo} 
                alt='floka-logo' 
                className={classes.img}
                onClick={()=>{
                    window.open('https://www.floka.co/')
                }}
            />
            
        


        </div>

    )
}

export default ErrorNoUsername
