import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { addEllipsis } from '../../helper/stringHelper';

import CollapseElement from '../../elements/CollapseElement';

const useStyles = makeStyles((theme) => ({

    faq:{
        padding:"100px 100px",
        [theme.breakpoints.down(700)]: {
            padding:"80px 50px"
        },
        [theme.breakpoints.down(400)]: {
            padding:"80px 25px"
        },
    },
    faqTitle:{
        textAlign:'center',
        fontFamily:'Lora',
        fontSize:"55px",
        fontStyle:"italic",
        [theme.breakpoints.down(700)]: {
            fontSize:"35px",
        },
    },
    faqContainer:{
        marginTop:"60px",
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        flexWrap:"wrap",
        gap:"20px"
    }
}))

const FAQ  =({ state }) =>{

    const classes = useStyles();
    console.log('state', state)


    return (
        <div className={classes.faq} id='faq'>
            <div className={classes.faqTitle}>FAQs</div>

            <div className={classes.faqContainer}>
                {state.courseOverview.FAQs
                    .map((faq, index) => {
                        return (<CollapseElement key={index} faq={faq} />)
                })}


            </div>

        </div>
    )
}

export default FAQ;