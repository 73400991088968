import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import Typography from '@material-ui/core/Typography'
import Alert from '@material-ui/lab/Alert'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
    root: {
        color: '#FFF',
    },
    alert: {
        backgroundColor: theme.palette.tertiary.main,
    },
}))

function Notification(props) {
    const classes = useStyles()
    const { text, onClose } = props

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={text !== ''}
            onClose={onClose}
            autoHideDuration={3000}
            className={classes.root}
        >
            <Alert variant="filled" className={classes.alert} onClose={onClose}>
                <Typography variant="body1">
                    <b>{text}</b>
                </Typography>
            </Alert>
        </Snackbar>
    )
}

export default Notification
