import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import clsx from  'clsx';


const useStyles = makeStyles((theme) => ({
    informationContainer:{
        display:'flex',
        flexDirection:"column",
        justifyContent:'center',
        alignItems:'center',
        marginTop:'25px'
    },
    sectionTitleContainer:{
        width:'80%',
        display:'flex',
        flexDirection:'row',
        justifyContent:'start'
     },
     sectionTitle: {
         fontSize: '16px',
         fontWeight:"bold",
         color: 'black',
         textAlign: 'left',
         marginBottom: '15px',
     },
     inputContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '80%',
        marginBottom: '12px',
        // border:"1px solid red",

    },
    inputLabel: {
        textAlign: 'left',
        fontWeight: 'normal ',
        marginBottom: '3px'
    },
    courseNameInput: {
        width: '90%',
        padding: '10px',
        fontSize: '13px',
        borderRadius: '5px',
        border: '1px #c5c5c5 solid',

        '&:focus': {
            outline: 'none',
            border: "1px #000 solid",
        }
    },
    statement:{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap:'15px',
        width: '80%',
        fontSize:"14px",
        marginTop:'25px',
        marginBottom: '12px',
        textAlign:'left'
    },
    redirectText:{
        fontSize:"12px",
        color:"#6e6f71",
        lineHeight:'1.8'
    },
    agreementLink:{
        textDecoration:"underline",
        cursor:"pointer",
        fontWeight:'bold',
    },
    buttonContainer:{
        width:"60%",
        marginTop:"30px",
        marginBottom:'30px',
        borderRadius:'25px',
        backgroundColor:"#000"
    },
}))

const Afterpay = () =>{
    const classes = useStyles();
    const stepSignUp = ["First & Last Name", "Country/Region", "Address Line"]
    const placeHolderObj ={
        "First & Last Name": 'First and Last Name',
        "Country/Region": 'Canada/BC',
        "Address Line":'address'
    }

    return (
        <>
           <div className={classes.informationContainer}>
                <div className={classes.sectionTitleContainer}>
                    <div className={classes.sectionTitle}>
                        Billing Information:
                    </div>
                </div>

                {stepSignUp.map((step, idx) => (

                    <div key={idx}className={classes.inputContainer}>
                        <div className={classes.inputLabel}>
                            {step}
                        </div>

                        <input  
                            type="text"  
                            className={classes.courseNameInput} 
                            onChange={(input) => {}} 
                            placeholder={placeHolderObj[step]}
                        />
                    </div>

                ))}

                <div className={classes.statement}>
                    <div>
                        Pay in 4 interest-free installements of <strong>[amount]</strong> each.
                    </div>
                    <div className={classes.redirectText}>
                        You will be redirected to the Afterpay 
                        website to fill out your payment information. 
                        You will be redirected to our site to complete your order.
                        <span className={classes.agreementLink}>Instalment Agreement.</span>
                        
                    </div>
                </div>

                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {}}
                    aria-label="assign-program"
                    className={classes.buttonContainer}
                    disabled={false}
                >
                    Complete My Order
                </Button>
           </div>
        </>
    )
}

export default Afterpay