import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { addEllipsis } from '../../helper/stringHelper';

import PaymentButton from './PaymentButton';
import ConsultationButton from './consultationButton';

import irregular1 from '../../assets/irregular1.png'
import irregular2 from '../../assets/irregular2.png'
import irregular3 from '../../assets/irregular3.png'
import irregular4 from '../../assets/irregular4.png'
import irregular5 from '../../assets/irregular5.png'
import irregular6 from '../../assets/irregular6.png'

const shapeArr = [irregular1, irregular2, irregular3, irregular4, irregular5, irregular6]

const useStyles = makeStyles((theme) => ({

    learning:{
        padding:"80px 30px",
        display:'flex',
        flexDirection:'column',
        justifyContent:"center",
        alignItems:'center'
    },
    learningTitle:{
        textAlign:'center',
        fontFamily:'Lora',
        fontSize:"40px",
        fontStyle:"italic",
        [theme.breakpoints.down(700)]: {
            fontSize:"35px",
        },
    },
    learningSubTitle:{
        textAlign:'center',
        fontFamily:'Lora',
        fontSize:"25px",
        fontStyle:"normal",
        fontFamily:"Poppins",
        marginTop:"20px",
        [theme.breakpoints.down(700)]: {
            fontSize:"20px",
        },
    },
    learningContainer:{
        // border:'1px solid red',
        marginTop:"80px",
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        flexWrap:"wrap",
        gap:"55px",
        [theme.breakpoints.down(700)]: {
            marginTop:"50px"
        },
        [theme.breakpoints.up(2500)]: {
            width:"50%"
        },
    },
    learningItem:{
        fontFamily:"Poppins",
        lineHeight:"1.2",
        border:"3px solid #d7cdea",
        borderRadius:'20px',
        width:"300px",
        height:'300px',
        textAlign:"center",
        padding:"",
        [theme.breakpoints.down(800)]: {
            lineHeight:"1.2",
        },
        [theme.breakpoints.down(500)]: {
            width:"250px",
            height:'250px',
        },

    },
    order:{
        fontSize:"50px",
        fontWeight:"bold",
        marginBottom:"10px",
        position: "relative",
        top:"-40px",
        // border:'1px solid red',
        // [theme.breakpoints.down(500)]: {
        //     fontSize:"40px",
        // },
        [theme.breakpoints.down(500)]: {
            fontSize:"30px",
            marginBottom:"0px",
        },

    },
    objective:{
        // border:"1px solid red",
        fontSize:'15px',
        padding:'10px 20px',
        marginTop:"0px",
        [theme.breakpoints.down(500)]: {
            padding:'0px 15px 5px 15px',
            fontSize:'14px',
            marginBottom:"20px"
        },
    },
    img:{
        // border:"1px solid red",
        marginTop:"20px",
        width:'55px',
        height:'55px',
        // [theme.breakpoints.down(500)]: {
        //     width:"45px",
        //     height:'45px',
        // },
        [theme.breakpoints.down(500)]: {
            width:"35px",
            height:'35px',
        },
    },

    expand:{
        color:"#5d35a9",
        fontWeight:'bold',
        fontFamily:"Poppins",
        textAlign:'center',
        marginTop:'40px',
        cursor:"pointer"
    },
    buttonGroup:{
        // textAlign:'center',
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        gap:"20px",
        marginTop:"40px"
    },
}))

const Learning  = ({ state, username, courseid }) =>{

    const classes = useStyles();
    const [isExpanded, setIsExpanded] = useState(false)
    const [dimensions, setDimensions] = useState(window.innerWidth);

    const handleResize = () => {
        setDimensions(window.innerWidth);
      }

    useEffect(() => {
        window.addEventListener("resize", handleResize, false);
    }, []);

    return (
        <>
          <div className={classes.learning} id='learning'>
            <div className={classes.learningTitle}>
                What will you be learning in this course?
            </div>

            {
                state.isOneToOne
                  &&
                <div className={classes.learningSubTitle}>
                    to pair with your 1:1 coaching experience
                </div>
            }

            <div className={classes.learningContainer}>
                {isExpanded?
                    state.courseOverview.objectivesCourse.map((obj,index)=>{

                        return (
                            <div key={index} className={classes.learningItem}>
                                <img 
                                    className={classes.img}
                                    src={shapeArr[Math.floor(Math.random()*shapeArr.length)]} 
                                    alt='irregular shape'
                                />
                                <div className={classes.order}>{index+1<10? "0":''}{index+1}</div>
                                <div className={classes.objective}>{addEllipsis(200,obj)}</div>
                            </div>
                        )
                    }):
                    state.courseOverview.objectivesCourse.slice(0,dimensions<802? 3:6).map((obj,index)=>{

                        return (
                            <div key={index} className={classes.learningItem}>
                                <img 
                                    className={classes.img}
                                    src={shapeArr[Math.floor(Math.random()*shapeArr.length)]} 
                                    alt='irregular shape'
                                />
                                <div className={classes.order}>{index+1<10? "0":''}{index+1}</div>
                                <div className={classes.objective}>{addEllipsis(200,obj)}</div>
                            </div>
                        )
                    })
                }
            </div>

           {
            state.courseOverview.objectivesCourse.length>6
               &&
            <div 
                className={classes.expand}
                onClick={()=>setIsExpanded(!isExpanded)}
            >
               {isExpanded? "Close": "See More"}
            </div>
            }
            <div className={classes.buttonGroup}>
                    {
                    (state.courseOverview.courseCTA
                        &&
                    state.courseOverview.courseCTA.consultation)
                        ?
                    <ConsultationButton 
                        link={state.courseOverview.courseCTA.link}
                        freeTrial={state.paymentSettings.freeTrial}
                    />
                        :
                    <PaymentButton  
                        username={username} 
                        courseid={courseid}
                        price={state.paymentSettings.price}
                        freeTrial={state.paymentSettings.freeTrial}
                    />
                    }
                </div>


          </div>
        </>
    )
}


export default Learning