import axios from 'axios';
import { baseUrl } from '../config';
import { database } from '../Auth/firebase_config'

const RemoveTracking = (patientId, courseId, flag) =>{
    return new Promise(async (resolve, reject) => {
        try{
            const trackingRef = database.ref(`/users/${patientId}/tracking`)
            trackingRef.once(
                'value', (snapshot) => {
                    const data = snapshot.val();
                    console.log('tracking data', data)
                    data[courseId].isConsented = true;
                    if(flag==='connect'){
                        data[courseId].isConnected = true;
                    }
                    console.log('data', data)
                    trackingRef.set(data)
                })
            resolve('success')
        }
        catch(err){
            reject(`error removing tracking flag`)
        }
    })


}

export default RemoveTracking