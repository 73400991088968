import React, { useState, useEffect } from "react";

import {  makeStyles, Dialog, Slide, Typography, Button } from '@material-ui/core'
import { urlCorrection } from "../../helper/stringHelper";


const useStyles = makeStyles((theme) => ({
    modalBox: {
        '& .MuiPaper-root': {
            width: '100%',
            display:'block',
            overflow:'scroll',
            maxWidth: '800px',
            borderRadius: '20px',
        },
        boxSizing:'border-box',
    },
    container:{
        width: '100%',
        marginTop: '30px',
        marginBottom: '30px',
        textAlign: 'center',
        backgroundColor: '#fff',
        padding: '25px 30px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap:'50px',
        [theme.breakpoints.down(600)]: {
            width: '100%',
        },
        boxSizing:'border-box',

    },
    completeText:{
        padding:"0px 150px",
        [theme.breakpoints.down(800)]: {
            padding:"0px 50px",
        }
    },
    button:{
        width:'250px',
        borderRadius:'20px',

    },
    buttonGroup:{
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        gap:'20px'
    }


}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const AlreadyCompleteModal =({ 
    redirectUrl,
    open, 
    coachName,
    signOut,
    onClose,
    setAuthPopUp

})=>{

    const classes = useStyles()



    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
                className={classes.modalBox}
            >

                <div className={classes.container}>
                    <Typography variant="h4">
                        📋 Success! Thank you for completing {coachName}'s Intake Form
                    </Typography>

                    <div className={classes.completeText}>
                        <div>Thank you for providing your information!</div> 
                            <div>
                                {redirectUrl? 
                                `👉 Click the button below to view the content your coach has left for you.`:
                                `👉 Click the link below to access your completed form.`}
                            </div>
                    </div>


                    <div className={classes.buttonGroup}>
                        {
                            redirectUrl
                                &&
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={()=>window.open(urlCorrection(redirectUrl))}
                                className={classes.button}
                            >
                                Next Steps from {coachName}

                            </Button>
                        }
                        <Button
                            variant='outlined'
                            color='primary'
                            onClick={()=>window.open('https://myprogram.portal.floka.co/signin')}
                            className={classes.button}
                        >
                            Take me to my Floka Account
                        </Button>

                        <Button
                            variant='outlined'
                            color='primary'
                            onClick={()=>{
                                signOut()
                                setAuthPopUp(true)
                                onClose()
                            }}
                            className={classes.button}
                        >
                            Sign Out
                        </Button>
                    </div>


                </div>
            
                    
            </Dialog>
        </>
    )
    


}


export default AlreadyCompleteModal