import React, { useState, useEffect } from "react";

import {  makeStyles, Dialog, Slide, Breadcrumbs, Typography } from '@material-ui/core'
import { NavigateNext } from '@material-ui/icons';
import { useParams, Link } from 'react-router-dom';

import SignUp from "./signUp";
import SignIn from "./signIn";

const useStyles = makeStyles((theme) => ({
    modalBox: {
        '& .MuiPaper-root': {
            width: '100%',
            display:'block',
            overflow:'scroll',
            maxWidth: '800px',
            borderRadius: '10px',
            [theme.breakpoints.down(500)]: {
                height:"100%"
            },
        },
        boxSizing:'border-box',
    },
    paymentFormContainer:{
        width: '100%',
        height: 'fit-content',
        marginTop: '30px',
        marginBottom: '30px',
        textAlign: 'center',
        backgroundColor: '#fff',
        padding: '25px 30px',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down(600)]: {
            width: '100%',
        },
        boxSizing:'border-box',

    },
    paymentFormTitle: {

        fontFamily: "Playfair Display",
        fontWeight: "bold",
        fontSize: '28px',
        color: 'black'
    },
    paymentTitleContainer: {
        lineHeight: '5px',
        textAlign: 'left',
        [theme.breakpoints.down(600)]: {
            textAlign: 'center',
            marginBottom: '25px'
        },
    },
    breadcrumbContainer: {
        fontFamily: "Playfair Display",
        padding: '5px',
        marginBottom: "25px",
        display: "flex",
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down(900)]: {
            flexDirection: 'row',
            alignItems: "center",
            gap: "15px",
            marginBottom: "15px",
        },
        [theme.breakpoints.down(800)]: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
        },

        [theme.breakpoints.down(600)]: {
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: "center",
        },
    },
    breadcrumbUnselected: {
        fontFamily: "Playfair Display",
        textDecoration: 'none',
        color: '#a2a2a5',
        fontSize: "14px",
        cursor: 'auto'
    },
    breadcrumbSeelected: {
        fontFamily: "Playfair Display",
        textDecoration: 'none',
        color: 'black',
        fontSize: "14px",
        cursor: 'pointer'
    },
    signUp: {
        color: "#090a0a",
        cursor: 'pointer',
        textDecoration: 'underline',
        fontWeight: 'bold'

    },
    notifyContainer:{
        // border:'1px solid red',
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        alignItems:"start",
        textAlign:"left",
        gap:"20px",
        padding:"0px 80px 0px 20px",
        marginBottom:"30px",
        [theme.breakpoints.down(700)]: {
            textAlign:"center",
            alignItems:"center",
            padding:"0px 80px 0px 80px",
        },
        [theme.breakpoints.down(500)]: {
            padding:"0px 10px 0px 10px",
        }
    },
    notifyASubContainer:{
        width:'100%',
        backgroundColor:"#f6f6f6",
        padding:'20px 30px 20px 20px',
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        alignItems:"center",
        gap:"25px",
        borderRadius:"20px",
        [theme.breakpoints.down(700)]: {
            padding:'20px',
        },

    }

}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const AuthModal =({ 
    onClose, 
    title,
    coachName,
    open, 
    auth,
    setAuth,
    signUpData,
    signInData,
    setSignUpData,
    setSignInData,
    signOutHandler,
    signUpHandler,
    signInHandler,
    isNewsletterSubscribed,
    setIsNewsletterSubscribed
})=>{

    const classes = useStyles()

    const breadcrumbs = [
        <Link to={``} key="1" className={(auth === 'SignIn' || auth === 'SignUp') ? classes.breadcrumbSeelected : classes.breadcrumbUnselected}>
            {auth === 'SignIn' ? 'Sign In' : 'Sign Up'}
        </Link>,
        <Link to={``} key="2" className={auth === 'auth' ? classes.breadcrumbSeelected : classes.breadcrumbUnselected}>
            Complete Survey
        </Link>
    ];


    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
                className={classes.modalBox}
            >

                <div 
                    className={classes.paymentFormContainer}
                >
                    {/* <div className={classes.paymentTitleContainer}>
                        <Typography className={classes.paymentFormTitle}>
                            Get Started
                        </Typography>
                    </div> */}

                    <div className={classes.breadcrumbContainer}>
                        <div></div>

                        {auth === "SignUp" && <span className={classes.authTitle} onClick={() => {
                            setAuth("SignIn")
                            setSignUpData({
                                firstName: "",
                                email: "",
                                confirmEmail: "",
                                password: "",
                                confirmPassword: "",
                                birthday: ""
                            })
                        }}>
                            Already have Flöka account? <span className={classes.signUp}>Sign In</span>
                        </span>}

                        {auth === "SignIn" && <span className={classes.authTitle} onClick={() => {
                            setAuth("SignUp")
                            setSignInData({
                                email: "",
                                password: ""
                            })
                        }}>
                            No Flöka account? <span className={classes.signUp}>Sign Up</span>
                        </span>}

                        {auth === "auth"
                            &&
                            <span
                                className={classes.authTitle}
                                onClick={() => {
                                    signOutHandler()
                                }}
                            >
                                <span className={classes.signUp}>Sign Out</span>
                            </span>}

                    </div>

                    <div className={classes.notifyContainer}>
                        <Typography variant="h4">
                            📋 Complete {coachName}'s Form
                        </Typography>

                        <div className={classes.notifyASubContainer}>

                            <div>
                                {coachName} would like to learn more about you. They've requested your assistance in filling out their form, {title}.
                            </div>

                            <div>
                                👇 {auth === "SignUp"? 'Sign up': 'Sign in'} below to access the form and provide valuable information that will help them better understand your needs.
                            </div>
                        </div>
                    </div>

                    {/* User Sign up */}
                    {
                    auth === "SignUp"
                        &&
                    <SignUp
                        isNewsletterSubscribed={isNewsletterSubscribed}
                        setIsNewsletterSubscribed={setIsNewsletterSubscribed}
                        signUpData={signUpData}
                        setSignUpData={setSignUpData}
                        auth={auth}
                        signUpHandler={signUpHandler}
                    />
                    }

                     {/* User Sign in */}
                    {
                    auth === "SignIn"
                        &&
                    <SignIn
                        signInData={signInData}
                        setSignInData={setSignInData}
                        auth={auth}
                        signInHandler={signInHandler}
                    />
                    }

                </div>
            
                    
            </Dialog>
        </>
    )
    


}


export default AuthModal