import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import ConsultationButton from './consultationButton';

const useStyles = makeStyles((theme) => ({

    consultationSection:{
        // height:"500px",
        backgroundColor:"#000",
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        color:"#fff"
    },
    consultationSectionTitle:{
        marginTop:"80px",
        textAlign:'center',
        fontFamily:'Lora',
        fontSize:"40px",
        fontStyle:"italic",
        [theme.breakpoints.down(700)]: {
            fontSize:"35px",
        },
    },
    consultationSectionText:{
        marginTop:"20px",
        padding:'0px 20px',
        fontFamily:"Poppins",
        textAlign:'center',
        lineHeight:"1.5",
        [theme.breakpoints.down(700)]: {
            paddingLeft:"20px",
            paddingRight:"20px"
        },
        [theme.breakpoints.down(500)]: {
            paddingLeft:"10px",
            paddingRight:"10px",
            marginTop:"25px",
        },
    },
    buttonContainer:{
        marginTop:"50px",
        marginBottom:'80px',
        display:"flex", 
        flexDirection:'row', 
        justifyContent:'center'
    },
}))

const Consultation =  ({ state, practitioner}) =>{

    const classes = useStyles();

    return (
        <>
            <div className={classes.consultationSection}>
                <Typography variant='h4' className={classes.consultationSectionTitle}>
                    Book a Consultation
                </Typography>

                <div className={classes.consultationSectionText}>
                    <div>
                        Book a consultation with {practitioner} and discover 
                    </div> 
                    <div>
                        how they can best help you in this journey to root-case healing.
                    </div>

                </div>

                <div className={classes.buttonContainer}>
                    {
                        <ConsultationButton 
                            bottom={true}
                            link={state.courseOverview.courseCTA.link}
                        />
                    }
                </div>

            </div>
          
        </>
    )
}

export default Consultation