import logo from './logo.svg';
import { useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
// import withRouter from './pages/WithRouter';

import LandingPage from './pages/LandingPage';
import PaymentForm from './pages/PaymentForm';
import CoursePage from './pages/CoursePage';
import IntakeForm from './pages/IntakeForm';
import Redirect from './pages/Redirect';
import './styles/index.css'
import FlokaTheme from './styles/Theme'

import ReactGA from 'react-ga4'
import { GOOGLE } from './lib/config';
import mixpanel from 'mixpanel-browser'
import MessengerCustomerChat from 'react-messenger-customer-chat';


// import Helmet from 'react-helmet';




function App() {

  if (process.env.NODE_ENV === 'production') {
    console.log = () => {};
    // You can also disable other console methods if you want
    console.info = () => {};
    console.warn = () => {};
    // console.error = () => {}; // Overriding console.error is not recommended
  }

  return (
    <>
    <BrowserRouter>
      {/* <Helmet>
      <title>Floka Courses Portal</title>
      <meta
         name='description'
         content="Find your practitioner's info and courses!"
      />
      <meta
         name='keywords'
         content='Health, Health Course, Practitioner, coaches'
      />
    </Helmet> */}
      <Routes>
        <Route path="/:username" element={<LandingPage />} />
        <Route path="/:username/course/:courseid" element={<CoursePage />} />
        <Route path="/:username/course/:courseid/payment" element={<PaymentForm />} />
        <Route path="/:username/survey/:surveyId" element={<IntakeForm />} />
        <Route path="/*" element={<Redirect />} />
      </Routes>
    </BrowserRouter>

    {/* <MessengerCustomerChat
      pageId="1141834419229007"
      appId="548117434192857"
    /> */}

    </>

  );
}

export default App;
