import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Calendar from '../../assets/calendar.svg'
import Calendar2 from '../../assets/calendar2.svg'

const useStyles = makeStyles((theme) => ({
    link:{
        textDecoration:"none",
    },
    purchaseButton: {
        display:'flex',
        flexDirection:"row",
        justifyContent:"center",
        alignItems:'center',
        gap:"15px",
        textAlign: 'center',
        padding: '12px 12px',
        marginTop:'10px',
        borderRadius: '30px',
        backgroundColor: 'black',
        color: 'white',
        fontSize: '13px',
        userSelect: 'none',
        cursor: 'pointer',
    },
    img:{
        // color:"red",
        width:'16px'
    },
    freeTrial:{
        fontSize:'14px'
    }
}))
 
 const ConsultationButton = ({link, bottom, freeTrial }) =>{
    
    const classes = useStyles();

    return (
        <>
            <div 
                className={classes.purchaseButton}
                style={{ 
                    backgroundColor: bottom? '#fff':"#000",
                    color: bottom? "#000":"#fff"
                }}
                onClick={()=>{
                    window.open(link)
                }}
            >
                <span>Book a Free Consultation</span>
                <img 
                    src={bottom? Calendar2:Calendar} 
                    className={classes.img}
                />
                {freeTrial 
                    && 
                freeTrial===true
                    &&
                <div className={classes.freeTrial}>
                    Start 7-Day Free Trial
                </div>
                }
            </div>
            
        </>
    )

}

export default ConsultationButton