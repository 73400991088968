import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { addEllipsis } from '../../helper/stringHelper';

import Quotations from '../../assets/Quotations.svg'


const useStyles = makeStyles((theme) => ({

    testimonial:{
        padding:"50px 100px",
        display:'flex',
        flexDirection:'column',
        justifyContent:"center",
        alignItems:'center',
        [theme.breakpoints.down(1000)]: {
            padding:"50px 50px",
        },

    },
    testimonialTitle:{
        textAlign:'center',
        fontFamily:'Lora',
        fontSize:"40px",
        fontStyle:"italic",
        marginTop:"30px",
        [theme.breakpoints.down(700)]: {
            fontSize:"35px",
        },
    },
    testimonialSubTitle:{
        textAlign:'center',
        marginTop:"30px"
    },
    testimonialContainer:{
        marginTop:"60px",
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        flexWrap:"wrap",
        gap:"30px",
        [theme.breakpoints.up(2500)]: {
            width:"50%"
        },
    },
    testimonialItem:{
        borderRadius:'10px',
        width:"250px",
        textAlign:"center",
        padding:"",
        background:"linear-gradient( #d2daff, #d7cdea)",
    },
    testimonialText:{
        fontFamily:"Poppins",
        padding:'30px 20px 50px 20px'
    },
    img:{
        marginTop:"25px"
    },
    imgContainer:{
        display:"flex",
        // border:'1px solid red',
        flexDirection:"row",
        justifyContent:"center",
        alignItems:'center',
        flexWrap:'wrap',
        // gap:"30px",
        [theme.breakpoints.down(1020)]: {
            flexDirection:"column",
            flexWrap:'nowrap',
            justifyContent:"center",
            alignItems:'center',
            gap:"0px",
        },
    },
    screenshot:{
        // border:"1px solid red",
        width:"400px",
        objectFit:"cover",
        [theme.breakpoints.down(1200)]: {
            width:"40%"
          },
        [theme.breakpoints.down(1020)]: {
          width:"50%"
        },
        [theme.breakpoints.down(550)]: {
            width:"70%"
          },
    },
    screenshotTitle:{
        textAlign:'center',
        fontFamily:'Lora',
        fontSize:"40px",
        fontStyle:"italic",
        marginBottom:'20px',
        [theme.breakpoints.down(700)]: {
            fontSize:"35px",
        },

    },
    screenshotTitleContainer:{
        marginTop:"50px",
        marginBottom:"50px",
        textAlign:"center",
        [theme.breakpoints.down(1000)]: {
            marginBottom:"50px",
            // display:'none'
        },
    },
    screenshotTitleContainer2:{
        marginTop:"100px",
        marginBottom:"100px",
        textAlign:"center",
        [theme.breakpoints.up(1000)]: {
            marginBottom:"50px",
            display:'none'
        },
    }





}))

const Testimonial = ({ state }) =>{

    const classes = useStyles()

    return(
        <>
           <div className={classes.testimonial} id='testimonial'>
                {
                (!state.courseOverview.testimonialType  || state.courseOverview.testimonialType ==='text')
                    &&
                <>
                    <div className={classes.testimonialTitle}>Testimonials</div>
                    <div className={classes.testimonialSubTitle}>Look at what others had to say...</div>

                    <div className={classes.testimonialContainer}>
                        {state.courseOverview.testimonials
                            .filter(test=> test !=='')
                            .map((testimonial, index) => {
                                return (
                                    <div 
                                        key={index} 
                                        className={classes.testimonialItem}
                                    >
                                        <img src={Quotations} className={classes.img}/>
                                        <div className={classes.testimonialText}>{addEllipsis(200,testimonial)}</div>
                                    </div>
                                )

                            }
                                
                        )}

                    </div>
                </>
                }
                {
                  state.courseOverview.testimonialType && state.courseOverview.testimonialType === 'image'
                    &&
                  <>
                    {/* {
                        <div className={classes.screenshotTitleContainer2}>
                           <div className={classes.screenshotTitle}>
                               Hear it from the community
                           </div>
                           <div>
                               Look at what others had to say...
                           </div>
                       </div>
                    } */}
                    {
                     state.courseOverview.testimonialImgs
                     .slice(3,4).length>0
                        &&
                    <div className={classes.imgContainer} 
                    style={{ 
                        justifyContent: state.courseOverview.testimonialImgs
                        .slice(3,4).length===1 ?"center":"space-between"}}
                    >
                        {
                            state.courseOverview.testimonialImgs
                            .slice(2,4)
                            .map((each,index)=>{
                                return (
                                    <img 
                                        key={index} 
                                        src={each.patch}
                                        alt={each.fileName}
                                        className={classes.screenshot}
                                    />
                                )
                            })
                        }
                    </div>
                    }
                    <div className={classes.screenshotTitleContainer}>
                        <div className={classes.screenshotTitle}>
                            Hear it from the community
                        </div>
                        <div>
                            Look at what others had to say...
                        </div>
                    </div>
                    <div className={classes.imgContainer}  style={{ 
                        justifyContent: state.courseOverview.testimonialImgs
                        .slice(0,3).length===1 ?"center":"space-between"}}
                    >
                        {
                            state.courseOverview.testimonialImgs
                            .slice(0,3)
                            .map((each,index)=>{
                                return (
                                    <img 
                                        key={index} 
                                        src={each.patch}
                                        alt={each.fileName}
                                        className={classes.screenshot}
                                    />
                                )
                            })

                        }
                    </div>
                  </>

                }

            
           </div>
        </>
    )
}

export default Testimonial