// Primary
export const GHOST_WHITE            = '#F2F4FF';
export const LAVENDER_WEB           = '#E2E7FF';
export const LAVENDER_BLUE          = '#D2DAFF';
export const MAX_BLUE_PURPLE        = '#AEBAF8';
export const CORNFLOWER_BLUE        = '#8595E8';
export const VIOLET_BLUE_CRAYOLA    = '#6576CF';
export const LIBERTY                = '#4A5BAE';
export const DARK_CORNFLOWER_BLUE   = '#354287';

// Secondary
export const CULTURED               = '#EEEEEE';
export const PLATINUM               = '#E4E4E4';
export const GAINSBORO              = '#DBDBDB';
export const GRAY_X_11_GRAY         = '#B6B6B6';
export const SPANISH_GRAY           = '#929292';
export const DAVYS_GREY             = '#494949';
export const EERIE_BLACK            = '#242424';
export const BLACK                  = '#000000';

// Tertiary
export const MAGNOLIA               = '#F6F2FF';
export const LANGUID_LAVENDER       = '#D7CDEA';
export const BLUE_MAGENTA           = '#C7B5EB';
export const LAVENDER_FLORAL        = '#A786E6';
export const MED_PURPLE             = '#8C63DA';
export const IRIS                   = '#7448C6';
export const REBECCA_PURPLE         = '#5D35A9';
export const SPANISH_VIOLET         = '#482787';

// Graph
export const WISTERIA               = '#C4A0D0';
export const APRICOT                = '#F8C7AE';
export const MIMI_PINK              = '#F1C9DB';
export const CAMBRIDGE_BLUE         = '#92B4A7';
export const LILAC_LUSTER           = '#B4929F';
export const QUEEN_BLUE             = '#416788';
export const OLD_LAVENDER           = '#776871';
export const DARK_BYZANTIUM         = '#5C374C';
export const DARK_PURPLE            = '#420039';

// Misc
export const WHITE = '#FFFFFF';
export const PALE_PINK = '#CDA8CE';
export const LIGHT_NAVY = '#526CF2';
export const DARK_BLUE_MAGENTA = '#362b73';

// Change Password
export const WARNING_ORANGE = '#FF780A'
export const TRANSLUCENT_WARNING_ORANGE = 'rgb(255, 120, 10, 0.1)'

// Delete button
export const UA_RED = '#DC004E'
export const TRANSLUCENT_UA_RED = 'rgb(220, 0, 78, 0.1)'

// Old Colors:
// Main colors for the portal
export const JET = '#323232';
export const BRIGHT_LILAC = '#C18CFF';
export const MED_SLATE_BLUE = '#7456FF';

// Fullscript tables
export const PALE_NAVY = '#f3f2ff';

// Requests
export const WARNING_RED = '#DC004E';
export const PENDING_YELLOW = '#FFA90A';
export const SUCCESS_GREEN = '#03CEA4';

// Patient card
export const TRANSLUCENT_MAX_BLUE_PURPLE = 'rgb(170, 152, 254, 0.5)'

// Linear progress
export const LIGHT_GRAY = '#F1F1F1'

// View Full History Button (PillarStats.js)
export const HELIOTROPE = '#D660F7'

// Premium Gold Chip
export const GOLD = '#D7BE69'

// Banner text
export const DARK_GRAY = '#4f4f4f'

// Light Mode
export const LILAC = '#CDA8CE';
export const BABY_PINK = '#EABCB7';
export const QUEEN_PINK = '#F1CDDD';

// Dark Mode
export const MAJORELLE_BLUE = '#5E3DEF'
export const CERULEAN_BLUE = '#4658CF'
export const PURPLE_PLUM = '#9054C7'
export const FUCHSIA_CRAYOLA = '#C750C0'
export const NEW_YORK_PINK = '#E69494'
export const MELLOW_APRICOT = '#FBC276'
