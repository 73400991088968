import axios from 'axios';
import { baseUrl } from '../config';

const addAbandonedCartEmail = (courseId, abandonedCartEmail) => {

    return new Promise((resolve, reject) => {

        let params = {
            abandonedCartEmail,
            courseId
        };
        axios.post(`${baseUrl}/flokaLanding/abandonedCartEmail`, params)
            .then((res) => {
                resolve(res)
            })
            .catch((err) => {
                console.log('error', err)
                reject(err)
            })
    })

}

export default addAbandonedCartEmail