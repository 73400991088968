import React, { useState, useEffect } from'react';
import { makeStyles, Typography, Button, Checkbox, TextField, Box, IconButton, ListItemSecondaryAction } from '@material-ui/core'
import FileCopyIcon from '@material-ui/icons/FileCopy';
import clsx from 'clsx'
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import { ExpandMore, ExpandLess } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    box:{
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
        justifyContent:"flex-start",
        [theme.breakpoints.down(500)]: {
            justifyContent:'center'
        },

    },
    creators:{
       color:"grey"
    },
    selected:{
       backgroundColor:"#f6f2ff",
    },
    warning:{
        width:'100%',
        borderRadius:'20px',
        backgroundColor: '#ffecec',
        padding:'40px'
    }, 
    warningText:{
        marginTop:'20px',
        fontWeight:'bold',
    },
    container: {
        width:'100%',
        padding:'40px',
        [theme.breakpoints.down(800)]: {
            padding:'20px',
        }
    },
    notification:{
        backgroundColor:"#f6f2ff",
        borderRadius:'20px',
        padding:'20px',
        marginTop:'20px',
        marginBottom:'20px',
        [theme.breakpoints.down(500)]: {
            textAlign:'center'
        }
    },
    titleAndAction:{
        width:'100%',
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'start',
        [theme.breakpoints.down(1100)]: {
            flexDirection:'column',
            alignItems:'start',
            gap:'15px'
        },
        [theme.breakpoints.down(500)]: {
            alignItems:'center',
            justifyContent:'center',
        }

    },
    titleAndAuthor:{
       display:'flex',
       flexDirection:'column',
       justifyContent:'flex-start',
       alignItems:'start',
       gap:'10px',
       [theme.breakpoints.down(500)]: {
            alignItems:'center',
            justifyContent:'center',
        }
    },
    actions:{
       display:'flex',
       flexDirection:'row',
       justifyContent:'center',
       alignItems:'center',
       gap:'15px',
       [theme.breakpoints.down(500)]: {
        flexDirection:'column',
       }
    },
    backButton:{
        backgroundColor:"#fff",
        color:"#000",
        border:'1px solid #000'
    },
    assignButton:{
        backgroundColor:"#000",
        color:"#fff",
        "&:hover":{
            backgroundColor:"#000",
        }
    },
    button:{
        cursor:'pointer',
        padding:'5px 30px',
        width:'200px',
        borderRadius:'20px',
    },
    addAnotherChoice:{
       display:'flex',
       flexDirection:'row',
       justifyContent:'flex-end',
       [theme.breakpoints.down(900)]: {
           justifyContent:'center'
       }
    },
    addOptionButton:{
        color:"#fff",
        backgroundColor:"#482787",
        "&:hover":{
            backgroundColor:"#482787",
        }
    },
    questionsContainer:{
       width:'100%',
       display:'flex',
       flexDirection:'column',
       justifyContent:'center',
       alignItems:'center',
       gap:'30px',
       marginTop:'30px'
    },
    questionContainer:{
       width:'100%'
    },
    titleAndDescriptionContainer:{
        width:"100%",
        display:'flex',
        flexDirection:'row',
        borderRadius:'20px',
        height:'220px',
        border:'1px solid #929292',
        marginTop:'30px',
        gap:"30px",
        [theme.breakpoints.down(720)]: {
            // height:'fit-content',
            padding:'20px'
        }
    },
    titleAndDescriptionContent:{
        width:'100%',
        paddingRight:'20px',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        gap:'20px'
    },
    titleBox:{
        width:'100%',
        border:'1px solid grey',
        borderRadius:'25px',
        padding:'20px',
    },
    questionBox:{
        width:'100%',
        fontWeight:'bold'
    },
    clarify:{
       backgroundColor:"#f6f2ff",
       display:'flex',
       flexDirection:'row',
       justifyContent:'center',
       alignItems:'center',
       gap:'10px',
       cursor:"pointer"
    }, 
    colorBar:{
        width:'20px',
        height:'100%',
        backgroundColor:"#482787",
        borderTopLeftRadius:'20px',
        borderBottomLeftRadius:'20px',
        [theme.breakpoints.down(720)]: {
            display:'none'
        }
    },
    inputField:{
        borderRadius:'30px',
        width:'100%',

    },
    notchedOutline: {
        borderColor: "#929292 !important"
    },
    floatingLabelFocusStyle:{
        color: "#000 !important",
        fontWeight:'bold',
        fontSize:'16px',
        fontFamily:"ValueSerif",
        paddingRight:'10px',
        marginRight:'10px'
    },
    questionSelectionContainer:{
        padding:'20px',
        width:'100%',
        display:'flex',
        flexDirection:'column',
        borderRadius:'20px',
        border:'1px solid #929292',
        marginTop:'15px',
        gap:"20px",
        boxSizing:'border-box',

    },
    options:{
        width:'100%',
        display:'flex',
        flexWrap:'wrap',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        gap:"20px"
    },
    option:{
        width:'200px',
        border:'1px solid #dbdbdb',
        borderRadius:'20px',
        padding:'20px',
        textAlign:'center',
        cursor:"pointer",
        '&:hover':{
            border:'1px solid #000'
        }
    },
    selectedOption:{
        border:'1px solid #000'
    },
    optionContent:{
        width:'100%',
        display:'flex',
        flexDirection:'column',
        gap:'10px'

    },
    optionContent2:{
        width:'100%',
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center', 
        gap:'10px',
        [theme.breakpoints.down(700)]: {
            flexDirection:'column',
        }
    },
    optionContent3:{
        width:'100%',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'start', 
        gap:'10px',
    },
    optionContentItem:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        gap:'10px'
    },
    optionContentInput:{
        width:'100%',
        border:'1px solid #000',
        borderRadius:'20px',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'start',
        minHeight:'40px',
        padding:'10px 20px',
        cursor:'pointer',
        "&:hover":{
            backgroundColor:"#f6f2ff",
        }
    },
    square:{
        border:'1px solid #000',
        width:'25px',
        height:'25px',
        borderRadius:'5px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        cursor:'pointer',
        "&:hover":{
            backgroundColor:"#f6f2ff",
        }
    },
    circle:{
        border:'1px solid #000',
        width:'20px',
        height:'20px',
        borderRadius:'50%',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        cursor:'pointer',
        "&:hover":{
            backgroundColor:"#f6f2ff",
        }
    },
    booleanCircle:{
        border:'1px solid #000',
        width:'25px',
        height:'25px',
        borderRadius:'50%',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        cursor:'pointer',
        "&:hover":{
            backgroundColor:"#f6f2ff",
        }
    },
    innerCircle:{
        width:'12px',
        height:'12px',
        borderRadius:'50%',
        backgroundColor:"#000"
    },
    written:{
        width:'100%',
        border:'1px solid #dbdbdb',
        borderRadius:'30px',
        padding:'10px 30px',
        textAlign:'center',
        cursor:"pointer",
        '&:hover':{
            border:'1px solid #000'
        }
    },
    selectedWritten:{
        border:'1px solid #000'
    },
    addQuestion:{
        marginTop:'30px',
        width:'100%',
        display:'flex',
        flexdirection:'row',
        justifyContent:'center',
    },
    addQuestionButton:{
        fontSize:"18px",
        width:'90%',
        border:'1px solid #000',
        backgroundColor:"#fff",
        color:"#000",
        padding:'15px',
        borderRadius:'30px',
        fontFamily:"ValueSerif",
        fontWeight:'bold',
        "&:hover":{
            backgroundColor:"#fff",
        }
    },
    file:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        gap:'15px',
        marginBottom:'10px'
    },
    fileName:{
        textDecoration:'underline',
        cursor:'pointer',
    },
    clearout:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'flex-start',
        width:'100%'
    },
    clearoutItem:{
        cursor:'pointer',
        textDecoration:'underline',
        color:"#4a5bae"
    },
    scaleBlockContainer:{
        width:'100%',
        display:'flex',
        flexDirection:'row',
        justifyContent:'flex-start',
        alignItems:'center',
        flexWrap:'wrap',
        gap:'10px',
    },
    scaleBlock:{
        cursor:'pointer',
        width:'40px',
        height:'40px',
        border:'1px solid #000',
        borderRadius:'5px',
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'center',
        "&:hover":{
            backgroundColor:"#f6f2ff",
            border:"1px solid #5d35a9",
        }
    },
    dropDownIcon:{
       cursor:'pointer',
    },
    dropDownDefaultText:{
        color:"#929292",
    },
    dropdownHeader:{
        cursor:"pointer",
        width:'70%',
        height:'40px',
        borderRadius:'20px',
        padding:'10px 20px',
        border:'1px solid #929292',
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'center',
        [theme.breakpoints.down(800)]: {
            width:'100%'
        }

    },
    dropDown:{
        width:'70%',
        height:"100px",
        border:'1px solid #929292',
        borderRadius:'20px',
        overflowY:'scroll',
        display:'flex',
        flexDirection:'column',
        justifyContent:'start',
        alignItems:'start',
        [theme.breakpoints.down(800)]: {
            width:'100%'
        }
    },
    dropDownItem:{
        width: "100%",
        padding:'10px 10px 10px 30px',
        cursor:'pointer',
        "&:hover":{
            backgroundColor:"#f6f2ff",
        }
    },
    optionRankInput:{
        width:'30px'
    },
    uploadButtonContainer:{
        width:'100%',
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
    },
    uploadButton:{
        width:'300px',
        [theme.breakpoints.down(500)]: {
            width:'100%'
        }
    }

}))

const Questions = ({ 
    item, 
    index, 
    answers, 
    fillAnswers, 
    sortArrayToPosition,
    generateRangeArray,
    uploadFiles
}) =>{

    const classes = useStyles()
    const [openDropDown,  setOpenDropDown] = useState(false)

    return (
        <>
            <div className={classes.questionSelectionContainer} >

            <Typography variant='h6' className={classes.questionBox}>
                {item.question}
            </Typography>

            {
            item.type && item.type === 'multi'
                &&
            <div className={classes.optionContent}>
                {item.options.map((option, idx)=>{
                    return (
                        <div key={idx} className={classes.optionContentItem}>
                            <div 
                                className={classes.square}
                                // className={answers[index]?.includes(idx+1)? clsx(classes.square, classes.selected):  classes.square}
                                onClick={()=>{
                                    // if(completed) return; 
                                    const targetAnswers = 
                                    answers[index]?
                                    answers[index]?.includes(idx+1)?
                                    answers[index].filter((item)=>item !== idx+1):
                                    [...new Set([...answers[index], idx+1])]:
                                    [idx+1]

                                    fillAnswers(index, targetAnswers)
                                }}
                            >
                                {answers[index]?.includes(idx+1) && <CheckIcon />}
                            </div>

                            <div 
                                className={answers[index]?.includes(idx+1)? clsx(classes.optionContentInput, classes.selected): classes.optionContentInput} 
                                onClick={()=>{
                                    // if(completed) return; 
                                    const targetAnswers = 
                                    answers[index]?
                                    answers[index]?.includes(idx+1)?
                                    answers[index].filter((item)=>item !== idx+1):
                                    [...new Set([...answers[index], idx+1])]:
                                    [idx+1]
                                    
                                    fillAnswers(index, targetAnswers)
                                }}
                            >
                                {option}
                            </div>
                            
                        </div>
                    )
                })}
            </div>
            }

            {
            item.type && item.type === 'single'
                &&
            <div className={classes.optionContent}>
                {item.options.map((option, idx)=>{
                    return (
                        <div key={idx} className={classes.optionContentItem}>
                            <div 
                                className={classes.circle}
                                // className={ answers[index]===idx+1? clsx(classes.circle, classes.selected):classes.circle}
                                onClick={()=>{
                                    // if(completed) return; 
                                    fillAnswers(index, idx+1)
                                }}
                            >
                                { answers[index]===idx+1 && <div className={classes.innerCircle}></div>}
                            </div>

                            <div 
                                className={ answers[index]===idx+1?  clsx(classes.optionContentInput, classes.selected): classes.optionContentInput} 
                                onClick={()=>{
                                    // if(completed) return; 
                                    fillAnswers(index, idx+1)
                                }}
                            >
                                {option}
                            </div>
                            
                        </div>
                    )
                })}
            </div>
            }

            {
            item.type && item.type === 'rank'
                &&
            <div className={classes.optionContent}>
                {
                answers[index]? 
                answers[index].map((option, idx)=>{
                    return (
                        <div key={idx} className={classes.optionContentItem}>
                            <input 
                                type='number' 
                                min={1}
                                max={item.options.length}
                                value={answers[index].indexOf(option)+1}
                                className={classes.optionRankInput}
                                onChange={(e)=>{
                                    // if(completed) return; 
                                    console.log(e.target.value)
                                    const answer = 
                                    sortArrayToPosition(answers[index], option, e.target.value-1)
                                    // {...answers[index], [option]:e.target.value}
                                    fillAnswers(index, answer)
                                    // const newData = 
                                    // fillAnswers(index, idx+1)
                                }}
                                onBlur={(e)=>{
                                    // if(completed) return; 
                                }}
                            />

                            <div 
                                type='text' 
                                className={classes.optionContentInput} 
                                value={option}
                            >
                                {option}
                            </div>
                            
                        </div>
                    )
                }):  
                item.options.map((option, idx)=>{
                    return (
                        <div key={idx} className={classes.optionContentItem}>
                            <input 
                                type='number' 
                                min={1}
                                max={item.options.length}
                                value={1}
                                onChange={(e)=>{
                                    // if(completed) return; 
                                    console.log(e.target.value)
                                    const answer = 
                                    sortArrayToPosition(item.options, option, e.target.value-1)
                                    // {...answers[index], [option]:e.target.value}
                                    fillAnswers(index, answer)
                                    // const newData = 
                                    // fillAnswers(index, idx+1)
                                }}
                                onBlur={(e)=>{
                                    // if(completed) return; 
                                }}
                            />

                            <div 
                                type='text' 
                                className={classes.optionContentInput} 
                                value={option}
                            >
                                {option}
                            </div>
                            
                        </div>
                    )
                })
                }  

                {
                answers[index] 
                    &&
                <div className={classes.clearout}>
                    <div 
                        className={classes.clearoutItem}
                        onClick={()=>{
                            // if(completed) return; 
                            fillAnswers(index, "")
                        }}
                    >
                        Clear All
                    </div>
                </div> 
                }

            </div>
            }

            {
            item.type && item.type === 'scale'
                &&
            <div className={classes.optionContent2}>
                <div className={classes.scaleBlockContainer}>
                    {generateRangeArray(Number(item.options[0]), Number(item.options[1]))
                    .map((num, idx)=>{
                        return (
                            <div 
                                key={idx} 
                                className={answers[index] ===num? clsx(classes.scaleBlock, classes.selected): classes.scaleBlock}
                                onClick={()=>{
                                    // if(completed) return; 
                                    fillAnswers(index, num)
                                }}
                            >
                                {num}
                            </div>
                        )

                    })}
                </div>
            </div>
            }

            {
            item.type && item.type === 'dropdown'
                &&
            <div className={classes.optionContent3}>
                <div 
                    className={classes.dropdownHeader} 
                    onClick={()=>{
                        setOpenDropDown(!openDropDown)
                    }}
                >
                    {   answers[index]?
                        <div>{answers[index]}</div>:
                        <div className={classes.dropDownDefaultText}>{`Select from the drop down`}</div>
                    }

                    {
                    !openDropDown?
                    <ExpandMore 
                        className={classes.dropDownIcon}
                        onClick={()=>{
                            setOpenDropDown(!openDropDown)
                        }}
                    />:
                    <ExpandLess 
                        className={classes.dropDownIcon}
                        onClick={()=>{
                            setOpenDropDown(!openDropDown)
                        }}
                    />
                    }
                </div>

                {
                    openDropDown 
                        &&
                    <div className={classes.dropDown}>
                        {item.options.map((option, idx)=>{
                            return (
                                <div 
                                    key={idx} 
                                    className={answers[index]===option? clsx(classes.dropDownItem, classes.selected):classes.dropDownItem}
                                    onClick={()=>{
                                        // if(completed) return; 
                                        fillAnswers(index, option)
                                        setOpenDropDown(!openDropDown)
                                    }}
                                >
                                    {option}
                                </div>
                            )
                        })}
                    </div>
                }
            </div>
            }

            {
            item.type && item.type === 'boolean'
                &&
            <div className={classes.optionContent}>
                {item.options.map((option, idx)=>{
                    return (
                        <div key={idx} className={classes.optionContentItem}>
                            <div 
                                className={classes.booleanCircle}
                                // className={ answers[index]===idx+1? clsx(classes.circle, classes.selected):classes.circle}
                                onClick={()=>{
                                    // if(completed) return; 
                                    fillAnswers(index, option)
            
                                }}
                            >
                                { answers[index]===option && <CheckIcon />}
                            </div>

                            <div 
                                className={ answers[index]===option?  clsx(classes.optionContentInput, classes.selected): classes.optionContentInput} 
                                onClick={()=>{
                                    // if(completed) return; 
                                    fillAnswers(index, option)
            
                                }}
                            >
                                {option}
                            </div>
                            
                        </div>
                    )
                })}
            </div>
            }



            {
            item.type && item.type.includes('written')
                &&
            <TextField 
                variant="outlined"
                multiline
                minRows={item.type ==='written-short'? 2:4}
                fullWidth
                className={classes.inputField}
                InputProps={{
                    style: {
                    borderRadius: "30px",
                    padding: "15px 20px",

                    },
                    classes: {
                        notchedOutline: classes.notchedOutline
                    }
                }}
                InputLabelProps={{
                    shrink: true,
                    className: classes.floatingLabelFocusStyle,
                }}
                value={answers[index] ?? ''}
                onChange={(e)=>{
                    // if(completed) return; 
                    fillAnswers(index, e.target.value)
                }}
                onBlur={()=>{
                    // if(completed) return; 
                }}
            />

            }

            {
            item.type && item.type === 'file'
                &&
            <>
            {
                answers[index] && 
                typeof answers[index] ==='object'&&

                <div className={classes.file}>
                    <FileCopyIcon />
                    <div className={classes.fileName} onClick={()=>window.open(answers[index].url)}>{answers[index].name}</div>
                </div>
            }

            <div className={classes.uploadButtonContainer}>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.uploadButton}
                    component="label"
                >
                    {
                        (answers[index] && typeof answers[index] ==='object')? 
                        `Change File`:
                        `Upload File`
                    }
                    <input 
                        hidden 
                        accept="image/jpeg,image/gif,image/png,application/pdf" 
                        type="file" 
                        onChange={(e) => {
                            console.log(e.target.files[0])
                            const fileData = e.target?.files[0];
                            const name = e.target?.files[0].name;
                            uploadFiles(fileData, index, name)                

                        }} 
                        
                    />
                </Button>
            </div>
            </>
            }




            </div>

        </>
    )
}


export default Questions;