import { responsiveFontSizes } from '@material-ui/core/styles'
import { createTheme } from '@material-ui/core/styles'
import * as COLORS from './colors'

const GRAPH_PALETTE = [
    COLORS.WISTERIA,
    COLORS.MAX_BLUE_PURPLE,
    COLORS.APRICOT,
    COLORS.LAVENDER_BLUE,
    COLORS.LANGUID_LAVENDER,
    COLORS.MIMI_PINK,
    COLORS.CAMBRIDGE_BLUE,
    COLORS.LILAC_LUSTER,
    COLORS.QUEEN_BLUE,
    COLORS.OLD_LAVENDER,
    COLORS.DARK_BYZANTIUM,
    COLORS.DARK_PURPLE,
]

// Override default Material-UI Component Theme
let FlokaTheme = createTheme({
    palette: {
        primary: {
            main: COLORS.MAX_BLUE_PURPLE,
            dark: COLORS.MAJORELLE_BLUE,
            card: '#d2d5f7',
            graph: GRAPH_PALETTE,
        },
        secondary: {
            main: COLORS.LILAC,
            dark: COLORS.CERULEAN_BLUE,
            sidebar: COLORS.MED_SLATE_BLUE,
        },
        tertiary: {
            main: COLORS.BABY_PINK,
            dark: COLORS.SPANISH_GRAY,
        },
        text: {
            primary: '#000',
        },
    },
    typography: {
        fontFamily: ['Apercu Pro', 'Value Serif Pro'].join(','),
        root: {
            color: '#000',
        },
        h1: {
            fontFamily: 'Value Serif Pro',
            fontWeight: '700',
            fontSize: '144px',
        },
        h2: {
            fontFamily: 'Value Serif Pro',
        },
        h3: {
            fontFamily: 'Value Serif Pro',
        },
        h4: {
            fontFamily: 'Value Serif Pro',
        },
        h5: {
            fontFamily: 'Value Serif Pro',
            fontWeight: 'bold',
            color: '#505050',
        },
        h6: {
            fontFamily: 'Apercu Pro',
            fontWeight: 'bold',
        },
        subtitle1: {
            fontFamily: 'Apercu Pro',
            fontSize: '18px',
        },
        subtitle2: {
            fontFamily: 'Apercu Pro',
            fontSize: '16px',
        },
        body1: {
            fontFamily: 'Apercu Pro',
        },
        body2: {
            fontFamily: 'Apercu Pro',
        },
        button: {},
        caption: {
            fontFamily: 'Apercu Pro',
            fontStyle: 'Italic',
            fontWeight: 'bold',
            color: COLORS.MAX_BLUE_PURPLE,
        },
        overline: {},
    },
    spacing: 4,
    overrides: {
        MuiAlert: {
            message: {
                color: '#FFF',
            },
        },
        MuiButton: {
            root: {
                textTransform: 'none',
                borderRadius: '4px',
            },
            textPrimary: {
                color: '#1F2627',
                '&:hover': {
                    backgroundColor: '#E4E4E4',
                },
                '&:focus': {
                    backgroundColor: '#EEEEEE',
                },
                '&.Mui-disabled': {
                    background: 'none',
                },
            },
            textSecondary: {
                color: '#1F2627',
                '&:hover': {
                    backgroundColor: '#E2E7FF',
                },
                '&:focus': {
                    backgroundColor: '#F2F4FF',
                },
                '&.Mui-disabled': {
                    background: 'none',
                },
            },
            outlined: {
                border: 'solid 2px',
                '&.Mui-disabled': {
                    border: 'solid 2px #EEEEEE',
                },
            },
            outlinedPrimary: {
                border: '2px solid #000',
                color: '#090A0A',
                '&:hover': {
                    border: '2px solid #000',
                    backgroundColor: '#E4E4E4',
                },
                '&:focus': {
                    backgroundColor: '#EEEEEE',
                },
                '&.Mui-disabled': {
                    border: 'solid 2px #EEEEEE',
                },
            },
            outlinedSecondary: {
                border: '2px solid #8595E8',
                color: '#090A0A',
                '&:hover': {
                    border: '2px solid #8595E8',
                    backgroundColor: '#E2E7FF',
                },
                '&:focus': {
                    backgroundColor: '#F2F4FF',
                },
                '&.Mui-disabled': {
                    border: 'solid 2px #EEEEEE',
                },
            },
            contained: {
                boxShadow: 'none',
                '&:hover': {
                    boxShadow: 'none',
                },
                '&:focus': {
                    boxShadow: 'none',
                },
            },
            containedPrimary: {
                color: '#FCFCFC',
                backgroundColor: '#090A0A',
                '&:hover': {
                    backgroundColor: '#494949',
                },
                '&:focus': {
                    backgroundColor: '#242424',
                },
            },
            containedSecondary: {
                color: '#FCFCFC',
                backgroundColor: '#8595E8',
                '&:hover': {
                    backgroundColor: '#4A5BAE',
                },
                '&:focus': {
                    backgroundColor: '#6576CF',
                },
            },
            // disabled: {
            //     color: '#d6d6d9',
            //     fontWeight: 'bold',
            //     backgroundColor: '#EEE'
            // },
            sizeSmall: {
                fontSize: 12,
                padding: '2px 8px',
            },
            // sizeMedium: {
            //     fontSize: 14,
            //     padding: '8px 16px'
            // },
            sizeLarge: {
                fontSize: 16,
                fontWeight: 'bold',
                padding: '16px 32px',
            },
        },
        MuiIconButton: {
            root: {
                borderRadius: 0,
                padding: 0,
                margin: '10px 0',
            },
        },
        MuiChip: {
            colorPrimary: {
                color: '#000',
                backgroundColor: '#d2d5f7',
            },
            colorSecondary: {
                color: '#FFF',
                backgroundColor: 'rgba(201, 164, 206, 0.39)',
            },
            deleteIconColorPrimary: {
                color: 'rgba(0, 0, 0, 0.26);',
            },
        },
        MuiSwitch: {
            root: {
                width: 43,
                height: 23,
                padding: 0,
                margin: 12,
            },
            switchBase: {
                padding: 0,
                margin: 2,

                '&.Mui-checked': {
                    transform: 'translateX(20px)',
                    color: '#FFF',

                    '& + .MuiSwitch-track': {
                        backgroundColor: COLORS.MAX_BLUE_PURPLE,
                        opacity: '1',
                    },
                },
            },
            thumb: {
                width: '15px',
                height: '15px',
                margin: 2,
                color: '#FFF',
            },
            track: {
                borderRadius: 40,
                backgroundColor: 'rgba(0, 0, 0, 0.26)',
                opacity: '1',
            },
        },
        MuiTab: {
            root: {
                textTransform: 'none',
                fontFamily: 'Value Serif Pro',
                fontSize: '18px',

                '&.Mui-selected': {
                    color: COLORS.LILAC,
                },
            },
            textColorInherit: {
                opacity: '1',
            },
        },
        MuiTabs: {
            root: {
                marginBottom: '26px',
            },
            indicator: {
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: COLORS.LILAC,
            },
        },
        MuiLinearProgress: {
            colorPrimary: COLORS.JET,
        },
        MuiGrid: {
            root: {
                margin: '0.5rem',
            },
        },
        MuiSelect: {
            filled: {
                paddingTop: '10px',
            },
        },
        MuiMobileStepper: {
            dot: {
                backgroundColor: COLORS.LIGHT_GRAY,
            },
            dotActive: {
                backgroundColor: '#526CF2',
            },
        },
        MuiSpeedDial: {
            fab: {
                textTransform: 'none',
                backgroundColor: 'black',
                color: COLORS.WHITE,

                '&:hover': {
                    backgroundColor: COLORS.WHITE,
                    color: COLORS.JET,
                },
                borderRadius: 48 / 2,
                padding: '0 16px',
                width: 'auto',
                minHeight: 'auto',
                minWidth: 48,
                height: 48,
            },
            actions: {
                marginLeft: '100px',
            },
        },
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: COLORS.MAX_BLUE_PURPLE,
            },
        },
        MuiSvgIcon: {
            root: {
                alignSelf: 'center',
            },
        },
    },
})

FlokaTheme = responsiveFontSizes(FlokaTheme)

export default FlokaTheme
