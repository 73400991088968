import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Drawer, Dialog,DialogContent, Paper } from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import clsx from  'clsx';
import ReactHlsPlayer from 'react-hls-player';
import { Stream } from "@cloudflare/stream-react";
import LogoFloka from '../../assets/Logo-floka.png'
import { addEllipsis } from '../../helper/stringHelper';

import PaymentButton from './PaymentButton';
import ConsultationButton from './consultationButton';
import './introduction.css'

const useStyles = makeStyles((theme) => ({
    introduction:{
        marginTop:"25px",
        // border:"1px solid red",
        background: "linear-gradient(to bottom right, #fff 0%, #fff 50%, #d2daff 50%, #d7cdea 100%)",
        paddingBottom:'100px',
         [theme.breakpoints.down(700)]: {
            background:'none',
         },
        

    },
    navigator:{
        // border:'1px solid red',
        position:'fixed',
        top: "0",
        backgroundColor:"white",
        zIndex:1,
        width:"100%",
        display:'flex',
        flexDirection:"row",
        justifyContent:'space-between',
        // [theme.breakpoints.down(1000)]: {
        //     flexDirection:"column",
        //     alignItems:'center',
        //     gap:"25px"
        // },
        [theme.breakpoints.down(1000)]: {
            flexDirection:"row",
            alignItems:'center',
            justifyContent:'space-between',
            gap:"25px",
        },

    },
    greyBlock:{
        // border:'1px solid red',
        width:"40px",
        height:'40px',
        backgroundColor:"#d6d6d9",
        borderRadius:"8px",
        marginLeft:"40px",
        marginTop:"20px",
        marginBottom:"20px",
        cursor:"pointer",
        [theme.breakpoints.down(1000)]: {
            marginLeft:"20px",
            marginBottom:"20px",
        }
    },
    navigatorContainer:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        gap:"15px",
        marginTop:"20px",
        marginBottom:"20px",
        // [theme.breakpoints.down(1000)]: {
        //     marginTop:"0px",
        //     marginBottom:"0px",
        // },
        [theme.breakpoints.down(1000)]: {
            display:"none"
        },
    },
    navigatorItem:{
        fontSize:'14px',
        fontFamily:"Poppins",
        fontWeight:"normal",
        borderRadius:'14px',
        padding:"8px 11px",
        cursor:"pointer",
        "&:hover": {
           backgroundColor: '#c7b5eb'
        }
    },
    navigatorItemActive:{
        backgroundColor:"#c7b5eb",
    },
    content:{
        display:'flex',
        flexDirection:"row",
        justifyContent:'center',
        alignItems:'center',
        gap:"60px",
        // paddingTop:"100px",
        marginTop:"150px",
        paddingLeft:"20px",
        paddingRight:"20px",

        [theme.breakpoints.down(1000)]: {
            paddingLeft:"0px",
            paddingRight:"0px",
            flexDirection:'column',
            marginTop:"20px",
        },
    },
    courseInfo:{
        width:"500px",
        [theme.breakpoints.down(600)]: {
            width:"90%"
        },

    },
    courseName:{
        fontFamily:"Northrow, Apple Chancery, cursive",
        fontSize:"67px",
        textAlign:'right',
        marginBottom:"25px",
        [theme.breakpoints.down(1000)]: {
            textAlign:'center',
            marginTop:'70px'
        },
        [theme.breakpoints.down(800)]: {
            fontSize:"70px",
            marginTop:'70px'
        },
    },
    courseDescription:{
        // border:'1px solid red',
        whiteSpace:"pre-wrap",
        fontFamily:"Poppins",
        [theme.breakpoints.down(1000)]: {
            textAlign:'center',
            marginBottom:"20px"
        },
    },
    thumbnail:{
        // border:"1px solid red",
        width:'500px',
        // maxHeight:"600px",
        objectFit:"cover",
        [theme.breakpoints.down(1000)]: {
            display:'none'
        },
    },
    thumbnailSmaller:{
        width:'500px',
        // height:'600px',
        objectFit:"cover",
        [theme.breakpoints.up(1000)]: {
            display:'none',
        },
        [theme.breakpoints.down(600)]: {
            width:"100%",
            height:"auto",
            display:'flex',
            flexDirection:"row",
            justifyContent:'center'
        },
    },
    img:{
        width:'100%',
        height:"600px",
        objectFit:"cover",
        borderRadius:'15%',
        [theme.breakpoints.down(1000)]: {
            borderRadius:"15%"

        },
        [theme.breakpoints.down(600)]: {
            width:"90%"
        },
        [theme.breakpoints.down(450)]: {
             borderRadius:'25px'
        },
    },
    couseTypeAndVideoNumber:{
        textAlign:'left',
        marginTop:"20px",
        fontFamily:'Lora',
        fontWeight:'bold',
        [theme.breakpoints.down(1000)]: {
            textAlign:'center',
        },
    },
    buttonGroup:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'flex-start',
        alignItems:"start",
        gap:"20px",
        marginTop:"20px",
        [theme.breakpoints.down(1000)]: {
            justifyContent:'center',
        },
        [theme.breakpoints.down(600)]: {
            flexDirection:'column',
            alignItems:'center'
        },
    },
    freeTrial:{
        border:'1px solid red',

    },
    sneakButton:{
        display:'flex',
        flexDirection:"row",
        justifyContent:"center",
        alignItems:'center',
        textAlign: 'center',
        padding: '8px 12px',
        marginTop:'10px',
        borderRadius: '30px',
        backgroundColor: 'black',
        color: 'white',
        fontSize: '13px',
        userSelect: 'none',
        cursor: 'pointer',
    },
    menuIcon:{
        // border:'1px solid red',
        cursor:'pointer',
        marginRight:"20px",
        [theme.breakpoints.up(1000)]: {
            display:'none',
        },

    },
    emptyBlock:{
        [theme.breakpoints.down(1000)]: {
            display:'none',
        },
    },
    modal: {
        width: '100%',
        height:'400px',
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        background:"linear-gradient(90deg, #d2daff, #d7cdea)",
        [theme.breakpoints.up(1000)]: {
           display:'none'
        },
        [theme.breakpoints.down(500)]: {
            width: '100vw'
        },
    },
    drawerContainer:{
        width:"90%",
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        gap:"35px"
    },
    drawerContainerItem:{
        fontFamily:"Poppins",
        cursor:"pointer",
        '&:hover': {
            color:"#fff"
        },
    },
    modalBox:{
        width: '100%',
        border:'1px solid red',
        '& .MuiPaper-root': {
            width: '100%',
            padding:'0px',
            margin:"0px",
            borderRadius: '10px',
            [theme.breakpoints.down(700)]: {
                width: '90%'
            },
        },

    },
    seeMoreButton:{
        // border:'1px solid red',
        marginLeft:'10px',
        color:"#6576cf",
        cursor:"pointer"
    },
    openVideoContainer:{
        border:'1px solid blue',
        // width:'1000px',
        // height:'600px',
        // boxSizing:'border-box'
    },
    dialogContent:{
        display:'flex',
        flexDirection:"column",
        width:"1000px",
        alignItems:'center',
        marginTop:"50px",
        marginBottom:"50px",
        [theme.breakpoints.down(900)]: {
            marginTop:"30px",
            marginBottom:"30px",
        },
        [theme.breakpoints.down(500)]: {
            marginTop:"15px",
            marginBottom:"15px",
        },
    }
}))


const Introduction  = ({ state, FAQs, username, courseid, navigateToSection }) =>{

    const classes = useStyles();
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [selectedNavItem, setSelectedNavItem] = useState("Start Here");
    const [openVideo, setOpenVideo] = useState(false);
    const [expanded, setExpanded] = useState(false)


    const [navigatorItems, setNavigatorItems] = useState([
        "Start Here",
        "Who is this course for?",
        "What will you learn?", 
        "Modules",
        "Testimonials",
        "The Coach",
    ])
    const [sectionIds, setSectionIds] = useState([
        'introduction',
        'experience',
        'learning',
        'modules',
        'testimonial',
        'instructor'
    ])

    useEffect(()=>{
        if(FAQs.length>0){
            setNavigatorItems([
                "Start Here",
                "Who is this course for?",
                "What will you learn?", 
                "Modules",
                "Testimonials",
                "The Coach",
                "FAQs"
            ])
            setSectionIds([
                'introduction',
                'experience',
                'learning',
                'modules',
                'testimonial',
                'instructor',
                'faq'
            ])
        }
    },[FAQs])


    return (

        <>
            <div className={classes.introduction}>
               <Drawer 
                    anchor={'top'} 
                    open={drawerOpen} 
                    onClose={() => setDrawerOpen(false)} 
                    classes={{ paper: classes.modal }}
                >
                    <div className={classes.drawerContainer}>
                        {
                            navigatorItems.map((item, index)=>{
                                return (
                                    <div 
                                        key={index} 
                                        className={classes.drawerContainerItem}
                                        onClick={()=>{
                                            navigateToSection(sectionIds[index])
                                            setDrawerOpen(false)
                                        }}
                                    >
                                        {item}
                                    </div>
                                )

                            })

                        }
                    </div>
                </Drawer>

                <div className={classes.navigator}>
                    <img 
                        className={classes.greyBlock} 
                        src={LogoFloka}
                        onClick={()=>window.open('https://www.floka.co/')}
                    />
                    <Menu className={classes.menuIcon} onClick={()=> setDrawerOpen(true)}/>
                    <div className={classes.navigatorContainer}>
                        {navigatorItems.map((item, index)=>{
                            return (
                                <div 
                                    key={index} 
                                    className={
                                        selectedNavItem ===item?
                                        clsx(classes.navigatorItem, classes.navigatorItemActive):  
                                        classes.navigatorItem

                                        // index ===0? 
                                        // clsx(classes.navigatorItem, classes.navigatorItemActive):  
                                        // classes.navigatorItem
                                    } 
                                    onClick={()=>{
                                        setSelectedNavItem(item)
                                        navigateToSection(sectionIds[index])}
                                    }
                                >
                                    {item}
                                </div>
                            )

                        })}
                    </div>
                    <div className={classes.emptyBlock}></div>
                </div>

                <div className={classes.content} id='introduction'>
                    <div className={classes.courseInfo}>
                        <div className={classes.courseName}>{state.courseOverview.courseName}</div>
                        <div className={classes.courseDescription}>
                            {expanded? 
                            state.courseOverview.courseDescription.trim(): 
                            addEllipsis(500,state.courseOverview.courseDescription)  
                            
                            }
                            {state.courseOverview.courseDescription.length>500
                               &&
                            <span 
                               className={classes.seeMoreButton}
                               onClick={()=>setExpanded(!expanded)}
                               >{expanded?`Close`:`See More` }</span>
                            }
                        </div>
                        <div className={classes.thumbnailSmaller}>
                            <img 
                                className={classes.img} 
                                src={ state.courseOverview?.instructorImage?
                                    (typeof 
                                    state.courseOverview?.instructorImage==='string'?  
                                    state.courseOverview?.instructorImage:
                                    state.courseOverview?.instructorImage[0]?.patch):
                                    state.courseOverview?.courseThumbnail} 
                                alt='instructor image'
                            />
                        </div>
                        <div className={classes.couseTypeAndVideoNumber}>
                            {state.typeCourse === 'Static'? 
                            'Full Access at Purchase  ':
                            'Ongoing / Live Course  '
                            }
                            {
                                state.isOneToOne?
                                `1:1 Coaching `
                                :
                                ``
                            }
                            {/* {
                                `${state.courseOverview?.amountVideos ? state.courseOverview?.amountVideos:0} video${state.courseOverview?.amountVideos>0?"s":''}`
                            }  */}
                        </div>
                        <div className={classes.buttonGroup}>
                            {
                            (state.courseOverview.courseCTA
                              &&
                            state.courseOverview.courseCTA.consultation)
                            ?
                            <ConsultationButton 
                                link={state.courseOverview.courseCTA.link}
                                freeTrial={state.paymentSettings.freeTrial}
                            />
                            :
                            <PaymentButton 
                                username={username} 
                                courseid={courseid}
                                price={state.paymentSettings.price}
                                freeTrial={state.paymentSettings.freeTrial}
                            />
                            }
                            <Button
                              variant='contained'
                              color='primary'
                              className={classes.sneakButton}
                              onClick={()=>{
                                setOpenVideo(true)
                              }}
                            >
                                Watch a Sneak Peek
                            </Button>
                        </div>


                    </div>
                    <div className={classes.thumbnail}>
                        <img 
                            className={classes.img} 
                            src={
                                state.courseOverview?.instructorImage?
                                (typeof 
                                state.courseOverview?.instructorImage==='string'?  
                                state.courseOverview?.instructorImage:
                                state.courseOverview?.instructorImage[0]?.patch):
                                state.courseOverview?.courseThumbnail
                            } 
                            alt='instructor image'
                        />
                    </div>

                </div>
            </div>

            <Dialog 
                open={openVideo}
                onClose={()=>setOpenVideo(false)}
                // className={classes.modalBox}
                PaperProps={{ style: { width: '100%'} }}
            >
                <Paper style={{ width: '100%', height: '100%' }}>
                    {/* <ReactHlsPlayer
                        src={`https://customer-43tpssqc1qvan2fr.cloudflarestream.com/${state.courseOverview.previewVideo}/manifest/video.m3u8`}
                        autoPlay={true}
                        controls={true}
                        width="100%"
                        height="auto"                                   
                        style={{  borderRadius:"7px" }}
                    /> */}
                    <Stream 
                        controls 
                        src={state.courseOverview.previewVideo} 
                        responsive={true}  
                        autoplay={true}
                        muted={true}
                        width='100%'
                        height='100%'
                    />
                </Paper>

                
                {/* <div className={classes.openVideoContainer}>
                    <Stream 
                        controls 
                        src={state.courseOverview.previewVideo} 
                        responsive={true}  
                        autoplay={true}
                        muted={true}
                        height='500px'
                    />
                </div> */}
            
            </Dialog>
          

        </>
    )
}


export default Introduction