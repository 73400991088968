import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements, AddressElement } from "@stripe/react-stripe-js";
import { TextField } from "@material-ui/core";
import { useNavigate } from 'react-router-dom'
import { makeStyles } from "@material-ui/core";

import ErrorSignUp from "../ErrorAuth";
import SuccessAuth from "../SuccessAuth";

import {
    paymentIntent
} from '../../lib/stripe/index'

import { baseUrl } from "../../lib/config";
import paymentOptions from '../../assets/paymentOptions.png'
import { Mixpanel } from '../../lib/Analytics/Mixpanel'


import './style.css'
import axios from "axios";
import { ContactSupportOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    img: {
        width: "100px"

    },
    cardContainer: {
        // border:'1px solid red',
        width: "90%",
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        [theme.breakpoints.down(1200)]: {
            width: "95%",
        },
        [theme.breakpoints.down(800)]: {
            width: "90%",
        },
        [theme.breakpoints.down(750)]: {
            width: "100%",
        },

    }
}))



export default function CheckoutForm({
    data,
    isCourseOneToOne,
    mailchimp,
    emailData,
    patientEmail,
    practitionerEmail,
    courseid,
    saveCourse,
    paymentOption,
    setIspaymentSuccessful,
    authData,
    addressInfoComplete,
    setAddressInfoComplete,
    promoCodeValid
 }) {


    const classes = useStyles()
    const navigate = useNavigate();
    // 1️⃣ Setup state to track client secret, errors and checkout status
    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [notification, setNotification] = useState('')
    const [processing, setProcessing] = useState("");
    const [disabled, setDisabled] = useState(true);
    const closeError = () => setError('')
    const closeNotification = () => setNotification('')

    // 2️⃣ Store reference to Stripe
    const stripe = useStripe();
    const elements = useElements();

    const [userInfo, setUserInfo] = useState({
        name: "",
        address: {
            city: "",
            country: '',
            line1: '',
            line2: '',
            postal_code: "",
            state: ""
        }
    })
    const [textInfoComplete, setTextInfoComplete] = useState(false)
    const [eventTriggered, setEventTriggered] = useState(false)


    const handleChange = async (event) => {

        // 3️⃣ Listen for changes in the CardElement and display any errors as the customer types their card details
        setDisabled(event.empty);
        setError(event.error ? event.error.message : "");

    };

    const getClientSecret = async (response) => {

        if (data.paymentSettings.freeTrial) {

            return await stripe.confirmCardSetup(response, {
                payment_method: {
                    card: elements.getElement(CardElement),
                },
            });

        } else {

            return await stripe.confirmCardPayment(response, {
                payment_method: {
                    card: elements.getElement(CardElement),
                },
            });
        }


    }


    const handleSubmit = async (ev) => {

        ev.preventDefault();
        if (!textInfoComplete) {
            setAddressInfoComplete(false)
            return;
        }

        setProcessing(true);

        console.log('1')

        // 4️⃣ Create PaymentIntent and fetch client secret as soon as the page loads

        const response = await paymentIntent(data, paymentOption, authData, userInfo, promoCodeValid.valid ? promoCodeValid : undefined) //coupon
        console.log(response)




        // 5️⃣ Confirm Card Payment.
        const payload = await getClientSecret(response)
        
        
        if (payload.error) {

            setError(`Payment failed:  ${payload.error.message}`);
            setProcessing(false);

        } else {

            setError(null);
            setProcessing(false);
            setSucceeded(true);
            saveCourse()
            console.log('SUCESSFULL!')
            setNotification('The payment is successful!')
            handleSendEmailToPatient()
            handleSendEmailToPractitioner(practitionerEmail)
            if (mailchimp.mailchimpListId) {
                handleAddUserEmailToAudienceList(mailchimp)
            }

            Mixpanel.track(`/purchaseCompleted/${data.courseid}`)



        }
    };

    // add client email into user(practitioner) mailchimp account:
    const handleAddUserEmailToAudienceList = (mailchimp) => {
        let params = {
            mailchimpObj: mailchimp,
            email: emailData.patientEmail,
            name: emailData.patientName
        };
        axios.post(`${baseUrl}/flokaLanding/mailchimpAddEmail`, params)
            .then(res => console.log('mailchimp add successful', res))
            .catch(err => {
                console.log('something went wrong', err)
            })

    }

    // send email to patient after purchase is successful
    const handleSendEmailToPatient = () => {
        let params = {
            ...emailData,
            patientEmail: patientEmail,
            isCourseOneToOne
        }
        axios.post(`${baseUrl}/flokaLanding/paymentEmail/send`, params)
            .then(res => console.log(res))
            .catch(err => {
                console.log('something went wrong', err)
            })
    }

    // send email to practitioner after purchase is successful
    const handleSendEmailToPractitioner = (practitionerEmail) => {

        let params = {
            practitionerEmail,
            courseName: emailData.courseName
        };
        axios.post(`${baseUrl}/flokaLanding/practitioner/paymentEmail/send`, params)
            .then(res => console.log(res))
            .catch(err => {
                console.log('something went wrong', err)
            })


    }


    // 6️⃣ Construct UI.
    return (
        <>
            <form id="payment-form" onSubmit={handleSubmit} className="form-class-stripe">
                <div className={classes.cardContainer}>
                    <img className={classes.img} src={paymentOptions} alt='payment option' />
                </div>
                <CardElement
                    id="card-element"
                    options={{}}
                    onChange={handleChange}
                />
                <AddressElement
                    options={{ mode: 'shipping' }}
                    onChange={(event) => {
                        console.log('event', event)
                        setUserInfo(event.value)
                        setAddressInfoComplete(true)
                        setTextInfoComplete(event.complete)

                    }}

                />


                <div className='button-container'>
                    <button
                        disabled={processing || disabled || succeeded}
                        id="submit"
                        className="button-class-stripe"
                    >
                        <span id="button-text">
                            {succeeded ?
                                "Already paid"
                                :
                                processing ?
                                    <div className="spinner" id="spinner">Paying...</div>
                                    :
                                    "Complete My Order"
                            }
                        </span>
                    </button>
                </div>
            </form>

            {error && <ErrorSignUp text={error} onClose={closeError} />}
            {succeeded && <SuccessAuth text={notification} onClose={closeNotification} />}
        </>
    );
}