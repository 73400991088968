import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { addEllipsis } from '../../helper/stringHelper';

import Module from './module';
import PaymentButton from './PaymentButton';
import ConsultationButton from './consultationButton';


const useStyles = makeStyles((theme) => ({
    modules:{
        background:"linear-gradient( #d2daff, #d7cdea)",
        padding:"80px 30px",
        display:'flex',
        flexDirection:'column',
        justifyContent:"center",
        alignItems:'center',
    },
    moduleTitle:{
        textAlign:'center',
        fontFamily:'Lora',
        fontSize:"40px",
        fontStyle:"italic",
        [theme.breakpoints.down(700)]: {
            fontSize:"35px",
        },
    },
    videosForYouSpan: {
        textAlign:'center',
        fontSize: '12px',
        fontWeight: '500',
        marginTop:"15px",
        [theme.breakpoints.down(700)]: {
            fontSize: '18px',
            marginTop:"25px"
        },
    },
    takeaPeak: {
        color: 'black',
        fontSize: '18px',
        textAlign: 'center',
        fontFamily: 'Value Serif Pro',
        marginTop: '30px',
        marginBottom: '50px',
        [theme.breakpoints.down(700)]: {
            fontSize: '20px',
            fontWeight:'bold'
        },
    },
    moduleContainer:{
        // border:'1px solid red',
        display:'flex',
        flexDirection:"row",
        justifyContent:'center',
        flexWrap:"wrap",
        gap:"30px",
        [theme.breakpoints.up(2500)]: {
            width:"50%"
        },
    },
    buttonGroup:{
        // textAlign:'center',
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        gap:"20px",
        marginTop:"40px"
    },
    oneToOnePuterContainer:{
        // border:'1px solid red',
        display:'flex',
        flexDirection:'row',
        justifyContent:"center",
        marginTop:"30px",
        [theme.breakpoints.up(2500)]: {
            width:"40%"
        },
         [theme.breakpoints.down(600)]: {
            width:"100%"
        },


    },
    oneToOneContainer:{
        border:"1px solid #6e6f71",
        borderRadius:"16px",
        width:"100%",
        display:"flex",
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'center',
        gap:'20px',
        padding:'20px 20px 30px 20px',
        [theme.breakpoints.down(1200)]: {
            width:"95%",
            gap:'10px',
        },
        [theme.breakpoints.down(1174)]: {
            flexDirection:'column',
            justifyContent:'center',
            width:"500px",
        },
    },
    practitionerName:{
        color:"#5d35a9",
        fontSize:"25px",
        [theme.breakpoints.down(1174)]: {
            textAlign:'center'
        },
    },
    name:{
        fontSize:'18px'
    },
    textCopy:{
    [theme.breakpoints.down(1174)]: {
        textAlign:'center',
        marginTop:"25px"
    },

    },
    header:{
        fontSize:"22px",
        fontWeight:'bold',
        fontFamily:'Lora'
    },
    subHeader:{
        marginTop:"10px",
        width:'800px',
        fontSize:"15px",
        [theme.breakpoints.down(1174)]: {
            width:'100%',
        },
    }

}))

const Modules = ({ state, username, courseid , practitioner}) =>{

    const classes = useStyles();

    return (
        <>
           <div className={classes.modules} id='modules'>
                <div className={classes.moduleTitle}>
                    {state.typeCourse ==='Static'? 
                    `Root-Cause Education Built for You`: 
                    `New Videos each Week built for you...`}
                </div>

                {/* <div className={classes.videosForYouSpan}>What to expect next...</div> */}

                <Typography className={classes.takeaPeak}>
                    A sneak peak at what you will be learning...
                </Typography>

                <div className={classes.moduleContainer}>
                    {Object.keys(state.modules)
                        .sort((a,b)=> state.modules[a].moduleIndex - state.modules[b].moduleIndex)
                        .map((moduleName, index) => {

                            const module = state.modules[moduleName].moduleData
                            return (
                                <Module 
                                    key={index}
                                    number={index+1} 
                                    moduleName={moduleName}
                                    module={module}
                                />
                            )

                        })}

                </div>

                {
                    state.isOneToOne
                      &&
                    <div className={classes.oneToOnePuterContainer}>
                        <div className={classes.oneToOneContainer}>
                            <div className={classes.practitionerName}>
                                1:1 Session
                                <div className={classes.name}>With {practitioner}</div>


                            </div>

                            <div className={classes.textCopy}>
                                <div className={classes.header}>
                                    Creating a Personalized Experience
                                </div>

                                <div className={classes.subHeader}>
                                      Through this course, you will be able to book in 1:1 sessions
                                       with your instructor to clarify, discuss, or learn more about
                                        course content, making this experience personalized to you.
                                </div>

                            </div>

                        </div>
                    </div>
                }

                <div className={classes.buttonGroup}>
                    {
                        (state.courseOverview.courseCTA
                            &&
                        state.courseOverview.courseCTA.consultation)
                        ?
                        <ConsultationButton 
                            link={state.courseOverview.courseCTA.link}
                        />
                        :
                        <PaymentButton 
                            username={username} 
                            courseid={courseid}
                            price={state.paymentSettings.price}
                        />
                     }
                </div>

           </div>
        </>
    )
}

export default Modules