// import everything from firebase package
import { CodeSharp } from '@material-ui/icons'
import firebase from 'firebase/app'
require('firebase/database')
require('firebase/auth')

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

// Initialize Firebase
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig)
} else {
    firebase.app() // if already initialized, use that one
}

// initialize Realtime Database and Authentication service
const database = firebase.database()
const auth = firebase.auth
const projectAuth = firebase.auth()

// Third-party Sign in/Sign out Providers
let googleProvider = new firebase.auth.GoogleAuthProvider()
googleProvider.addScope('https://www.googleapis.com/auth/user.birthday.read')

let facebookProvider = new firebase.auth.FacebookAuthProvider()
facebookProvider.addScope('user_birthday')

const appleProvider = new firebase.auth.OAuthProvider('apple.com')

// Listen to Authenticaton provider to setUser
function onAuthStateChange(callback) {
    return firebase.auth().onAuthStateChanged((user) => {
        console.log('user', user)
        if (user) {            
            callback({
                id: user.uid,
                email:user.email
            })
        } else {
            callback(false)
        }
    })
}

export {
    database,
    auth,
    projectAuth,
    googleProvider,
    facebookProvider,
    appleProvider,
    onAuthStateChange,
}
