import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {  makeStyles, Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import clsx from  'clsx';

const useStyles = makeStyles((theme) => ({
    modalBox: {
        '& .MuiPaper-root': {
            width: '100%',
            overflow:'scroll',
            display:'block',
            maxWidth: '1000px',
            borderRadius: '10px',
            [theme.breakpoints.down(500)]: {
                height:"100%"
            },
        }
    },
    box:{
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
        justifyContent:"flex-end",
        [theme.breakpoints.down(500)]: {
            justifyContent:'center'
        },

    },
    headerText:{
        width:"80%",
        [theme.breakpoints.down(500)]: {
            width:"90%"
        },
    },
    modalTitle: {
        gap:"10px",
        color:"#000",
        textAlign: 'center',
        margin: '40px 0px 0px 0px',
        display:'flex',
        flexDirection:"column",
        justifyContent:'center',
        alignItems:"center",
        
    },
    darkDescription: {
        textAlign: 'center',
        fontSize: '15px',
        marginTop:"30px",
        marginBottom: '4px',
        fontWeight:"normal",
        width:"60%",
        [theme.breakpoints.down('600')]: {
            width:"80%",
        },
    },
    button: {
        width: '350px',
        backgroundColor:"#000",
        color:"#fff",
        borderRadius: '5px',
        textAlign: 'center',
        padding: '10px 10px',
        [theme.breakpoints.down(500)]: {
            padding:"7px 7px",
            width:"90%"
        },
    },
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems:'center',
        marginBottom: '5%',
        gap: '20px',
        [theme.breakpoints.down(900)]: {
            flexDirection:'column',
            alignItems:"center",
            gap: '20px'

        },

    },
    dialogContent:{
        display:'flex',
        flexDirection:"column",
        alignItems:'center',
        marginTop:"10px",
        marginBottom:"10px",
    }


}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const SuccessModalAfterConnect =({  onClose, open, newPractitionerName })=>{

    const classes = useStyles()
    const navigate = useNavigate();

    return (
        <>
           <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                // onClose={() => setShowPrompt(false)}
                aria-describedby="alert-dialog-slide-description"
                className={classes.modalBox}
           >

            <DialogTitle>
                {/* <Box className={classes.box}>
                    <Box>
                        <IconButton onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box> */}

            </DialogTitle>
            <Typography variant="h4" paragraph className={classes.modalTitle}>
                <span className={classes.headerText}>
                    Congratualtions! You Successfully connected with {newPractitionerName}!
                </span>
            </Typography>
            <DialogContent className={classes.dialogContent}>
                    
                <Typography variant="h6" paragraph className={classes.darkDescription}>
                    Your coach can now connect with you 1:1 and is able to view your sleep, 
                    fitness, cycle, mind, body, and nutrition data that is synced into Flöka. 
                </Typography>

                <Typography variant="h5" paragraph className={classes.darkDescription}>
                    Let's Get Started!
                </Typography>  

            </DialogContent>
            <DialogActions className={classes.buttonsContainer}>

                <Button 
                    variant='contained' 
                    onClick={()=>{
                        window.open("https://www.floka.co/iphone-vs-web","_self")
                    }} 
                    className={classes.button}
                >
                    Take me to my course
                </Button>

            </DialogActions>
        </Dialog>
        </>
    )
    


}

export default SuccessModalAfterConnect