import axios from 'axios'
import { projectAuth } from './firebase_config'
import { baseUrl } from '../config'



/**
 * Retrieves Google Fit API oAuth url.
 *
 * @param {String} patientId patient ID
 * @param {Object} invite
 * @return {Promise<any>}
 */
const updateCounterClient = (courseid, patientid, practitionerid) => {

    return new Promise((resolve, reject) => {
        projectAuth.currentUser
            .getIdToken(true)
            .then(function (idToken) {

                const reqBody = {
                    headers: {
                        Authorization: `${idToken}`,
                    },
                }

                const params = {
                    courseid,
                    patientid,
                    practitionerid,
                }

                console.log(params)

                axios
                    .post(`${baseUrl}/flokaLanding/update/clientList_courseCounter`, params, reqBody)
                    .then((res) => resolve(res.data))
                    .catch((err) => {
                        console.log(err)
                        reject(JSON.stringify(err))})
            })
            .catch(function (error) {
                reject(error)
            })
    })
}

export default updateCounterClient
