import React from 'react';
import ReactGA from 'react-ga4';

const GAEventsTracker = (
    category = 'Event Category',
    action ='action', 
    label= 'label'
) =>{

    ReactGA.event({ category, action, label })
}


export default GAEventsTracker