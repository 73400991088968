import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {  makeStyles, Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import clsx from  'clsx';
import { DisconnectPractitoner, ConnectPractitioner, RemoveTracking } from "../../lib/Patient";

const useStyles = makeStyles((theme) => ({
    modalBox: {
        '& .MuiPaper-root': {
            width: '100%',
            display:'block',
            overflow:'scroll',
            maxWidth: '1000px',
            borderRadius: '10px',
            [theme.breakpoints.down(500)]: {
                height:"100%"
            },
        }
    },
    box:{
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
        justifyContent:"flex-end",
        [theme.breakpoints.down(500)]: {
            justifyContent:'center'
        },

    },
    headerText:{
        [theme.breakpoints.down(500)]: {
            width:"90%"
        },
    },
    modalTitle: {
        gap:"10px",
        color:"#000",
        textAlign: 'center',
        margin: '10px 0px 15px 0px',
        display:'flex',
        flexDirection:"column",
        justifyContent:'center',
        alignItems:"center",
        
    },
    darkDescription: {
        textAlign: 'center',
        fontSize: '13px',
        marginBottom: '4px',
        fontWeight:"normal",
        width:"50%",
        [theme.breakpoints.down('600')]: {
            width:"80%",
        },
    },
    button: {
        width: '350px',
        backgroundColor:"#000",
        color:"#fff",
        borderRadius: '5px',
        textAlign: 'center',
        padding: '10px 10px',
        [theme.breakpoints.down(500)]: {
            padding:"7px 7px",
            width:"90%"
        },
    },
    removeButton:{
        width: '350px',
        backgroundColor:"#de0000",
        color:"#fff",
        borderRadius: '5px',
        textAlign: 'center',
        padding: '10px 10px',
        [theme.breakpoints.down(500)]: {
            padding:"7px 7px",
            width:"90%"
        },

    },
    
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems:'start',
        marginBottom: '5%',
        gap: '83px',
        [theme.breakpoints.down(900)]: {
            flexDirection:'column',
            alignItems:"center",
            gap: '20px'

        },

    },
    buttonGroup:{
        display:"flex",
        flexDirection:"column",
        alignItems:'center',
        gap:"12px"

    },
    buttonTextBelow:{
        fontSize:"12px",
        width:"300px",
        textAlign:'center',
        color:"#6e6f71",
        [theme.breakpoints.down(500)]: {
            width:"80%",
        },
    },
    directConnectBuutton:{
        border:'1.5px solid #000',
        backgroundColor:"#fff",
        color:"#000",
        '&:hover': {
            backgroundColor: '#fff',
        },
    },
    directConnectBuuttonWithPractitioner:{
        height:'70px',
        border:'1.5px solid #000',
        backgroundColor:"#fff",
        color:"#000",
        '&:hover': {
            backgroundColor: '#fff',
        },

    },

    img:{
        width:"30px"
    },
    dialogContent:{
        display:'flex',
        flexDirection:"column",
        alignItems:'center',
        marginTop:"50px",
        marginBottom:"50px",
        [theme.breakpoints.down(900)]: {
            marginTop:"30px",
            marginBottom:"30px",
        },
        [theme.breakpoints.down(500)]: {
            marginTop:"15px",
            marginBottom:"15px",
        },
    }


}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const SuccessModalOneToOne =({ 
    onClose, 
    open, 
    userName, 
    patientHasPractitioner, 
    currentPractitionerName,
    practitionerId,
    newPractitionerId,
    patientId,
    newPractitionerName,
    setOneToOneAfterConnect,
    courseId
})=>{

    const classes = useStyles()
    console.log('old practitionerid', practitionerId)
    console.log('new practitionerid', newPractitionerId)

    const removeFlag = async (patientId, courseId, flag) =>{
        let res = await RemoveTracking(patientId, courseId, flag)
        console.log('res',res)
    }

    const disconnect =async ()=>{
        removeFlag(patientId, courseId, 'connect')
        let resDisconnect = await DisconnectPractitoner(practitionerId,patientId)
        console.log('disconnect success',resDisconnect)
        let resConnect = await ConnectPractitioner(newPractitionerId, patientId, courseId)
        console.log('connect success',resConnect)
        onClose()
        setOneToOneAfterConnect(true)
        
    }

    const connect = async ()=>{
        removeFlag(patientId, courseId, 'connect')
        let res = await ConnectPractitioner(newPractitionerId, patientId, courseId)
        console.log('connect success', res)
        onClose()
        setOneToOneAfterConnect(true)
    


    }

    console.log("patientHasPractitioner", patientHasPractitioner)

    return (
        <>
           <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                // onClose={() => setShowPrompt(false)}
                aria-describedby="alert-dialog-slide-description"
                className={classes.modalBox}
           >

            <DialogTitle>
                {/* <Box className={classes.box}>
                    <Box>
                        <IconButton onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box> */}

            </DialogTitle>
            <Typography variant="h5" paragraph className={classes.modalTitle}>
                <span className={classes.headerText}>Congratualtions! You Successfully enrolled!</span>
                <span className={classes.headerText}>Sharing Health Data with Your Coach</span>
            </Typography>
            <DialogContent className={classes.dialogContent}>
                <Typography variant="h5" paragraph className={classes.darkDescription}>
                     Hello {userName} 
                </Typography>  
                    
                <Typography variant="h6" paragraph className={classes.darkDescription}>
                    The course you have purchased contains a 1:1 package. 
                    Included in this package is the ability to share your health data 
                    and tracking with your coach. This way your coach can get a full 360 
                    degree view of your health and provide next steps/recommendations accordingly.
                    Would you like to share your data with your coach?
                </Typography>

            </DialogContent>
            <DialogActions className={classes.buttonsContainer}>
                <div className={classes.buttonGroup}>
                    <Button 
                        variant='contained' 
                        onClick={()=>{
                            removeFlag(patientId, courseId, 'consent')
                            window.open("https://www.floka.co/iphone-vs-web","_self")
                        }}
                        className={
                            patientHasPractitioner?
                            clsx(classes.button, classes.directConnectBuuttonWithPractitioner):
                            clsx(classes.button, classes.directConnectBuutton)
                        }
                    >
                        Continue without flöka connection
                    </Button>
                    <div className={classes.buttonTextBelow}>
                        You will still be able to book 
                        1:1s with this practitioner
                        independent of flöka
                    </div>
                </div>
                <div className={classes.buttonGroup}>
                    {
                    patientHasPractitioner?
                    <Button 
                        variant='contained' 
                        onClick={disconnect} 
                        className={classes.removeButton}
                        disabled={newPractitionerId===practitionerId}
                    >
                       { 
                       newPractitionerId===practitionerId?
                       `You are already connect to this ${currentPractitionerName} 
                       so you don't need to connect with her again `:
                       `I want to disconnect with my current coach ${currentPractitionerName}, 
                        and connect and share data with ${newPractitionerName}`
                        }
                    </Button>
                        :
                    <Button 
                        variant='contained' 
                        onClick={connect} 
                        className={classes.button}
                    >
                        Yes, Connect and Share my Data with my Coach
                    </Button>
                    }
                    <div className={classes.buttonTextBelow}>
                        I understand that by connecting I consent to be share
                        any wellness data I have inputted on the flöka apps or synced to 
                        Flöka, including but not limited to the categories of sleep, 
                        cycle, fitness, mind, body and nutrition.
                    </div>
                </div>
            </DialogActions>
        </Dialog>
        </>
    )
    


}


export default SuccessModalOneToOne