import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button, Link as MuiLink } from '@material-ui/core';
import { Instagram, YouTube, Launch, MusicNoteOutlined } from '@material-ui/icons';
import { FiberManualRecord } from '@material-ui/icons';
import { useParams } from 'react-router-dom';
import ErrorNoUsername from '../../elements/ErrorNoUsername';
import { Link } from 'react-router-dom';

import axios from 'axios'

import LoadingSpinner from '../../elements/LoadingSpinner'
import ErrorNoLanding from '../../elements/ErrorNoLanding';


import Quotations from '../../assets/Quotations.svg'
import { baseUrl } from '../../lib/config'
import { Stream } from '@cloudflare/stream-react';
import ReactHlsPlayer from 'react-hls-player';

import { firstLetterUpper, addEllipsis } from '../../helper/stringHelper';
import GAEventsTracker from '../../lib/GoogleAnalytic';
import { Helmet } from 'react-helmet-async';

import { Mixpanel } from '../../lib/Analytics/Mixpanel'



const useStyles = makeStyles((theme) => ({
    dialog: {
        position: 'absolute',
    },
    modalBox: {
        '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '1000px',
            borderRadius: '10px'
        }
    },
    container: {
        width: "100%",
        margin: 0,
        background: 'linear-gradient(to bottom, #fff , #eae2fc , #fbf9ff)',
        backgroundAttachment: "fixed",
        backgroundRepeat: "repeat",
        // position: 'absolute',
        // top: 0,
        left: 0,
        right: 0,
        // bottom: 0,
        // zIndex: -1,

    },
    checkIcon: {
        color: 'green',
        width: "20px"
    },
    introText: {
        margin: "30px",
    },
    tag: {
        backgroundColor: "#fff",
        display: "inline-block",
        margin: "10px",
        padding: "5px",
        borderRadius: "5px",
        border: "1px solid #000"
    },
    tagContainer: {
        margin: "30px",
        marginBottom: "20px",
        flexWrap: "wrap",
        display: 'flex',
        flexDirection: "row",
        alignItems: "center"
    },
    button: {
        margin: '20px'
    },

    coverPhoto: {
        width: "100%",
        height: "auto",
        borderBottomLeftRadius: "30px",
        objectFit: "cover",
        opacity: "100%",
        [theme.breakpoints.down(900)]: {
            width: "100%",
            height: 'auto'
        },
        [theme.breakpoints.down(600)]: {
            width: "80%",
            borderBottomLeftRadius: "30px",
            borderTopLeftRadius: "30px"
        },
    },
    coverPhotoContainer: {
        display: "flex",
        alignItems: 'flexStart',
        maxHeight: "600px",
        [theme.breakpoints.down(900)]: {
            alignItems: "flexStart",
        },
        [theme.breakpoints.down(600)]: {
            justifyContent: "flex-end"
        },
    },



    aboutMe: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        marginTop: "80px",
        [theme.breakpoints.down(1000)]: {
            width: '100%',
            flexDirection: "column",
            alignItems: "center"
        },
    },

    personalCoverPhoto: {
        margin: "40px",
        borderRadius: "50px",
        width: "40%",
        height: "500px",
        objectFit: 'cover',
        [theme.breakpoints.down(1000)]: {
            width: "80%"
        },
    },
    verticalVideo: {
        objectFit: "cover",
        margin: "40px",
        borderRadius: "50px",
        width: "27%",
        height: "600px",
        [theme.breakpoints.down(1000)]: {
            width: "80%"
        },
    },
    videoUpload: {
        objectFit: "cover",
        margin: "20px",
        width: "30%",
        height: "450px",
        // maxHeight:"300px",
        [theme.breakpoints.down(700)]: {
            width: "60%"
        },
    },
    aboutMeTitleLarge: {
        [theme.breakpoints.down(1000)]: {
            display: 'none'
        },
    },
    aboutMeTitleSmall: {
        [theme.breakpoints.up(1000)]: {
            display: 'none'
        },
        [theme.breakpoints.down(1000)]: {
            marginBottom: '25px'
        },
    },

    aboutMeRight: {
        display: "flex",
        alignItems: "flex-end",
        position: "relative",
        right: "20px",
        top: "70px",
        flexDirection: "column",
        marginBottom: "50px",


        [theme.breakpoints.down(1000)]: {
            width: '100%',
            alignItems: "center",
            position: "static",
            right: "0",
            top: "0",
        },
    },
    aboutMeContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
        alignItems: 'center',
        width: "500px",
        marginTop: "50px",
        padding: '30px 50px',
        backgroundColor: "#fff",
        borderRadius: "5px",
        borderTopRightRadius: "50px",
        boxShadow: "3px 3px 3px 3px rgba(36, 36, 36, 0.3)",
        [theme.breakpoints.down(1000)]: {
            width: "60%",
            textAlign: 'center',
            borderTopRightRadius: "50px",
            borderTopLeftRadius: "50px",
            marginTop: "20px",

        },

    },
    socialMedia: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        flexWrap: "wrap"
    },
    people: {
        marginTop: "30px",
        display: 'flex',
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    },
    peopleComment: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        flexWrap: 'wrap'

    },
    testimonial: {
        width: "200px",
        minHeight: '160px',
        maxHeight: "fit-content",
        margin: "20px",
        padding: "20px",
        borderRadius: "10px",
        display: "flex",
        flexDirection: 'column',
        alignItems: "center",
        justifyContent: "space-between",
        boxShadow: "3px 3px 3px rgba(36, 36, 36, 0.3)",

    },
    underline: {
        width: "100%",
        marginTop: "12px"
    },

    //Course:
    courseSection: {
        // border:"1px solid red",
        display: "flex",
        flexDirection: 'column',
        justifyContent: "flex-start",
        alignItems: 'flex-start',
        margin: "50px",
        width: "100%",
        [theme.breakpoints.down(600)]: {
            textAlign: 'center'
        },
    },
    courseText: {
        marginTop: "20px",
        [theme.breakpoints.down(600)]: {
            textAlign: 'center'
        },

    },
    courses: {
        // border:'1px solid red',
        display: "flex",
        flexDirection: "column",
        alignItems: 'center',
        width: "100%",
        //     [theme.breakpoints.down(1000)]: {
        //       width:"90%",
        //    },
    },
    course: {
        //    border:'1px solid blue',
        marginTop: '40px',
        backgroundColor: "#fff",
        borderRadius: "10px",
        boxShadow: "3px 3px 3px 3px rgba(36, 36, 36, 0.3)",
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "space-between",
        padding: "40px",
        width: "90%",
        [theme.breakpoints.down(1000)]: {
            flexDirection: "column",
            alignItems: 'center',

        },
        [theme.breakpoints.down(600)]: {
            alignItems: 'center',

        },

    },
    courseDetail: {
        margin: "15px"
    },
    courseDetailLearn: {
        margin: "15px",
        display: "none",
        [theme.breakpoints.down(1000)]: {
            display: 'block'

        },

    },
    courseDetailTitle: {
        [theme.breakpoints.down(460)]: {
            fontSize: '13px'
        },
    },
    courseDetailPoint: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "7px",
        [theme.breakpoints.down(460)]: {
            fontSize: '11px'
        },
    },
    courseDetailCircle: {
        width: "8px"
    },
    courseImg: {
        width: "400px",
        height: "400px",
        objectFit: 'cover',
        borderRadius: "10px",

        [theme.breakpoints.down(1000)]: {
            width: "60vw",
            height: "60vw"
        },
        [theme.breakpoints.down(500)]: {
            width: "50vw",
            height: "50vw"
        },
        [theme.breakpoints.down(380)]: {
            width: "45vw",
            height: "45vw"
        },
    },
    contactSection: {
        width: "20%",
        display: "flex",
        flexDirection: "row",
        justifyContent: 'space-around'
    },
    videoContainer: {
        marginTop: "30px",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: "center",
        [theme.breakpoints.down(700)]: {
            flexDirection: 'column'
        },
    },
    videoInfo: {
        display: "flex",
        flexDirection: "column",
        alignItems: 'center',
        fontSize: "25px",
        marginBottom: '25px'
    },
    videoText: {

    },
    StreamVideoWrapper: {
        height: '600px',
        width: '30%',
        borderRadius: "50px",
        [theme.breakpoints.down(1000)]: {
            width: '40%',
        },
        [theme.breakpoints.down(650)]: {
            width: '60%',
        },
        [theme.breakpoints.down(500)]: {
            width: '80%',
        },
    },
    buttonGroup: {
        display: 'flex',
        flexDirection: "row",
        justifyContent: "space-between",
        [theme.breakpoints.down(600)]: {
            flexDirection: "column",
            justifyContent: 'center',
            alignItems: 'center'
        },
    },
    cancelButton: {
        width: "180px",
        margin: '20px',
        [theme.breakpoints.down(600)]: {
            width: "100%"
        },
    },
    publishButton: {
        width: '180px',
        margin: '20px',
        [theme.breakpoints.down(600)]: {
            width: "100%"
        },
    },
    link: {
        textDecoration: "none"
    },
    introductionMargin: {
        marginLeft: "30px",
        marginRight: "30px",
        [theme.breakpoints.down(600)]: {
            marginLeft: "0px",
            marginRight: "0px",
        },
    },
    courseMargin: {
        marginTop: "30px",
        marginLeft: "50px",
        marginRight: "50px",
        [theme.breakpoints.down(600)]: {
            marginLeft: "0px",
            marginRight: "0px",
        },

    },
    ulList: {
        // border:'1px solid red',
        padding: "10px",
        width: '90%',
        listStylePosition: "inside",
        '& li': {
            // border:'1px solid red',
            textAlign: 'left',
            marginBottom: '10px',
            padding: "0px 10px"
        },
        [theme.breakpoints.down(500)]: {
            width: "90%",
            '& li': {
                textAlign: 'center',

            },
        },
        [theme.breakpoints.down(400)]: {
            '& li': {
                fontSize: "13px"
            },
        },

    },

}))




const LandingPage = ({ }) => {

    const [isLoading, setIsLoading] = useState(true)
    const [practitonerName, SetPractitonerName] = useState('Practitioner')
    const [state, setState] = useState(undefined)
    const [error, setError] = useState(undefined)
    let { username } = useParams()

    const courseRef = useRef();

    const goToCourse = () => {
        courseRef.current.scrollIntoView({ behavior: 'smooth', block: "start" })
    }

    const urlCorrection = (url) => {
        return url.startsWith('www') ?
            `https://${url}` : url.startsWith('https') ? url : `https://www.${url}`
    }

    const classes = useStyles();


    const initialRequest = async () => {
        setIsLoading(true)


        await axios.get(
            `${baseUrl}/flokaLanding/${username.toLowerCase()}`
        )
            .then((res) => {
                console.log('res', res)
                console.log(res.data.landing)
                //res.data.landing
                
                if (res.data?.data === "Landing page cannot be found") {
                    setError(res.data?.data)
                    return
                }
                setState(res.data.landing)
                SetPractitonerName(res.data.practitioner)
            })
            .catch((err) => {
                // Practitioner Name
                // err.response.data.practitioner
                console.log(err.response.data.practitioner)
                setError(err.response.data.error)

            })


        setIsLoading(false)
    }


    useEffect(() => {

        initialRequest()

        //Google analytics event tracking:
        // GAEventsTracker("Page View", `/${username}`, `${username}`)
    }, [])
    // 
    useEffect(() => {

        if (state?.practitionerid) {

            let newUserFlag = localStorage.getItem(`new_user_${state.practitionerid}`)

            if (newUserFlag) {
                newUserFlag = false
            } else {
                localStorage.setItem(`new_user_${state.practitionerid}`, true)
                newUserFlag = true


            }

            Mixpanel.track(`/landingPageVisit/${state.practitionerid}`, {
                "new_user": newUserFlag
            })



        }


    }, [state])







    const backgroundColorsForTestimonials = ['#e2e7ff', '#f6f2ff', '#d2daff'];
    const borderColorForTestimonials = ['#aebaf8', '#8595e8', '#c7b5eb'];



    const socialMedia = () => {
        return (
            <>
                {
                    state.instagram
                    &&
                    <MuiLink
                        className={classes.socialMediaLink}
                        href={urlCorrection(state.instagram)}
                        target="_blank"
                    >
                        <Instagram />
                    </MuiLink>
                }

                {
                    state.youtube
                    &&
                    <MuiLink
                        className={classes.socialMediaLink}
                        href={urlCorrection(state.youtube)}
                        target="_blank"
                    >
                        <YouTube />
                    </MuiLink>
                }
                {
                    state.tikTok
                    &&
                    <MuiLink
                        className={classes.socialMediaLink}
                        href={urlCorrection(state.tikTok)}
                        target="_blank"
                    >
                        <MusicNoteOutlined />
                    </MuiLink>

                }
                {
                    state.website
                    &&
                    <MuiLink
                        className={classes.socialMediaLink}
                        href={urlCorrection(state.website)}
                        target="_blank"
                    >
                        <Launch />
                    </MuiLink>
                }
            </>
        )
    }

    return (
        <>
            {/* introduction */}
            {!isLoading && !error && <>
                {/* introduction */}
                <Helmet>
                    <title>
                        {practitonerName}
                    </title>
                    <meta
                        name="description"
                        content={`${state.about_me}`}
                    />
                    <meta
                        name='keywords'
                        content={`${state?.tags?.join(", ")}, ${practitonerName}`}
                    />
                    <link rel='canonical' href={`/${username}`} />
                </Helmet>
                <Grid
                    container
                    className={classes.container}
                >
                    <Grid
                        container
                        spacing={2}
                        item
                        xs={12}
                        className={classes.introductionMargin}
                    >
                        <Grid item sm={6} style={{ width: "100%" }}>
                            <div style={{ height: "100%" }}>
                                <Typography variant='h4' className={classes.introText}>{`${practitonerName}`}</Typography>

                                <Typography className={classes.introText} >
                                    {state.mission_statement}
                                </Typography>


                                <div className={classes.tagContainer}>
                                    <Typography>Tags:</Typography>
                                    {state?.tags?.map((tag, index) => {
                                        return (
                                            <div key={index} className={classes.tag}>{tag}</div>
                                        )
                                    })

                                    }
                                </div>

                                <Button
                                    color="primary"
                                    variant='contained'
                                    className={classes.button}
                                    onClick={
                                        state.link && state.courses.length === 0 ?
                                            () => window.open(urlCorrection(state.link)) :
                                            goToCourse
                                    }
                                >
                                    {` Learn from ${practitonerName}`}
                                </Button>
                            </div>

                        </Grid>

                        <Grid item sm={6} style={{ width: "100%" }}>
                            <div style={{ height: "100%" }} className={classes.coverPhotoContainer}>
                                <img
                                    className={classes.coverPhoto}
                                    src={state.cover_photo}
                                    alt={'Cover Photo'}
                                />
                            </div>
                        </Grid>
                    </Grid>


                    {/* About Me Section */}
                    <Grid
                        container
                        spacing={2}
                        item
                        xs={12}
                        style={{ marginBottom: "50px" }}
                    >
                        <Grid item className={classes.aboutMe}>
                            <Typography variant="h4" className={classes.aboutMeTitleSmall}>
                                {`About ${practitonerName}`}
                            </Typography>
                            {state.selected_profile_type === 'photo'
                                &&
                                <img
                                    className={classes.StreamVideoWrapper}
                                    src={state.profile_photo}
                                    style={{ objectFit: "cover" }}
                                    alt={'Personal Cover Photo'}
                                />

                            }

                            {
                                state.selected_profile_type === 'video'
                                &&
                                state.vertical_video.startsWith('blob')
                                &&
                                <video
                                    src={state.vertical_video}
                                    className={classes.verticalVideo}
                                    controls
                                >

                                </video>
                            }

                            {
                                state.selected_profile_type === 'video'
                                &&
                                !state.vertical_video.startsWith('blob')
                                &&
                                <div className={classes.StreamVideoWrapper}>
                                    <ReactHlsPlayer
                                        src={`https://customer-43tpssqc1qvan2fr.cloudflarestream.com/${state.vertical_video}/manifest/video.m3u8`}
                                        autoPlay={false}
                                        controls={true}
                                        width="100%"
                                        height="100%"
                                        style={{ borderRadius: "50px", objectFit: 'cover' }}
                                    />
                                </div>
                            }

                            <div className={classes.aboutMeRight}>
                                <Typography variant="h4" className={classes.aboutMeTitleLarge}>
                                    {`About ${practitonerName}`}
                                </Typography>

                                <div className={classes.aboutMeContainer}>
                                    <Typography variant="body1" paragraph>
                                        {state.about_me}
                                    </Typography>
                                    <div className={classes.socialMedia}>
                                        {socialMedia()}
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        spacing={2}
                        item
                        xs={12}
                        justifyContent="center"
                        style={{ marginTop: "100px" }}
                    >
                        <Grid className={classes.people}>
                            <Typography variant='h5'>What are People Saying...</Typography>
                            <div className={classes.peopleComment}>
                                {state?.written_testimonials && 
                                    state?.written_testimonials
                                    .filter(test => test !== '')
                                    .map((testimonial, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className={classes.testimonial}
                                                style={{
                                                    backgroundColor:
                                                        backgroundColorsForTestimonials[Math.floor(Math.random() * backgroundColorsForTestimonials.length)],
                                                    // border: `1px solid ${borderColorForTestimonials[Math.floor(Math.random() * borderColorForTestimonials.length)]}`
                                                }}
                                            >
                                                <img src={Quotations} />
                                                <Typography style={{ minHeight: "40px", marginTop: '20px', padding: "15px", textAlign: "center", width: "200px", wordWrap: "break-word" }}>
                                                    {typeof testimonial === 'string' ? addEllipsis(250, firstLetterUpper(testimonial)) : addEllipsis(250, firstLetterUpper(testimonial.text))}
                                                </Typography>
                                                <div style={{ padding: "15px", fontFamily: 'Apercu', textAlign: "center", width: "200px", wordWrap: "break-word" }}>
                                                    {typeof testimonial === 'object'
                                                        &&
                                                        <Typography>- {testimonial.name && firstLetterUpper(testimonial.name)}</Typography>
                                                    }
                                                    <div
                                                        className={classes.underline}
                                                        style={{ marginBottom: "12px", border: `0.6px solid ${borderColorForTestimonials[Math.floor(Math.random() * borderColorForTestimonials.length)]}` }}
                                                    >
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                            </div>
                        </Grid>

                    </Grid>

                    {/* Course Section */}
                    {
                        state.courses.length > 0
                        &&
                        <Grid
                            container
                            spacing={2}
                            item
                            xs={12}
                            className={classes.courseMargin}

                        >
                            <Grid className={classes.courseSection}>
                                <Typography ref={courseRef} variant='h4'>{`Learn From ${practitonerName} Now`}</Typography>

                                <Typography className={classes.courseText}>
                                    {`Here's how to learn from ${practitonerName}. ${practitonerName.split(' ')[0]} has created a 
                                    gorgeous learning experience for you to learn how to heal deeply, 
                                    with the help of cutting edge technology you may already be using, 
                                    like flöka and her thousands of wellness integrations.`}
                                </Typography>

                                {state.courses.map((course) => {
                                    return (
                                        <div className={classes.courses} key={course.courseid}>
                                            <div className={classes.course}>
                                                <div>
                                                    <Typography variant='h6'>
                                                        <b>{course.courseOverview.courseName}</b>
                                                    </Typography>
                                                    <div className={classes.courseDetail}>
                                                        <Typography className={classes.courseDetailTitle}>
                                                            <b>Who is this experience perfect for?</b>
                                                        </Typography>

                                                        <ul className={classes.ulList}>
                                                            {course.courseOverview.targetAudience
                                                                .map((point, index) => {
                                                                    return (
                                                                        <li key={index}>
                                                                            {point}
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                                {/* <div className={classes.courseImgContainer}> */}
                                                <img
                                                    src={
                                                        typeof course.courseOverview?.courseThumbnail === 'string' ?
                                                            course.courseOverview.courseThumbnail
                                                            :
                                                            course.courseOverview.courseThumbnail[0].patch
                                                    }
                                                    alt={'course'}
                                                    className={classes.courseImg}
                                                />
                                                {/* </div> */}
                                            </div>
                                            <Link to={`/${username.toLowerCase()}/course/${course.courseid}`} className={classes.link}>

                                                <Button
                                                    style={{ position: "relative", top: "-15px" }}
                                                    color='primary'
                                                    variant='contained'
                                                >

                                                    Learn More and Get Started
                                                </Button>

                                            </Link>
                                        </div>
                                    )
                                })}

                            </Grid>

                        </Grid>
                    }

                    <Grid
                        container
                        spacing={2}
                        item
                        xs={12}
                        justifyContent='center'
                    >

                        <Grid className={classes.contactSection}>
                            {socialMedia()}
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        spacing={2}
                        item
                        xs={12}
                        justifyContent='center'
                        style={{ marginTop: "30px", marginBottom: "50px" }}
                    >
                        <Typography>
                            Powered by &nbsp;
                            <a href='https://www.floka.co/' target='_blank'>flöka</a>
                        </Typography>
                    </Grid>

                </Grid>
            </>}

            {/* Loading Spinner */}
            {isLoading && !error && <LoadingSpinner />}

            {/* Error handling */}
            {!isLoading && error === "Username cannot be found" && <ErrorNoUsername username={username} />}
            {!isLoading && error === "Landing page cannot be found" && <ErrorNoLanding />}

        </>
    )
}


export default LandingPage;