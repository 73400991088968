import * as React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const useStyles = makeStyles((theme) => ({

    accordionMain: {

        width:'100%',
        border: '0.5px solid #dbdbdb',
        boxShadow:"none",
        borderRadius: '8px !important',
        "&.MuiAccordion-root:before": {
            height:'0px',
            backgroundColor: "none"
        },
        '& .MuiAccordionSummary-root': {
            '& .MuiAccordionSummary-content': {
                margin: '12px 10px !important'
            },
            
            '& .MuiAccordionSummary-expandIcon': {
                marginRight: '9px !important'
            },


        },
        '& .MuiAccordionSummary-root.Mui-expanded': {
            // boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
            borderRadius: '12px',
            marginBottom: '20px',

            '& .MuiAccordionSummary-content.Mui-expanded': {
                margin: '12px 10px !important'
            }

        }
    },
    descriptionModule: {
        color: '#494949',
        fontFamily: 'Poppins',
        fontWeight: 'normal',
        fontSize: '23px',
        [theme.breakpoints.down(700)]: {
            fontSize:"20px",
        },
        [theme.breakpoints.down(450)]: {
            fontWeight: '1.2',
            fontSize:"18px",
        },
    },
    accordionSummaryhere: {
        minHeight: 'unset !important',
        // margin: '0px 15px 0px 10px !important',

        '& .MuiAccordionSummary-content.Mui-expanded': {
            // margin: '0px 15px 0px 10px !important',

            // borderRadius: '10px',
            // boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
            // minHeight: '20px'
        },
    },
    moduleDescription:{
        whiteSpace:"pre-wrap",
        fontFamily: 'Poppins',
    },
    accordionDetails: {
        margin: '0px 10px',
    },
    expandMoreIcon:{
      color:"#dbdbdb"
    }
}))

export default function CollapseElement({ faq}) {

    const classes = useStyles()

    return (
            <Accordion className={classes.accordionMain}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon className={classes.expandMoreIcon}/>}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    className={classes.accordionSummaryhere}
                    sx={{
                        '&:before': {
                            display: 'none',
                        }
                    }}
                >
                    <Typography className={classes.descriptionModule}>{faq.question}</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                    <Typography className={classes.moduleDescription}>
                        {faq.answer}
                    </Typography>
                </AccordionDetails>
            </Accordion>
    );
}
