import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { addEllipsis } from '../../helper/stringHelper';

const useStyles = makeStyles((theme) => ({

    background:{
        background:"linear-gradient(90deg, #d2daff, #d7cdea)",
        padding:"80px 30px",
        display:'flex',
        flexDirection:'column',
        justifyContent:"center",
        alignItems:'center'
    },
    backgroundTitle:{
        textAlign:'center',
        fontFamily:'Lora',
        fontSize:"40px",
        fontStyle:"italic",
        [theme.breakpoints.down(700)]: {
            fontSize:"35px",
        },

    },
    backgroundContainer:{
        // border:'1px solid red',
        marginTop:"60px",
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        flexWrap:"wrap",
        gap:"30px",
        [theme.breakpoints.up(2500)]: {
            width:"50%"
        },
    },
    backgroundItem:{
        border:"1px solid #222222",
        lineHeight:"normal",
        fontSize:"18px",
        fontFamily:"Poppins",
        borderRadius:'20px',
        width:"400px",
        height:'400px',
        textAlign:"center",
        padding:"",
        [theme.breakpoints.down(1100)]: {
            fontSize:"18px",
            width:"400px",
            height:'400px',
        },
        [theme.breakpoints.down(500)]: {
            lineHeight:"1.3",
            fontSize:"14px",
            width:"300px",
            height:'300px',
        },
        [theme.breakpoints.down(400)]: {
            lineHeight:"1.2",
        },
    },

    reason:{
        margin:'15px',
        fontWeight:'bold'
    },
    line:{
        width:"100%",
        border:"0.6px solid #222222"
    },
    audienceContainer:{
        height:'300px',
        // border:"1px solid red",
        display:'flex',
        flexDirection:"row",
        justifyContent:"center",
        alignItems:"center",
        [theme.breakpoints.down(1100)]: {
            height:'300px',
        },
        [theme.breakpoints.down(500)]: {
            height:'230px',
        },
    },
    audience:{
        padding:"20px 20px",
    },
    expand:{
        fontWeight:'bold',
        textAlign:'center',
        marginTop:'40px',
        cursor:"pointer"
    }

}))

const Experience  = ({ state }) =>{

    const classes = useStyles();
    const [isExpanded, setIsExpanded] = useState(false)

    return (
        <>
          <div className={classes.background} id='experience'>
            <div className={classes.backgroundTitle}>
                This {state.isOneToOne? `Personalized`:``} experience is perfect for you if...
            </div>

            <div className={classes.backgroundContainer}>
                {isExpanded?
                    state.courseOverview.targetAudience.map((target, index)=>{
                        return (
                            <div key={index} className={classes.backgroundItem}>
                                <div className={classes.reason}>Reason {index+1}</div>
                                <div className={classes.line}></div>
                                <div className={classes.audienceContainer}>
                                    <div className={classes.audience}>
                                        {addEllipsis(350, target)}
                                    </div>
                                </div>
                            </div>
                        )

                    }):
                    state.courseOverview.targetAudience.slice(0,2).map((target, index)=>{
                        return (
                            <div key={index} className={classes.backgroundItem}>
                                <div className={classes.reason}>Reason {index+1}</div>
                                <div className={classes.line}></div>
                                <div className={classes.audienceContainer}>
                                    <div className={classes.audience}>
                                        {addEllipsis(350, target)}
                                    </div>
                                </div>
                            </div>
                        )

                    })
                }
            </div>

            {state.courseOverview.targetAudience.length>2
              &&
            <div 
                className={classes.expand}
                onClick={()=>setIsExpanded(!isExpanded)}
            >
               {isExpanded? "Close": "See More"}
            </div>
            }

          </div>
        </>
    )
}

export default Experience