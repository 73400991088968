function sortArrayToPosition(array, item, number) {
    // Make a copy of the original array to avoid modifying it directly.
    const sortedArray = array.slice();
  
    // Remove the item from the array, if it exists.
    const itemIndex = sortedArray.indexOf(item);
    if (itemIndex !== -1) {
      sortedArray.splice(itemIndex, 1);
    }
  
    // Ensure the specified position is within the bounds of the array.
    number = Math.max(0, Math.min(number, sortedArray.length));
  
    // Insert the item at the specified position.
    sortedArray.splice(number, 0, item);
  
    return sortedArray;
  }

export default sortArrayToPosition