import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Avatar, Divider } from '@material-ui/core';
import { Check } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    outerContainer:{
        // fontFamily:"Playfair Display",
        display:'flex',
        flexDirection:"column",
        justifyContent:'center',
        alignItems:'center'

    },

    stepForm: {
        fontSize: '16px',
        color: 'black',
        textAlign: 'center',
        marginBottom: '10px',
        display:'flex',
        flexDirection:'row',
        justifyContent:"center",
        alignItems:"center"
    },
    userCard:{
        width:"70%",
        display:"flex",
        flexDirection:"row",
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:"#f9f9f9",
        borderRadius:"5px",
        padding:"25px 30px 25px 30px",
        [theme.breakpoints.down('400')]: {
            flexDirection:"column",
            justifyContent:'center',
            alignItems:"center"
        },
    },
    userAvatar:{
        width:"70px",
        height:"70px",
        margin:"10px"
    },
    userInfo:{
        textAlign:'center',
        [theme.breakpoints.down('400')]: {
            marginTop:"10px",
            marginBottom:"15px",
            textAlign:"center"
        },
    },
    userName:{
        fontWeight:"bold",

    },
    userEmail:{
        fontSize:"13px"
    },
    signoutButton:{
        marginTop:"20px",
        width:"80%",
        textAlign:'center'
    },
    alreadyHasCourse:{
        width:"80%",
        display:"flex",
        flexDirection:"row",
        justifyContent:"center",
        alignItems:'center',
        backgroundColor:"#F5F5F5",
        color:"#000",
        fontWeight:'bold',
        height:"50px",
        margin:'5px',
        marginTop:'25px',
        borderRadius:"5px",
        marginBottom:'20px',
        [theme.breakpoints.down(600)]: {
            width:"90%",
        },
    },
    check:{
        fontSize:"14px"
    },
    addressIncomplete:{
        width:'80%',
        padding:"15px 0px 15px 0px",
        borderRadius:'8px',
        backgroundColor:"#ffe8e8",
        marginBottom:'20px',
        fontSize:"16px"
    }

}))
const Authenticated = ({ 
    authData, 
    courseOwned,
    auth,
    addressInfoComplete,
    patientEmail 
}) =>{

    const classes = useStyles()

    return (
        <>
            <div className={classes.outerContainer}>
                {
                (!addressInfoComplete
                    &&
                !courseOwned)
                    &&
                <div className={classes.addressIncomplete}>
                    User information is not complete.
                </div>
                }
                <div className={classes.stepForm}>
                    <div>You Are Signed In</div> &nbsp; <Check  className={classes.check}/>
                </div>

                <div className={classes.userCard}>
                    {/* <Avatar
                        className={classes.userAvatar}
                        variant="circular"
                        src={`https://floka-web-portal-practitioner-assets.s3.ca-central-1.amazonaws.com/default-avatar.svg`}
                    /> */}
                    <div className={classes.userInfo}>
                        <div className={classes.userName}>{authData.user.firstName}</div>
                        <div className={classes.userEmail}>{patientEmail}</div>
                    </div>
                </div>


            <div>
            </div>
            {
                courseOwned ===true
                    &&
                auth==='auth'
                    &&
                <div className={classes.alreadyHasCourse}>
                    <span>You already bought this course!</span>
                </div>
            }
            </div>
        </>
    )
}


export default Authenticated