import { projectAuth } from './firebase_config'

/**
 * Signout current login patient
 */

const signout = () => {
    projectAuth.signOut()
}

export default signout
