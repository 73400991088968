import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { PlayArrow } from '@material-ui/icons';



const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '15px'

    },
    freeTrial: {
        fontSize: '14px'
    },
    link: {
        textDecoration: "none",
    },
    purchaseButton: {
        display: 'flex',
        flexDirection: "row",
        justifyContent: "center",
        alignItems: 'center',
        textAlign: 'center',
        padding: '8px 12px',
        marginTop: '10px',
        borderRadius: '30px',
        backgroundColor: 'black',
        color: 'white',
        fontSize: '13px',
        userSelect: 'none',
        cursor: 'pointer'
    },
    triangle: {
        color: "#fff"
    },
}))

const PaymentButton = ({ username, courseid, price, freeTrial }) => {

    const classes = useStyles();
    
    return (
        <>
            <div className={classes.buttonContainer}>
                <Link to={`/${username.toLowerCase()}/course/${courseid}/payment`} className={classes.link}>
                    <div className={classes.purchaseButton}>
                        <span>${price}</span>
                        &nbsp; | &nbsp;
                        <span>Start Healing Deeper</span>
                        <PlayArrow className={classes.triangle} />
                    </div>
                </Link>
                {freeTrial
                    &&
                    freeTrial === true
                    &&
                    <div className={classes.freeTrial}>
                        Start 7-Day Free Trial
                    </div>
                }
            </div>
        </>
    )

}

export default PaymentButton