/**
 * Convert the first character of @param string into uppercase.
 *
 * @param {String} string
 * @return A new string with uppercase first character and the rest of the string unchange.
 */
 export const firstLetterUpper = (string = '', el) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

/**
 * Convert the first character of @param string into uppercase.
 *
 * @param {String} string
 * @return A new string with uppercase first character and the rest of the string unchange.
 */
 export const pluralHelper = (text = '', number) => {
    return number>1? `${text}s`: text
}

/**
 * Truncate string to have a maximum length of @param maxLength and
 * append ellipsis to the end of the string.
 *
 * @param {Number} maxLength
 * @param {String} string
 * @return {String} @param string if string has length < maxLength,
 *                  else truncated string with length = maxLength, excluding the ellipsis
 */
 export const addEllipsis = (maxLength, string) => {
    if (!string) return
    if (string.length < maxLength) return string
    return string.substring(0, maxLength) + ` ...`
}


export const urlCorrection = (url) =>{
    return  url.startsWith('www')?  
     `https://${url}`: url.startsWith('https')? url: `https://www.${url}`
}
