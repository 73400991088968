import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import './payment.css'

const useStyles = makeStyles((theme) => ({

    informationContainer:{      
        // fontFamily:"Playfair Display",
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        marginBottom:"20px",
        padding:'20px',
        [theme.breakpoints.down(600)]: {
            padding:"0px"
        },
    },
    inputContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
        marginBottom: '12px',
        // border:"1px solid red",

    },
    inputLabel: {
        textAlign: 'left',
        fontSize:"14px",
        fontWeight: 'normal',
        marginBottom: '3px'
    },
    courseNameInput: {
        width: '90%',
        padding: '10px',
        fontSize: '13px',
        borderRadius: '4px',
        border: '1px #c5c5c5 solid',
        backgroundColor:"#fff",

        '&:focus': {
            outline: 'none',
            border: "1px #090a0a solid",
        },
        // [theme.breakpoints.down(600)]: {
        //     width: '100%',
        // },

    },
    forgetPassword:{
        marginTop:'30px',
        color:"#000"
    },
    buttonContainer:{
        width:"60%",
        backgroundColor:"#000",
        marginTop:"30px",
        borderRadius:"25px"
    },
}))

const SignInSection = ({  
    inputHandlerFunction, 
    signInHandler,
    signInData,
    auth,

}) =>{

    const classes = useStyles();
    const stepSignIn = ["Email", "Password"]

    const placeholderObj ={
        "Email":"example@gmail.com",
        "Password":"Enter Password"
    }

    return (
        <>
            <div className={classes.informationContainer}>

                {stepSignIn && stepSignIn.map((step, idx) => (

                    <div key={idx} className={classes.inputContainer}>
                        <div className={classes.inputLabel}>
                            {step}
                        </div>

                        <input 
                            className={classes.courseNameInput} 
                            type={step==='Password'? 'password':"text"} 
                            onChange={(input) => inputHandlerFunction(input.target.value, step, auth)} 
                            placeholder={placeholderObj[step]}
                        />
                    </div>

                ))}

                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => signInHandler()}
                    aria-label="assign-program"
                    className={classes.buttonContainer}
                    disabled={!signInData.Email || !signInData.Password}
                    >
                     Continue
                </Button>

                <a
                    href="https://myprogram.portal.floka.co/pw-forget"
                    target="_blank"
                    className={classes.forgetPassword}
                >
                    Forgot Password?
                </a>
            </div>


                <br />
                <br />
                <br />
                <br />
        </>
    )
}

export default SignInSection