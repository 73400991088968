import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { addEllipsis } from '../../helper/stringHelper';
import LoyaltyIcon from '@material-ui/icons/Loyalty';

import DiscountIcon from '../../assets/discount_icon.png'


const useStyles = makeStyles((theme) => ({
    courseDescriptionContainer: {
        // border:'1px solid red',

        fontFamily: "Playfair Display",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        padding: "0px 10px",
        width: "100%",
        [theme.breakpoints.down(800)]: {
            width: "85%",
            padding: "0px",
        },

    },
    courseDescription: {
        width: "100%",
        marginTop: "50px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        overflow: "hidden"

    },
    fixedData: {
        [theme.breakpoints.down(800)]: {
            position: 'unset',
            display: 'flex',
            flexDirection: "column",
            alignItems: 'center',

        },
    },
    courseTitle: {

        fontFamily: "Playfair Display",
        fontSize: '29px',
        color: 'black',
        width: "80%",
        [theme.breakpoints.down(800)]: {
            width: '100%',
        },
    },
    pracNameDescription: {
        maxWidth: '400px',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '30px',
        [theme.breakpoints.down(800)]: {
            maxWidth: '100%',
        },

    },
    pracName: {
        fontSize: '13px',
        fontWeight: '600'
    },
    pracDescription: {
        fontSize: '17px',
        lineHeight: '20px',
        fontWeight: '400',
        paddingRight: "20px",
        whiteSpace: "pre-wrap"
    },
    disclaimer: {
        fontSize: '13px',
        color: 'gray',
        paddingRight: "20px",
        marginTop: '20px',
    },
    paymentContainer: {
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        borderRadius: '3px',
        padding: '17px',
        marginBottom: '20px',
        backgroundColor: 'white',
        height: 'fit-content',
        marginBottom: '20px',
        maxWidth: '80%'
    },
    optionSelected: {
        border: '1px solid #494949'

    },
    optionNotSelected: {
        border: '1px solid #B6B6B6',

    },
    checkboxRound: {
        width: '0.9em',
        height: '0.9em',
        backgroundColor: 'white',
        borderRadius: '50%',
        verticalAlign: 'middle',
        border: '1px solid #090A0A',
        appearance: 'none',
        outline: 'none',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'


    },
    checked: {
        width: '0.6em !important',
        height: '0.6em !important',
        backgroundColor: 'black !important',
        borderRadius: '50%',
        verticalAlign: 'middle',
        appearance: 'none',
        outline: 'none',
        cursor: 'pointer',

    },
    paymentOption: {

        fontFamily: "Playfair Display",
        fontWeight: "500",
        fontSize: '16px',
        color: 'black',
        marginLeft: '25px'
    },

    relativeContainer: {
        position: 'relative'
    },
    discountPosition: {
        width: '45px',
        position: 'absolute',
        top: '-10px',
        right: '-10px',
    }


}))

const CourseDescription = ({
    state,
    courseType,
    isCourseOneToOne,
    paymentOptions,
    selectedOption,
    setSelectedOption,
    promoCodeValid

}) => {

    const classes = useStyles();
    console.log('state!!!!', state)



    const percent_off_calculation = (price, optionSubscription) => {

        const percent_off = (promoCodeValid.type.percent_off / 100 ) * price
        console.log('price', price)
        console.log('promoCodeValid.type.percent_off',promoCodeValid.type.percent_off)
        console.log('percent_off',percent_off)
        let calculatePrice = price - percent_off

        console.log('calculatePrice',calculatePrice)
        console.log('price',price)

        if (calculatePrice > 0.50 && calculatePrice < 0.50) {
            calculatePrice = price
        }


        return (
            <>

                {
                    calculatePrice === 0 ?
                        `Free Course ( $ ${calculatePrice} USD )`
                        :
                        `${optionSubscription === 'Single' ? 'One-Time Payment' : optionSubscription} 
                                    - $ ${calculatePrice.toFixed(2)} USD`
                }

            </>
        )

    }

    const amount_off_calculation = (price, optionSubscription) => {

        let amount_off = promoCodeValid.type.amount_off

        amount_off /= 100

        let price_with_amount_off = price - amount_off

        if (price_with_amount_off < 0) {
            price_with_amount_off = 0
        }

        if (price_with_amount_off > 0 && price_with_amount_off < 50) {
            price_with_amount_off = price
        }


        return (
            <>

                {
                    price_with_amount_off === 0 ?
                        `Free Course ( $ ${price_with_amount_off} USD )`
                        :
                        `${optionSubscription === 'Single' ? 'One-Time Payment' : optionSubscription} 
                                    - $ ${price_with_amount_off.toFixed(2)} USD`
                }

            </>
        )

    }


    return (
        <>
            <Grid
                container
                item
                direction={'column'}
                className={classes.courseDescriptionContainer}
            >
                <div className={classes.courseDescription}>

                    <div className={classes.fixedData}>


                        <Typography className={classes.courseTitle}>
                            {state.courseOverview.title}
                        </Typography>


                        <div className={classes.pracNameDescription}>

                            <span className={classes.pracName}> {state.practitioner} • {courseType} Course {isCourseOneToOne ? `• 1:1 Package` : ``}</span>

                            <br />

                            <span className={classes.pracDescription}>{addEllipsis(650, state.courseOverview.description)}</span>

                            <br />

                            {
                                courseType
                                &&
                                <span
                                    className={classes.disclaimer}>
                                    ** This is {courseType === 'Static' ? "a" : 'an'} {courseType.toLowerCase()} course.
                                    {
                                        courseType === 'Static' ?
                                            `
                                    This means ${state.practitioner} specially created a full course you 
                                    will have instant-access to from anywhere.
                                `
                                            :
                                            `This means ${state.practitioner} 
                                will be adding more videos to the course indefinetely. 
                                As such you will have new material through this course as 
                                long as you have a subscription.`
                                    }
                                </span>
                            }

                            <span className={classes.disclaimer}>
                                ** The information herein is for educational purposes only,
                                provided as an information resource only, and does not
                                constitute medical advice. It is not to be used or
                                relied on for any diagnostic or treatment purposes.
                                Please speak to your doctor about your medical questions.

                            </span>

                            {
                                isCourseOneToOne
                                &&
                                <span className={classes.disclaimer}>
                                    ** This course includes a 1:1 package. As a result, if you already have
                                    a coach connected to your account, please be advised that connecting with
                                    {state.practitioner} will disconnect your connection with your current coach.
                                </span>
                            }



                        </div>

                        {/* Mock of options */}


                        {
                            promoCodeValid?.valid ?
                                <>
                                    {paymentOptions && paymentOptions.map((option, idx) => {
                                        const { optionSubscription, currency, value } = option
                                        console.log(option, idx)

                                        return (
                                            <div key={idx} className={`${classes.paymentContainer} ${selectedOption === idx ? classes.optionSelected : classes.optionNotSelected} ${classes.relativeContainer}`} onClick={() => {
                                                setSelectedOption(idx)
                                            }}>
                                                 <img src={DiscountIcon} className={classes.discountPosition} />
                                                <div className={classes.checkboxRound} width={45}>

                                                    {selectedOption === idx && <div className={classes.checked} />}

                                                </div>


                                                <Typography className={classes.paymentOption}>

                                                    {
                                                        promoCodeValid.type.percent_off ?

                                                            <>

                                                                {
                                                                    percent_off_calculation(Number(value), optionSubscription)
                                                                }

                                                            </>

                                                            :
                                                            <>

                                                                {
                                                                    amount_off_calculation(Number(value), optionSubscription)
                                                                }

                                                            </>
                                                    }


                                                </Typography>

                                            </div>

                                        )


                                    })}
                                </>

                                :
                                <>
                                    {paymentOptions && paymentOptions.map((option, idx) => {
                                        const { optionSubscription, currency, value } = option
                                        console.log(option, idx)

                                        return (
                                            <div key={idx} className={`${classes.paymentContainer} ${selectedOption === idx ? classes.optionSelected : classes.optionNotSelected}`} onClick={() => {
                                                setSelectedOption(idx)
                                            }}>
                                                <div className={classes.checkboxRound}>

                                                    {selectedOption === idx && <div className={classes.checked} />}

                                                </div>


                                                <Typography className={classes.paymentOption}>
                                                    {
                                                        Number(value) === 0 ?
                                                            `Free Course ( $ ${value} ${currency} )`
                                                            :
                                                            `${optionSubscription === 'Single' ? 'One-Time Payment' : optionSubscription} 
                                                             - $ ${value} ${currency}`
                                                    }
                                                </Typography>

                                            </div>

                                        )


                                    })}
                                </>

                        }




                    </div>




                </div>
            </Grid>
        </>

    )
}

export default CourseDescription