import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
// Make sure to call loadStripe outside of a component’s render to avoid recreating the Stripe object on every render.
// Specicy Stripe Publishable API key here
const promise = loadStripe(`${process.env.REACT_APP_NODE_ENV === 'production'
    ? "pk_live_51HCrBpIazuHtyn2CqcZkh3Ll1PcAIP9VsJ9lKG55oqTD4qOIz09K7T9SOrGmYECbIOtpSw4MESUzp5mBhiTGoCVf00kxysYD6T"
    : "pk_test_51HCrBpIazuHtyn2CE7C3GDruQL1WjX0dUXVV0XlT0c1rOmHHaZOa6x3JzDlfsvIRVsr1qnAsDpNZRAaBv4ZKfd0h001FQaRjX9"}`);
// const promise = loadStripe(`pk_test_51HCrBpIazuHtyn2CE7C3GDruQL1WjX0dUXVV0XlT0c1rOmHHaZOa6x3JzDlfsvIRVsr1qnAsDpNZRAaBv4ZKfd0h001FQaRjX9`);
// Initialize Stripe Elements


export default function App({
    data,
    isCourseOneToOne,
    mailchimp,
    emailData,
    patientEmail,
    practitionerEmail,
    courseid,
    saveCourse,
    paymentOption,
    setIspaymentSuccessful,
    authData,
    addressInfoComplete,
    setAddressInfoComplete,
    promoCodeValid
}) {
    return (
        <div className="App">
            <Elements stripe={promise}>
                <CheckoutForm
                    data={data}
                    isCourseOneToOne={isCourseOneToOne}
                    mailchimp={mailchimp}
                    emailData={emailData}
                    patientEmail={patientEmail}
                    practitionerEmail={practitionerEmail}
                    courseid={courseid}
                    saveCourse={saveCourse}
                    paymentOption={paymentOption}
                    setIspaymentSuccessful={setIspaymentSuccessful}
                    authData={authData}
                    addressInfoComplete={addressInfoComplete}
                    setAddressInfoComplete={setAddressInfoComplete}
                    promoCodeValid={promoCodeValid}
                />
            </Elements>
        </div>
    );
}