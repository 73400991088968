import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { addEllipsis } from '../../helper/stringHelper';
import { Typography, Button, Link as MuiLink } from '@material-ui/core';
import { Instagram, YouTube, MusicNote, } from '@material-ui/icons';
import { ReactComponent as LinkIcon } from "../../assets/link.svg"
import LazyLoad from 'react-lazyload';

import ReactHlsPlayer from 'react-hls-player';
import { urlCorrection } from '../../helper/stringHelper';

const useStyles = makeStyles((theme) => ({

    coach: {
        background: "linear-gradient( #d2daff, #d7cdea)",
        padding: "80px 50px",
        margin: 'auto',
        marginTop: '3%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "center",
        alignItems: 'center',
        [theme.breakpoints.down(500)]: {
            // width:'80%',
            // padding:"80px 20px",
        },
    },
    coachTitle: {
        textAlign: 'center',
        fontFamily: 'Lora',
        fontSize: "40px",
        fontStyle: "italic",
        [theme.breakpoints.down(700)]: {
            fontSize: "35px"
        },
    },
    practitionerContainer: {
        // border:'1px solid red',
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "flex-start",
        gap: '20px',
        [theme.breakpoints.down(800)]: {
            flexDirection: "column",
            alignItems: "center",
        },
        [theme.breakpoints.up(2500)]: {
            width: "50%"
        },

    },
    instructorIntroContainer: {
        width: '40%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: "flex-start",
        gap: "20px",
        paddingLeft: "50px",
        paddingRight: "50px",
        [theme.breakpoints.down(800)]: {
            textAlign: 'center',
            width: "100%",
            justifyContent: "center",
            alignItems: "center"
        },
    },
    instructorVideoContainer: {
        width: "40%",
        height: "100%",
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        [theme.breakpoints.down(800)]: {
            width: "100%"
        },
        [theme.breakpoints.down(600)]: {
            width: "100%",
            height: "auto",
            display: 'flex',
            flexDirection: "row",
            justifyContent: 'center'
        },

    },
    instructorVideo: {
        width: "90%",
        display: "flex",
        flexDirection: 'row',
        justifyContent: "center",
        alignItems: 'center',
    },
    meetPractitioner: {
        marginTop: "12px",
        marginBottom: "50px",
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: "center",
        gap: '15px',
        // fontFamily:"Poppins",
        fontStyle: "normal",
        fontWeight: 'bold',
        fontSize: '22px',
        [theme.breakpoints.down(600)]: {
            flexDirection: 'column',
        },

    },
    socialMedia: {
        display: 'flex',
        flexDirection: "row",
        justifyContent: 'center',
        alignItems: 'center',
        gap: "10px",
    },
    socialMediaLink: {
        display: 'flex',
        flexDirection: "row",
        alignItems: 'center',
        color: "#a2a2a5",
        marginRight: "18px"
    },
    instructorImg: {
        objectFit: "cover",
        height: "600px",
        width: '100%',
        borderRadius: '15%',
        marginBottom: "50px",
        [theme.breakpoints.down(1000)]: {
            width: '100%'
        },
        [theme.breakpoints.down(800)]: {
            width: "80%"
        },

        [theme.breakpoints.down(600)]: {
            width: "100%"
        },

        [theme.breakpoints.down(500)]: {
            marginRight: "0px"
        },
        [theme.breakpoints.down(450)]: {
            borderRadius: '25px'
        },
    },
    instructorInformation: {
        display: 'flex',
        flexDirection: "row",
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '20px',
        marginTop: '20px'

    },
    learnMoreButton: {
        borderRadius: "30px"
    },
    instructorIntro: {
        width: "100%",
        textAlign: "left",
        whiteSpace: "pre-wrap",
        fontSize: "18px",
        fontFamily: "Poppins",
        lineHeight: "1.3",
        [theme.breakpoints.down(600)]: {
            width: "90%",
            textAlign: 'center'
        },
    },
}))

const Instructor = ({ state, socialMedia, practitioner, username, usernameError }) => {

    const classes = useStyles();

    return (
        <>
            <div className={classes.coach} id='instructor'>
                <div className={classes.coachTitle}>
                    Meet Your Coach
                </div>

                <div className={classes.meetPractitioner}>
                    <div>{practitioner}</div>
                    {
                        Object.keys(socialMedia).length > 0
                        &&
                        <div className={classes.socialMedia}>
                            {

                                socialMedia.instagram
                                &&
                                <MuiLink
                                    className={classes.socialMediaLink}
                                    href={urlCorrection(socialMedia.instagram)}
                                    target="_blank"
                                >
                                    <Instagram />
                                </MuiLink>
                            }

                            {
                                socialMedia.youtube
                                &&
                                <MuiLink
                                    className={classes.socialMediaLink}
                                    href={urlCorrection(socialMedia.youtube)}
                                    target="_blank"
                                >
                                    <YouTube />
                                </MuiLink>
                            }

                            {
                                socialMedia.website
                                &&
                                <MuiLink
                                    className={classes.socialMediaLink}
                                    href={urlCorrection(socialMedia.website)}
                                    target="_blank"
                                >
                                    <LinkIcon />
                                </MuiLink>
                            }

                            {

                                socialMedia.tikTok
                                &&
                                <MuiLink
                                    className={classes.socialMediaLink}
                                    href={urlCorrection(socialMedia.tikTok)}
                                    target="_blank"
                                >
                                    <MusicNote />
                                </MuiLink>
                            }


                        </div>
                    }
                </div>

                <div className={classes.practitionerContainer}>
                    <div className={classes.instructorIntroContainer}>
                        <div className={classes.instructorIntro}>
                            {state.courseOverview.instructorIntro}
                        </div>
                        <div>
                            <div className={classes.instructorInformation}>
                                <div>
                                </div>
                                {
                                    !usernameError
                                    &&
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        className={classes.learnMoreButton}
                                        onClick={() => {
                                            window.open(`https://www.floka.ca/${username}`)
                                        }}
                                    >
                                        Learn More
                                    </Button>
                                }
                            </div>
                        </div>
                    </div>

                    {/*If user choose a video to be uploaded  */}

                    {
                        state.courseOverview.instructorVideo ?

                            state.courseOverview.instructorVideo.startsWith('https://') ?
                                <div className={classes.instructorVideoContainer}>
                                    <LazyLoad>
                                        <img
                                            controls
                                            className={classes.instructorImg}
                                            src={
                                                state.courseOverview?.instructorVideo ||
                                                state.courseOverview?.instructorImage[0].patch
                                                ||
                                                state.courseOverview?.instructorImage
                                            }
                                        />
                                    </LazyLoad>
                                </div>
                                :
                                <div className={classes.instructorVideoContainer}>
                                    <div
                                        className={classes.instructorVideo}
                                    >
                                        <LazyLoad>
                                            <ReactHlsPlayer
                                                src={`https://customer-43tpssqc1qvan2fr.cloudflarestream.com/${state.courseOverview.instructorVideo}/manifest/video.m3u8`}
                                                autoPlay={false}
                                                controls={true}
                                                width="100%"
                                                height="100%"
                                                style={{ objectFit: 'cover', borderRadius: "7px" }}
                                            />
                                        </LazyLoad>
                                    </div>
                                </div>

                            :
                            <div className={classes.instructorVideoContainer}>
                                <img
                                    controls
                                    className={classes.instructorImg}
                                    src={
                                        state.courseOverview?.instructorImage ?
                                            (state.courseOverview?.instructorImage[0]?.patch
                                                ||
                                                state.courseOverview.instructorImage)
                                            : ''
                                    }
                                />
                            </div>
                    }

                </div>
            </div>
        </>
    )
}


export default Instructor