import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Grid, Box, IconButton, makeStyles, Button, Typography, Dialog, DialogActions, DialogContentText, DialogContent, DialogTitle, Slide } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    modalBox: {
        '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '1000px',
            borderRadius: '10px'
        }
    },
    box:{
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
        justifyContent:"flex-end",
        [theme.breakpoints.down(500)]: {
            justifyContent:'center'
        },

    },
    modalTitle: {
        gap:"10px",
        color:"#000",
        textAlign: 'center',
        margin: '10px 0px 15px 0px',
        display:'flex',
        flexDirection:"row",
        justifyContent:'center',
        alignItems:"center",
        [theme.breakpoints.down('600')]: {
            flexDirection:"column"
        },
    },
    darkDescription: {
        textAlign: 'center',
        fontSize: '13px',
        marginBottom: '4px',
        fontWeight:"normal"
    },
    soundGoodButton: {
        width: '300px',
        backgroundColor:"#000",
        color:"#fff",
        borderRadius: '5px',
        textAlign: 'center',
        padding: '4px',
        [theme.breakpoints.down(550)]: {
            width:'250px' 
        },
        [theme.breakpoints.down(400)]: {
            width:'200px' 
        },
    },
    
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '5%',
        gap: '83px',
        [theme.breakpoints.down('768')]: {
            gap: '0px'
        },
        [theme.breakpoints.down('550')]: {
            gap: '20px',
            flexDirection: 'column',
        },

    },
    img:{
        width:"30px"
    },
    dialogContent:{
        marginBottom:"65px"
    }


}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const SuccessModal =({ onClose, open, courseid })=>{

    const classes = useStyles()
    const navigate = useNavigate();

    return (
        <>
           <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                // onClose={() => setShowPrompt(false)}
                aria-describedby="alert-dialog-slide-description"
                className={classes.modalBox}
           >

            <DialogTitle>
                {/* <Box className={classes.box}>
                    <Box>
                        <IconButton onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box> */}

            </DialogTitle>
            <Typography variant="h5" paragraph className={classes.modalTitle}>
                <span>Congrats! You are enrolled</span>
            </Typography>
            <DialogContent className={classes.dialogContent}>
                <Typography variant="h6" paragraph className={classes.darkDescription}>
                We have sent you an email to authenticate your account. 
                Please have a look to proceed to further in this course!
                
                </Typography>
                <Typography variant="h6" paragraph className={classes.darkDescription}>
                If it does not show up in your inbox, please check your spam.  
                </Typography>

            </DialogContent>
            <DialogActions className={classes.buttonsContainer}>
                <Button 
                    variant='contained' 
                    onClick={()=>window.open(`https://www.floka.co/iphone-vs-web`, '_blank')} 
                    className={classes.soundGoodButton}>
                         Awesome, See My Course!
                </Button>
            </DialogActions>
        </Dialog>
        </>
    )
    


}


export default SuccessModal