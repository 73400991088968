import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles';

import { Grid, Typography, Button, CircularProgress, Breadcrumbs } from '@material-ui/core';
import { NavigateNext } from '@material-ui/icons';
import { useParams, Link } from 'react-router-dom';
import {
    setPersistence,
    purchaseCourse,
    signin,
    signout,
    getCurrentUser,
    updateCounterClient
} from '../../lib/Auth/index'

import {
    addAbandonedCartEmail
} from '../../lib/Patient/index'
import { getPatientData, hasPractitioner, AddSubscribeNewsLetter } from '../../lib/Patient/index'
import { checkCoupon } from '../../lib/coupon/index'
import { baseUrl } from '../../lib/config'
import { database } from '../../lib/Auth/firebase_config';
import { Mixpanel } from '../../lib/Analytics/Mixpanel'



// components:
import BreadcrumbContainer from './breadcrumbs';
import SignUpSection from './signUp';
import SignInSection from './signIn';
import Authenticated from './auth';
import PaymentMethod from './payementMethod';
import Afterpay from './afterpay';
import StripePayment from '../../elements/StripePayment'
import CourseDescription from './courseDescription';

import Notification from '../../elements/Notification'
import ErrorSnackbar from '../../elements/ErrorSnackbar/ErrorSnackbar'
import SuccessModal from '../../elements/SuccessModal';
import SuccessModalOneToOne from '../../elements/SuccessModal/oneToOne';
import SuccessModalAfterConnect from '../../elements/SuccessModal/oneToOneConnect';


import LoadingSpinner from '../../elements/LoadingSpinner'
import ErrorNoLanding from '../../elements/ErrorNoLanding';
import ErrorSignUp from '../../elements/ErrorAuth';
import SuccessAuth from '../../elements/SuccessAuth';

import ReactGA from 'react-ga4'
import GAEventsTracker from '../../lib/GoogleAnalytic';
import { Helmet } from 'react-helmet-async';
import { loadStripe } from '@stripe/stripe-js';
import { set } from 'react-ga';


const promise = loadStripe(`${process.env.REACT_APP_NODE_ENV === 'production'
    ? "pk_live_51HCrBpIazuHtyn2CqcZkh3Ll1PcAIP9VsJ9lKG55oqTD4qOIz09K7T9SOrGmYECbIOtpSw4MESUzp5mBhiTGoCVf00kxysYD6T"
    : "pk_test_51HCrBpIazuHtyn2CE7C3GDruQL1WjX0dUXVV0XlT0c1rOmHHaZOa6x3JzDlfsvIRVsr1qnAsDpNZRAaBv4ZKfd0h001FQaRjX9"}`);


const useStyles = makeStyles((theme) => ({

    backgroundColor: {
        margin: 0,
        padding: 0,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        minHeight: '100vh',
        height: 'auto',
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        background: 'rgb(249,246,254)',
        background: 'linear-gradient(180deg, rgba(249,246,254,1) 0%, rgba(236,228,252,1) 33%, rgba(251,248,255,1) 100%)'
    },
    paymentOuterContainer: {

        display: "flex",
        flexDirection: "row",
        alignItems: 'center',
        justifyContent: "center",

        width: "100%",
        [theme.breakpoints.down(800)]: {
            width: "85%"
        },
        [theme.breakpoints.down(500)]: {
            width: "100%"
        },

    },
    paymentFormTitle: {

        fontFamily: "Playfair Display",
        fontWeight: "bold",
        fontSize: '28px',
        color: 'black'
    },
    paymentTitleContainer: {
        lineHeight: '5px',
        textAlign: 'left',
        [theme.breakpoints.down(600)]: {
            textAlign: 'center',
            marginBottom: '25px'
        },
    },
    paymentFormContainer: {
        width: '600px',
        height: 'fit-content',
        marginTop: '30px',
        marginBottom: '30px',
        textAlign: 'center',
        backgroundColor: '#fff',
        padding: '25px 30px',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '30px',
        [theme.breakpoints.down(600)]: {
            width: '100%',
        },
    },
    paymentFormContainerForStripe: {
        width: '100%',
        textAlign: 'center',
        maxWidth: '600px',

        backgroundColor: 'white',
        padding: '25px 30px',
        display: 'flex',
        flexDirection: 'column',
        height: 'fit-content',
        borderRadius: '30px',

        [theme.breakpoints.down(600)]: {
            width: '100%',
            padding: '25px 10px',
        },
    },
    signUp: {
        color: "#090a0a",
        cursor: 'pointer',
        textDecoration: 'underline',
        fontWeight: 'bold'

    },
    wrongAccount: {
        fontSize: '10px'
    },
    getCourseButton: {
        width: '70%',
        cursor: 'pointer',
        margin: 'auto',
        marginTop: '20px',
        "&:disabled": {
            backgroundColor: "#eeeeee",
        }
    },
    getCourseContainer: {
        width: '100%',

    },
    getCourseButtonContainer: {
        display: "flex",
        flexDirection: 'column',
        justifyContent: "center",
        alignItems: 'center',
        gap: '15px',
        marginTop: "25px"

    },
    outerContainer: {
        width: "100%",
        margin: 0,
        padding: 0,
        display: 'flex',
        flexDirection: "row",
        justifyContent: 'space-around',
        [theme.breakpoints.down(800)]: {
            flexDirection: "column",
            alignItems: 'center'
        },
    },

    breadcrumbContainer: {
        fontFamily: "Playfair Display",
        padding: '5px',
        marginBottom: "25px",
        display: "flex",
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down(900)]: {
            flexDirection: 'row',
            alignItems: "center",
            gap: "15px",
            marginBottom: "15px",
        },
        [theme.breakpoints.down(800)]: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
        },

        [theme.breakpoints.down(600)]: {
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: "center",
        },
    },
    breadcrumbUnselected: {
        fontFamily: "Playfair Display",
        textDecoration: 'none',
        color: '#a2a2a5',
        fontSize: "14px",
        cursor: 'auto'
    },
    breadcrumbSeelected: {
        fontFamily: "Playfair Display",
        textDecoration: 'none',
        color: 'black',
        fontSize: "14px",
        cursor: 'pointer'
    },
    CircularProgress: {
        marginTop: '20px',
        width: '100%'
    },
    errorBanner: {
        marginTop: '15px',
        width: '100%',
        backgroundColor: 'red',
        color: "#fff",
        borderRadius: '5px',
        padding: '10px',
    }

}))

const PaymentForm = ({ }) => {

    const [error, setError] = useState('')
    const [errorNotificaction, setNotificationError] = useState('')
    const [notification, setNotification] = useState('')
    const [successModal, setSuccessModal] = useState(false)
    const [oneToOneSuccessModal, setOneToOneSuccessModal] = useState(false);
    const [oneToOneAfterConnect, setOneToOneAfterConnect] = useState(false);

    const [isLoading, setIsLoading] = useState(true)
    const [isCheckoutLoading, setIsCheckoutLoading] = useState(false)
    const [checkoutError, setCheckoutError] = useState('')
    const [isCourseOneToOne, setIsCourseOneToOne] = useState(false)
    const [getCourseButtonLoading, setGetCourseButtonLoading] = useState(false)
    const [getCourseSuccess, setGetCourseSuccess] = useState(false);
    const [state, setState] = useState(undefined)
    const [paymentOptions, setPaymentOptions] = useState(undefined)
    const [selectedOption, setSelectedOption] = useState(undefined)
    const [courseOwned, setCourseOwned] = useState(false)


    // data for success email:
    const [emailData, setEmailData] = useState({})
    const [practitionerEmail, setPractitionerEmail] = useState('')
    const [mailchimp, setMailchimp] = useState('')

    const [patientName, setPatientName] = useState('')
    const [patientEmail, setPatientEmail] = useState('')

    const [patientHasPractitioner, setPatientHasPractitioner] = useState(false)
    const [currentPractitionerName, setCurrentPractitionerName] = useState('')
    const [currentPractitionerId, setCurrentPractitionerId] = useState('')
    const [patientId, setPatientId] = useState('')
    const [newPractitionerId, setNewPractitionerId] = useState('')

    const [signUpData, setSignUpData] = useState({
        FirstName: "",
        Email: "",
        ConfirmEmail: "",
        Password: "",
        ConfirmPassword: "",
        Birthday: ""
    })

    const [signInData, setSignInData] = useState({
        Email: "",
        Password: ""
    })


    const [auth, setAuth] = useState('SignUp')
    const [authData, setAuthData] = useState(undefined);
    const [isNewsletterSubscribed, setIsNewsletterSubscribed] = useState(false)
    const [addressInfoComplete, setAddressInfoComplete] = useState(true)
    const [abandonedCartEmail, setAbandonedCartEmail] = useState("")


    let { username, courseid } = useParams()

    const classes = useStyles();

    const fetchPractitionerEmail = async (id) => {
        await axios.get(`${baseUrl}/flokaLanding/${id}/email`)
            .then(res => {
                console.log('res', res)
                setPractitionerEmail(res.data.email)

            })
    }

    const getPractitionerName = async (id) => {
        console.log('getPractitionerName', id)
        await axios.get(`${baseUrl}/flokaLanding/practitioner/${id}`)
            .then(res => {
                console.log('res', res)
                setCurrentPractitionerName(res.data.practitioner)
            })
    }

    const isCourseOwned = async (patientId) => {
        let res
        res = await axios.get(`${baseUrl}/flokaLanding/${patientId}/${courseid}/check`)
        console.log('res', res)
        if (res.data) {
            setCourseOwned(true)
        } else {
            setCourseOwned(false)
        }

    }

    // send email to patient after purchase is successful
    const handleSendEmailToPatient = (params) => {
        // let params = {
        //     ...emailData,
        //     patientEmail: patientEmail,
        //     isCourseOneToOne
        // }
        axios.post(`${baseUrl}/flokaLanding/paymentEmail/send`, params)
            .then(res => console.log(res))
            .catch(err => {
                console.log('something went wrong', err)
            })
    }

    // send email to practitioner after purchase is successful
    const handleSendEmailToPractitioner = (params) => {

        // let params = {
        //     practitionerEmail,
        //     courseName: emailData.courseName
        // };
        axios.post(`${baseUrl}/flokaLanding/practitioner/paymentEmail/send`, params)
            .then(res => console.log(res))
            .catch(err => {
                console.log('something went wrong', err)
            })


    }

    // Add Email/name of client to designated audience list in mailchimp if practitioner has one
    const handleAddUserEmailToAudienceList = (email, name, mailchimp) => {
        let params = {
            mailchimpObj: mailchimp,
            email,
            name
        };
        axios.post(`${baseUrl}/flokaLanding/mailchimpAddEmail`, params)
            .then(res => console.log('mailchimp add successful', res))
            .catch(err => {
                console.log('something went wrong', err)
            })

    }



    const paymentAfterProcess = async (patientidCHECKOUT, courseidCHECKOUT, sessionidCHECKOUT, practitioneridCHECKOUT, email, name) => {

        await axios.get(
            `${baseUrl}/flokaLanding/${username.toLowerCase()}/${courseid}/initial_payment_data`
        )
            .then(async (res) => {


                await purchaseCourse(
                    courseidCHECKOUT,
                    patientidCHECKOUT,
                    "paymentOptions[selectedOption]",
                    "state",
                    res.data.isOneToOne,
                    sessionidCHECKOUT,
                    practitioneridCHECKOUT,
                    currentPractitionerName
                )
                    .then(async response => {

                        console.log(response)

                        if (response) {
                            //Track course purchase in mixpanel analytics

                            const result = await axios.get(`${baseUrl}/flokaLanding/${res.data.practitioner_id}/email`)

                            let paramsForPatientEmail = {
                                courseName: res.data.courseOverview.title,
                                courseType: res.data.courseType,
                                practitionerName: res.data.practitioner,
                                courseThumbnail: res.data.courseOverview.courseThumbnail,
                                objectivesCourse: res.data.courseOverview.objectivesCourse,
                                oneToOne: res.data.courseOverview.oneToOne,
                                patientEmail: email,
                                patientName: name,
                                isCourseOneToOne: res.data.isOneToOne
                            }



                            let paramsForPractitionerEmail = {
                                practitionerEmail: result.data.email,
                                patientEmail: email,
                                practitionerName: res.data.practitioner,
                                courseName: res.data.courseOverview.title,
                                patientName: name,
                                purchasePrice: res.data.paymentSettings.price,
                            }

                            console.log("paramsForPatientEmail", paramsForPatientEmail)
                            console.log("paramsForPractitionerEmail", paramsForPractitionerEmail)

                            handleSendEmailToPatient(paramsForPatientEmail)
                            handleSendEmailToPractitioner(paramsForPractitionerEmail)

                            Mixpanel.track(`/purchaseCompleted/${courseidCHECKOUT}`)

                            setSuccessModal(true)
                        }
                    })
                    .catch(err => {
                        console.log('err in purchase course')
                        console.log(err)
                    })




                if (res.data.mailchimp) {
                    handleAddUserEmailToAudienceList(email, name, res.data.mailchimp)
                }

                Mixpanel.track(`/purchaseCompleted/${courseidCHECKOUT}`)


            })
            .catch(err => {

            })




        setIsLoading(false)

    }

    const initialRequest = async () => {

        await axios.get(
            `${baseUrl}/flokaLanding/${username.toLowerCase()}/${courseid}/initial_payment_data`
        )
            .then((res) => {
                console.log(res.data)
                setIsCourseOneToOne(res.data.isOneToOne);
                setMailchimp(res.data.mailchimp)
                setEmailData(prev => ({ ...prev, courseName: res.data.courseOverview.title }))
                setEmailData(prev => ({ ...prev, courseType: res.data.courseType }))
                setEmailData(prev => ({ ...prev, practitionerName: res.data.practitioner }))
                setEmailData(prev => ({ ...prev, courseThumbnail: res.data.courseOverview.courseThumbnail }))
                setEmailData(prev => ({ ...prev, objectivesCourse: res.data.courseOverview.objectivesCourse }))
                setEmailData(prev => ({ ...prev, oneToOne: res.data.courseOverview.oneToOne }))

                setNewPractitionerId(res.data.practitioner_id)
                fetchPractitionerEmail(res.data.practitioner_id)



                const tempOptions = []
                res.data.paymentSettings.billing.forEach((option) => {

                    if (option.frecuency === "Single") {
                        tempOptions.push(
                            {
                                optionSubscription: 'Single',
                                value: res.data.paymentSettings.price,
                                currency: res.data.paymentSettings.currency,
                                stripeId: option.stripeId
                            }
                        )
                    }

                    if (option.frecuency === "Monthly") {
                        tempOptions.push(
                            {
                                optionSubscription: 'Monthly',
                                value: res.data.paymentSettings.price,
                                currency: res.data.paymentSettings.currency,
                                stripeId: option.stripeId
                            }
                        )
                    }

                    if (option.frecuency === "Yearly") {
                        tempOptions.push(
                            {
                                optionSubscription: 'Yearly',
                                value: option.price !== res.data.paymentSettings.price ? option.price : res.data.paymentSettings.price,
                                currency: res.data.paymentSettings.currency,
                                stripeId: option.stripeId
                            }
                        )
                    }

                })

                if (tempOptions.length === 1) {
                    setSelectedOption(0)
                }

                console.log("tempOptions", tempOptions)


                setPaymentOptions(tempOptions)
                setState(res.data)
            })
            .catch((err) => {
                // Practitioner Name
                console.log(err.response.data.error)
                setError(err.response.data.error)

            })

        setIsLoading(false)






    }


    useEffect(() => {

        //Google analytics event tracking
        GAEventsTracker("Page View", `/${username}/course/${courseid}/payment`, `${username} ${courseid}`)


        let patientEmail = undefined
        let patientName = undefined
        const handleRefreshUser = async (data) => {

            if (data) {



                setPatientEmail(data.email)
                patientEmail = data.email
                await getPatientData(data.id)
                    .catch(async (err) => {
                        console.log('error')
                        console.log(err)
                    })
                    .then(async(response) => {
                        console.log('response', response)
                        setPatientName(response.user.firstName)
                        patientName = response.user.firstName

                        setNotification('Sign in Successfully!')

                        setAuth('auth')
                        setAuthData(response)
                        isCourseOwned(response.patientId)
                        setPatientId(response.patientId)
                        hasPractitioner(response.patientId)
                            .then(res => {
                                console.log('res', res)
                                if (!res.practitioner) {
                                    setPatientHasPractitioner(false)
                                } else {
                                    setPatientHasPractitioner(true)
                                    setCurrentPractitionerId(res.practitioner)
                                    getPractitionerName(res.practitioner)
                                }
                            })
                        setPersistence(signInData.Email, signInData.Password)

                        if (patientidCHECKOUT?.length && courseidCHECKOUT?.length && sessionidCHECKOUT?.length && practitioneridCHECKOUT?.length) {

                            await paymentAfterProcess(patientidCHECKOUT, courseidCHECKOUT, sessionidCHECKOUT, practitioneridCHECKOUT, patientEmail, patientName)

                        } else {

                            await initialRequest()

                        }


                    })

            } else {

                await initialRequest()

            }



        }
        
        
        const queryParams = new URLSearchParams(window.location.search);

        // Accessing query parameters
        const patientidCHECKOUT = queryParams.get('pid');
        const courseidCHECKOUT = queryParams.get('cid');
        const sessionidCHECKOUT = queryParams.get('sid');
        const practitioneridCHECKOUT = queryParams.get('prid');

        try{
            
            setIsLoading(true)
            getCurrentUser(handleRefreshUser)

        } catch(err){

        }







    }, [])


    useEffect(() => {

        if (state?.courseid && state?.practitioner_id) {

            Mixpanel.track(`/purchaseVisit/${courseid}`)

        }

    }, [state])


    const saveCourse = async () => {

        setGetCourseButtonLoading(true)

        if (auth === "auth" && selectedOption !== undefined && (paymentOptions[selectedOption].optionSubscription === "Single")) {
            console.log('conditionalPurchase')
            console.log(authData)
            await purchaseCourse(
                courseid,
                authData.patientId,
                paymentOptions[selectedOption],
                state,
                isCourseOneToOne,
                undefined,
                state.practitioner_id,
                currentPractitionerName


            )
                .then(res => {
                    console.log('purchased')
                    console.log('GET COURSE SUCCESS', res)
                    if (res) {

                        setGetCourseButtonLoading(false)
                        setGetCourseSuccess(true)
                        setSuccessModal(true)

                        //Track course purchase in google analytics
                        Mixpanel.track(`/purchaseCompleted/${courseid}`)

                    }
                })
                .catch(err => {
                    console.log('err in purchase course')
                    console.log(err)
                    //Here we can handle if the user already has the course
                    // You already own this course!
                })

        } else {
            setGetCourseButtonLoading(false)
            setGetCourseSuccess(true)
            setSuccessModal(true)
        }

        //Update counter and client list
        await updateCounterClient(courseid, authData.patientId, state.practitioner_id)

    }

    //Missing persistance
    const signInHandler = () => {

        if (signInData.Email && signInData.Password) {

            signin(signInData.Email, signInData.Password)
                .then((res) => {

                    getPatientData(res)
                        .then((response) => {
                            console.log('response', response)
                            setNotification('Sign in successfully!')
                            setAuth('auth')
                            setAuthData(response)
                            isCourseOwned(response.patientId);
                            setPatientId(response.patientId)
                            hasPractitioner(response.patientId)
                                .then(res => {
                                    console.log('res', res)
                                    if (!res.practitioner) {
                                        setPatientHasPractitioner(false)
                                    } else {
                                        setPatientHasPractitioner(true)
                                        setCurrentPractitionerId(res.practitioner)
                                        getPractitionerName(res.practitioner)
                                    }
                                })
                            setEmailData(prev => ({ ...prev, patientName: response.user.firstName }))
                            setEmailData(prev => ({ ...prev, patientEmail: signInData.Email }))
                            setPersistence(signInData.Email, signInData.Password)

                            console.log('auth passed!', response)


                        })

                })
                .catch((error) => {
                    console.log(error)
                    setError('Sign in error: check your email and password')
                })
        } else {
            console.log('missing data')
        }


    }

    const signOutHandler = () => {

        try {

            signout()
            setNotification('Sign out! Come back soon..')
            setAuth('SignIn')
            setAuthData(undefined)

        } catch (err) {
            setError('error')

        }

    }

    const signUpHandler = async () => {

        const { FirstName, Email, ConfirmEmail, Password, ConfirmPassword, Birthday } = signUpData
        console.log(signUpData)

        if (FirstName.length &&
            Email.length &&
            ConfirmEmail.length &&
            Password.length &&
            ConfirmPassword.length &&
            Birthday.length) {

            let params = {
                email: Email,
                password: Password,
                data: { name: FirstName, birth: Birthday },
                isNewsletterSubscribed: isNewsletterSubscribed
            }

            await axios.post(
                `${baseUrl}/flokaLanding/newPaymentAccount`,
                params
            )
                .then((res) => {
                    console.log('res data', res)
                    setNotification('Account Successfully Created! ')
                    setAuth('auth')
                    isCourseOwned(res.data.info.id)
                    const temp = {
                        user: {
                            firstName: res.data.info.name
                        },
                        patientId: res.data.info.id
                    }
                    setAuthData({ ...temp })


                    setPersistence(Email, Password)

                })
                .catch((err) => {
                    console.log(err)
                    let errorMessage =
                        err?.response?.data?.error ||
                        'Account creation failed'

                    setError(errorMessage)

                })

        } else {
            console.log('missing data')
            setError('All fields are required')
            return;
        }

    }

    const inputHandlerFunction = (value, type, section) => {

        if (section === "SignIn") {

            setSignInData((prev) => {
                return { ...prev, [type]: value }
            })

        }

        if (section === "SignUp") {
            setSignUpData((prev) => {
                return { ...prev, [type]: value }
            })
        }


        // if (type === "Email" && value !== "") {
        //     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

        //     if (emailRegex.test(value)) {
        //         setAbandonedCartEmail(value)
        //     } else {
        //         setAbandonedCartEmail("")
        //     }


        // }


    }

    useEffect(() => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

        if (abandonedCartEmail) {

            if (emailRegex.test(abandonedCartEmail)) {

                setTimeout(() => {
                    console.log("Checking " + abandonedCartEmail)

                    addAbandonedCartEmail(courseid, abandonedCartEmail)

                }, 50000)

            }
        }

    }, [abandonedCartEmail])

    const saveAbandonedCartEmail = (value) => {

        //Send Email as abandoned Cart to the backend
        // console.log('email send to backend', abandonedCartEmail)
        // addAbandonedCartEmail(courseid, abandonedCartEmail)

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

        if (emailRegex.test(value)) {
            setAbandonedCartEmail(value)
        } else {
            setAbandonedCartEmail("")
        }


    }

    const closeError = () => setError('')

    const closeErrorNotification = () => setNotificationError('')

    const closeNotification = () => setNotification('')

    const isEmailSame = () => {
        return signUpData.Email === signUpData.ConfirmEmail
    }
    const isPasswordSame = () => {
        return signUpData.Password === signUpData.ConfirmPassword
    }


    const breadcrumbs = [
        <Link to={``} key="1" className={(auth === 'SignIn' || auth === 'SignUp') ? classes.breadcrumbSeelected : classes.breadcrumbUnselected}>
            Information
        </Link>,
        <Link to={``} key="2" className={auth === 'auth' ? classes.breadcrumbSeelected : classes.breadcrumbUnselected}>
            Payments
        </Link>
    ];

    const goToCheckout = async () => {

        console.log(paymentOptions)
        setIsCheckoutLoading(true)

        let params = {
            productId: paymentOptions[0].stripeId,
            courseId: courseid,
            patientId: authData.patientId,
            practitionerId: state.practitioner_id,
            username: username,
            patientEmail: patientEmail,
            isHybrid: isCourseOneToOne
        }

        await axios.post(
            `${baseUrl}/flokaLanding/checkoutPage`,
            params
        )
            .then(async (res) => {
                console.log('res data', res)
                const stripe = await promise;
                setIsCheckoutLoading(false)

                const { error } = await stripe.redirectToCheckout({
                    sessionId: res.data.sessionId,
                });

                if (error) {
                    console.error('Error:', error);
                    setCheckoutError(error)
                }


            })
            .catch((err) => {
                setIsCheckoutLoading(false)
                console.log('err data', err)
                setCheckoutError('Cannot Redirect To Checkout Link, please try again')

            })


    }



    return (
        <>
            {!isLoading && state && <div className={classes.backgroundColor}>

                <Grid className={classes.outerContainer} >
                    <Helmet>
                        <title>Payment for {state.courseOverview.title}</title>
                        <meta
                            name='description'
                            content={
                                `Purchase This Course For ${state.paymentSettings.price} ${state.paymentSettings.currency}, created by ${state.practitioner}`}
                        />
                        <meta
                            name='keywords'
                            content={`${state.practitioner}, ${state.courseOverview.title}, Payment Page`}
                        />
                        <link rel='canonical' href={`/${username}/course/${courseid}/payment`} />
                    </Helmet>


                    <Grid
                        container item direction={'column'}
                        className={classes.paymentOuterContainer}
                    >
                        <div
                            className={
                                auth === 'auth'
                                    && courseOwned === false ?
                                    classes.paymentFormContainerForStripe :
                                    classes.paymentFormContainer}
                        >

                            <div className={classes.paymentTitleContainer}>

                                <Typography className={classes.paymentFormTitle}>
                                    Get Started
                                </Typography>
                            </div>

                            <div className={classes.breadcrumbContainer}>
                                <Breadcrumbs
                                    separator={<NavigateNext fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    {breadcrumbs}
                                </Breadcrumbs>

                                {auth === "SignUp" && <span className={classes.authTitle} onClick={() => {
                                    setAuth("SignIn")
                                    setSignUpData({
                                        firstName: "",
                                        email: "",
                                        confirmEmail: "",
                                        password: "",
                                        confirmPassword: "",
                                        birthday: ""
                                    })
                                }}>
                                    Already have Flöka account? <span className={classes.signUp}>Sign In</span>
                                </span>}

                                {auth === "SignIn" && <span className={classes.authTitle} onClick={() => {
                                    setAuth("SignUp")
                                    setSignInData({
                                        email: "",
                                        password: ""
                                    })
                                }}>
                                    No Flöka account? <span className={classes.signUp}>Sign Up</span>
                                </span>}

                                {auth === "auth"
                                    &&
                                    <span
                                        className={classes.authTitle}
                                        onClick={() => {
                                            signOutHandler()
                                        }}
                                    >
                                        <span className={classes.signUp}>Sign Out</span>
                                    </span>}

                            </div>


                            {/* Sign up step */}
                            {auth === "SignUp"
                                &&
                                <SignUpSection
                                    isNewsletterSubscribed={isNewsletterSubscribed}
                                    setIsNewsletterSubscribed={setIsNewsletterSubscribed}
                                    saveAbandonedCartEmail={saveAbandonedCartEmail}
                                    inputHandlerFunction={inputHandlerFunction}
                                    isEmailSame={isEmailSame}
                                    isPasswordSame={isPasswordSame}
                                    signUpData={signUpData}
                                    auth={auth}
                                    signUpHandler={signUpHandler}
                                />
                            }

                            {/* Sign In Step */}
                            {
                                auth === "SignIn"
                                &&
                                <SignInSection
                                    inputHandlerFunction={inputHandlerFunction}
                                    signInHandler={signInHandler}
                                    signInData={signInData}
                                    auth={auth}
                                />
                            }

                            {/* Already auth */}
                            {auth === "auth"
                                &&
                                authData
                                &&
                                <Authenticated
                                    courseOwned={courseOwned}
                                    auth={auth}
                                    authData={authData}
                                    addressInfoComplete={addressInfoComplete}
                                    patientEmail={patientEmail}
                                    signOutHandler={signOutHandler}
                                />
                            }

                            {selectedOption !== undefined && paymentOptions !== undefined && paymentOptions[selectedOption].optionSubscription === "Single" &&

                                <div className={classes.getCourseContainer}>

                                    {
                                        selectedOption !== undefined
                                        && paymentOptions !== undefined
                                        &&
                                        Number(paymentOptions[selectedOption].value) === 0
                                        &&
                                        auth === 'auth'
                                        &&
                                        !courseOwned &&

                                        <div className={classes.getCourseButtonContainer}>
                                            <Button
                                                disabled={getCourseButtonLoading || getCourseSuccess}
                                                variant="contained"
                                                color="primary"
                                                className={classes.getCourseButton}
                                                onClick={() => saveCourse()}
                                                aria-label="assign-program"
                                            >
                                                {getCourseSuccess ?
                                                    'You Already Got this Course'
                                                    :
                                                    getCourseButtonLoading ?
                                                        "Getting Course ..." :
                                                        "Lets Get This Course"
                                                }
                                            </Button>
                                            {getCourseButtonLoading && <CircularProgress />}
                                        </div>
                                    }


                                </div>
                            }



                            {/* Payment method selection + promo code */}
                            {
                                paymentOptions.length > 0
                                &&
                                selectedOption !== undefined
                                &&
                                paymentOptions !== undefined
                                &&
                                Number(paymentOptions[selectedOption].value) !== 0
                                &&
                                auth === 'auth'
                                &&
                                courseOwned === false
                                &&
                                <Button
                                    disabled={(!isCheckoutLoading || auth === 'auth') ? false : true}
                                    variant="contained"
                                    color="primary"
                                    className={classes.getCourseButton}
                                    onClick={() => goToCheckout()}
                                    aria-label="assign-program"
                                >
                                    Go to checkout
                                </Button>
                            }
                            {
                                isCheckoutLoading
                                &&
                                <div className={classes.CircularProgress}>
                                    <CircularProgress />
                                </div>
                            }
                            {
                                checkoutError
                                &&
                                <div className={classes.errorBanner}>
                                    {checkoutError}
                                </div>
                            }

                        </div>
                    </Grid>



                </Grid>

            </div>}

            {/* Modal for regular course success purchase */}
            {isLoading && !error && <LoadingSpinner />}
            <SuccessModal
                courseid={courseid}
                open={successModal}
            // onClose={() => setSuccessModal(false)}
            />

            {/* Modal for 1:1 course success purchase */}
            <SuccessModalOneToOne
                patientHasPractitioner={patientHasPractitioner}
                currentPractitionerName={currentPractitionerName}
                newPractitionerName={emailData.practitionerName}
                practitionerId={currentPractitionerId}
                newPractitionerId={newPractitionerId}
                patientId={patientId}
                courseId={courseid}
                userName={patientName}
                open={oneToOneSuccessModal}
                onClose={() => setOneToOneSuccessModal(false)}
                setOneToOneAfterConnect={setOneToOneAfterConnect}
            />

            {/* Modal for 1:1 course after connect with practitioner */}
            <SuccessModalAfterConnect
                open={oneToOneAfterConnect}
                newPractitionerName={emailData.practitionerName}
                onClose={() => setOneToOneAfterConnect(false)}
            />
            <ErrorSnackbar error={errorNotificaction} onClose={closeErrorNotification} />
            <Notification text={notification} onClose={closeNotification} />
            {error && <ErrorSignUp text={error} onClose={closeError} />}
            {notification && <SuccessAuth text={notification} onClose={closeError} />}

        </>

    )
}

export default PaymentForm;