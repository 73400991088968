import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from  'clsx';

import paymentCard from '../../assets/payment-card.svg'
import paymentAfterpay from '../../assets/payment-afterpay.png'

const useStyles = makeStyles((theme) => ({

    paymentMethodContainer:{
        display:'flex',
        flexDirection:"column",
        justifyContent:'center',
        alignItems:'center',
        marginTop:'25px'

    },
    sectionTitleContainer:{
        width:'80%',
        display:'flex',
        flexDirection:'row',
        justifyContent:'start',
     },
     sectionTitleContainer2:{
        width:'80%',
        display:'flex',
        flexDirection:'column',
        justifyContent:'start',
        marginTop:'25px'
     },
     promoCodeContainer:{
       display:'flex',
       flexDirection:'row',
       justifyContent:'start',
       alignItems:'center',
       gap:'10px'
     },
     promoCodeButton:{
        height:'40px',
        cursor:"pointer",
        backgroundColor:"#c8c8c8",
        color:"#fff",
        padding:'5px 15px 5px 15px',
        borderRadius:'5px',
        fontWeight:'bold',
        border:"none"
     },


     sectionTitle: {
         fontSize: '16px',
         fontWeight:"bold",
         color: 'black',
         textAlign: 'left',
         marginBottom: '15px',
     },
     paymentOptions:{
        width:'80%',
        display:'flex',
        flexDirection:'row',
        justifyContent:'start',
        gap:"20px",
        [theme.breakpoints.down(400)]: {
            flexDirection:'column',
        },
     },
     paymentOption:{
        textAlign:'left',
        width:"180px",
        height:'60px',
        borderRadius:'10px',
        border:'1px solid #6e6f71',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        gap:'5px',
        cursor:"pointer"
     },
     selected:{
        boxShadow:"1px 1px 5px 1px #8c63da"
     },
     img:{
        width:'20px',
        marginLeft:"10px"
     },
     cardText:{
        marginLeft:"10px",
        fontSize:"12px"
     },
     courseNameInput: {
        width: '55%',
        height:"20px",
        padding: '10px',
        fontSize: '13px',
        borderRadius: '5px',
        border: '1px #c8c8c8 solid',

        '&:focus': {
            outline: 'none',
            border: "1px #c8c8c8 solid",
        }
    },
    inputContainer:{
        border:'1px solid red', 
        width:'90%'
    }
}))

const PaymentMethod = ({
    promoCode,
    setPromoCode,
    checkPromoCode,
    selectedPaymentMethod,
    setSelectedPaymentMethod
}) =>{

    const classes = useStyles();


    return(
        <>
           <div className={classes.paymentMethodContainer}>
                <div className={classes.sectionTitleContainer}>
                    <div className={classes.sectionTitle}>
                        Payment Method:
                    </div>
                </div>

                <div className={classes.paymentOptions}>
                    <div 
                        className={
                        selectedPaymentMethod ==='card'?
                        clsx(classes.paymentOption, classes.selected): classes.paymentOption}
                        onClick={()=>setSelectedPaymentMethod('card')}
                    >
                        <img  src={paymentCard} className={classes.img}/>
                        <div className={classes.cardText}>Card</div>
                    </div>

                    {/* <div 
                        className={selectedPaymentMethod ==='afterpay'?
                        clsx(classes.paymentOption, classes.selected): classes.paymentOption}
                        onClick={()=>setSelectedPaymentMethod('afterpay')}
                    >
                        <img  src={paymentAfterpay} className={classes.img}/>
                        <div className={classes.cardText}>Afterpay</div>
                    </div> */}

                </div>
                <div className={classes.sectionTitleContainer2}>
                    <div className={classes.sectionTitle}>
                        Promo Code:
                    </div>
                    <div className={classes.promoCodeContainer}>
                        <input  
                            type={'text'} 
                            className={classes.courseNameInput} 
                            placeholder={'promo code'}
                            value={promoCode}
                            onChange={(e) =>{
                                setPromoCode(e.target.value)
                            }} 
                        />
                        <button 
                            onClick={checkPromoCode}
                            className={classes.promoCodeButton}
                        >
                            Apply
                        </button>
                    </div>
                </div>

            </div>

        </>
    )
}

export default PaymentMethod