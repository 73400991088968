import { onAuthStateChange } from './firebase_config'

/**
 * Persist the session in a tab, and clear it when the tab or window is closed
 *
 * Source: https://firebase.google.com/docs/auth/web/auth-state-persistence#web-v8
 * @return {Promise}
 */
const getCurrentUser = (callback) => {
    return new Promise((resolve, reject) => {

        try{
            onAuthStateChange(callback)
            resolve(true)
            
        }catch(err){
            reject(err)

        }
            
    })
}

export default getCurrentUser
