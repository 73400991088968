
import { ContactSupportOutlined } from '@material-ui/icons'
import { database } from '../Auth/firebase_config'

import { decrypt, getAge, decryptPatient } from './index'

/**
 * Fetch all patient-related data from Firebase in @param patientId.
 *
 * @param {String[]} patientId - Patient's Firebase ids
 * @return {Promise<any>}
 */
const getPatientData = (patientId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const patientRef = database.ref(`/users/${patientId}`)
            patientRef.on(
                'value',
                (snapshot) => {
                    const data = snapshot.val()
                    const basicInfo = data?.basicInfo
                    console.log(basicInfo)
                   


                    // If user has no data OR user has already logged into iOS
                    if (data === null || !data || !basicInfo){

                        reject('no data')
                    }


                    let user = {}

                    if (basicInfo) {
                        const firstName =
                            decrypt(basicInfo?.name) ||
                            decryptPatient(basicInfo?.name) ||
                            'None'
                        user.firstName =
                            firstName.charAt(0).toUpperCase() +
                            firstName.slice(1)
                        user.age = getAge(basicInfo?.birth) || 'Not available'
                    }

                    console.log({user, patientId})
                    resolve({user, patientId})


                }
            )
        } catch (err) {
            reject(`no data`)
        }
    })
}

export default getPatientData
