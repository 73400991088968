import axios from 'axios';
import { baseUrl } from '../config';

const ConnectPractitioner =(practitionerId, patientId, courseId) =>{

    return new Promise((resolve, reject) => {

        let params = {
            practitionerId, 
            patientId,
            courseId
        };
        axios.post(`${baseUrl}/flokaLanding/connectPractitioner`,params)
            .then((res)=>{
                resolve(res)
            })
            .catch((err)=>{
                console.log('error', err)
                reject(err)
            })
    })

}

export default ConnectPractitioner