import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import AccessEverywhere from '../../assets/accessEverywhere.png'

import PaymentButton from './PaymentButton';
import ConsultationButton from './consultationButton';

const useStyles = makeStyles((theme) => ({

    triangle:{
        color:"#fff"
    },

    purchaseButton: {
        display:'flex',
        flexDirection:"row",
        justifyContent:"center",
        alignItems:'center',
        textAlign: 'center',
        padding: '8px 12px',
        marginTop:'10px',
        borderRadius: '30px',
        backgroundColor: 'black',
        color: 'white',
        fontSize: '13px',
        userSelect: 'none',
        cursor: 'pointer'
    },
    buttonContainer:{
        display:"flex", 
        flexDirection:'row', 
        justifyContent:'center'
    },
    link:{
        textDecoration:"none",
        // width:"30%",
    },
    bottomSection:{
        background:"linear-gradient( #d2daff, #d7cdea)",
        // backgroundColor:"#f2f4ff",
        display:'flex',
        flexDirection:"column",
        alignItems:'center',
        gap:"20px",

    },
    bottomSectionTitle:{
        marginTop:"40px",
        textAlign:'center',
        fontFamily:'Lora',
        fontSize:"40px",
        fontStyle:"italic",
        [theme.breakpoints.down(700)]: {
            fontSize:"35px",
        },
    },
    bottomSectionText:{
        marginTop:"20px",
        fontFamily:"Poppins",
        textAlign:'center',
        lineHeight:"1.5",
        [theme.breakpoints.down(700)]: {
            paddingLeft:"20px",
            paddingRight:"20px"
        },
        [theme.breakpoints.down(500)]: {
            paddingLeft:"10px",
            paddingRight:"10px",
            marginTop:"25px",
        },
    },
    dot:{
        fontSize:'7px'
    },
    poweredBy:{
        // fontFamily:"apercu",
        marginTop:"50px",
        marginBottom:"30px"
        
    },
    imgContainer:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        [theme.breakpoints.up(2500)]: {
            width:"50%"
        },
    },
    img:{
        // border:'1px solid red',
        width:"70%",
        [theme.breakpoints.down(700)]: {
            width:"80%",
        },
        [theme.breakpoints.down(500)]: {
            width:"90%",
        },
    },
}))

const Accessible = ({ state, username, courseid }) =>{

    const classes = useStyles();

    return (
        <>
            <div className={classes.bottomSection}>
                <Typography variant='h4' className={classes.bottomSectionTitle}>
                    Accessible Everywhere
                </Typography>

                <div className={classes.bottomSectionText}>
                    <div>
                        The all-in-one experience built to help you 
                        go deeper and achieve results faster.
                        <br/>
                        We meet you with the tools you already have.
                        With 1000+ wellness apps to integrate with, Flöka
                        <br />
                        puts your whole wellness journey in one place.
                    </div>

                </div>

                <div className={classes.imgContainer}>
                    <img 
                        className={classes.img}
                        src={AccessEverywhere}
                        alt={'Accessible Everywhere'}
                    />
                </div>

                {/*  Link to payment page */}
                <div className={classes.buttonContainer}>
                   {
                        (state.courseOverview.courseCTA
                            &&
                        state.courseOverview.courseCTA.consultation)
                        ?
                        <ConsultationButton 
                            link={state.courseOverview.courseCTA.link}
                            freeTrial={state.paymentSettings.freeTrial}
                        />
                        :
                        <PaymentButton 
                            username={username} 
                            courseid={courseid}
                            price={state.paymentSettings.price}
                            freeTrial={state.paymentSettings.freeTrial}
                        />
                    }
                </div>

                <div className={classes.poweredBy} variant='h6'>
                    Powered by &nbsp;
                    <a href='https://www.floka.co/' target='_blank'>Flöka</a>
                </div>

            </div>
          
        </>
    )
}

export default Accessible