import axios from 'axios'
import { baseUrl } from '../config'

/**
 * Add file, images for intake form answers:
 *
 * @return {Promise<any>}
 */
const addIntakeFormFile = (patientId, file) => {
    return new Promise((resolve, reject) => {
        const reqBody = {
            headers: {
                Accept: 'application/json',
                'Content-Type': `multipart/form-data; boundary=${file._boundary}`,
            },
        }

        axios
            .put(
                `${baseUrl}/flokaLanding/${patientId}/surveys/files`, 
                file, 
                reqBody
            )
            .then((res) => resolve(res.data))
            .catch((err) => reject(JSON.stringify(err)))
        })
}

export default addIntakeFormFile