import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Breadcrumbs } from '@material-ui/core';
import { NavigateNext } from '@material-ui/icons';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({

    breadcrumbContainer:{
        // fontFamily: "Playfair Display",
        padding:'5px',
        marginBottom:"25px",
        display:"flex",
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'center',
        [theme.breakpoints.down(900)]: {
            flexDirection:'column',
            justifyContent:'center',
            alignItems:"flex-start",
            gap:"15px",
            marginBottom:"15px",
        },
        [theme.breakpoints.down(800)]: {
            flexDirection:'row',
            justifyContent:'space-between',
            alignItems:'center'
        },

        [theme.breakpoints.down(600)]: {
            flexDirection:'column',
            justifyContent:'center',
            alignItems:"center",
        },
    },
    authTitle: {
        fontSize: '11px'
    },
    signUp: {
        color:"#090a0a",
        cursor: 'pointer',
        textDecoration: 'underline',
        fontWeight:'bold'
    },
    breadcrumbUnselected:{
        // fontFamily: "Playfair Display",
        textDecoration: 'none', 
        color:'#a2a2a5', 
        fontSize:"14px",
        cursor:'auto'
    },
    breadcrumbSeelected:{
        // fontFamily: "Playfair Display",
        textDecoration: 'none', 
        color:'black', 
        fontSize:"14px" ,
        cursor:'pointer'
    },
}))

const BreadcrumbContainer = ({ 
    auth, 
    setAuth, 
    setSignUpData, 
    setSignInData, 
    signOutHandler 
}) =>{

    const classes = useStyles();

    return(
        <>
            <div className={classes.breadcrumbContainer}>
                <Breadcrumbs
                    separator={<NavigateNext fontSize="small" />}
                    aria-label="breadcrumb"
                >
                    [
                        <Link to={``} key="1" className={(auth==='SignIn'|| auth==='SignUp')?classes.breadcrumbSeelected: classes.breadcrumbUnselected}>
                            Information
                        </Link>,
                        <Link to={``} key="2" className={auth==='auth'?classes.breadcrumbSeelected: classes.breadcrumbUnselected}>
                            Payments
                        </Link>
                    ]
                </Breadcrumbs>

                {auth === "SignUp" && <span className={classes.authTitle} onClick={() => {
                    setAuth("SignIn")
                    setSignUpData({
                        firstName: "",
                        email: "",
                        confirmEmail: "",
                        password: "",
                        confirmPassword: "",
                        birthday: ""
                    })
                }}>
                    Already have Flöka account? <span className={classes.signUp}>Sign In</span>
                </span>}

                {auth === "SignIn" && <span className={classes.authTitle} onClick={() => {
                    setAuth("SignUp")
                    setSignInData({
                        email: "",
                        password: ""
                    })
                }}>
                    No Flöka account? <span className={classes.signUp}>Sign Up</span>
                </span>}

                {auth === "auth" 
                    && 
                <span 
                    className={classes.authTitle} 
                    onClick={() => {
                        signOutHandler()
                    }}
                >
                    <span className={classes.signUp}>Sign Out</span>
                </span>}

            </div>
        </>
    )
}

export default BreadcrumbContainer