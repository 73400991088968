import axios from 'axios';
import { baseUrl } from '../config';

const checkSurveyComplete = (patientId, params) => {

    return new Promise((resolve, reject) => {
        axios.post(`${baseUrl}/flokaLanding/${patientId}/intakeForms/check`, params)
            .then((res) => {
                resolve(res.data)
            })
            .catch((err) => {
                console.log('error', err)
                reject(err)
            })
    })

}

export default checkSurveyComplete