import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Stream } from "@cloudflare/stream-react";
import { baseUrl } from '../../lib/config'

import axios from 'axios'
import LoadingSpinner from '../../elements/LoadingSpinner'
import ErrorNoCourse from '../../elements/ErrorNoCourse';

import ReactHlsPlayer from 'react-hls-player';
import GAEventsTracker from '../../lib/GoogleAnalytic';
import { Helmet } from 'react-helmet-async';

import { Mixpanel } from '../../lib/Analytics/Mixpanel'



// different sections:
import Introduction from './introduction';
import Experience from './experience';
import Learning from './learning';
import Modules from './modules';
import Testimonial from './testimonial';
import Instructor from './instructor';
import FAQ from './faq';
import Accessible from './accessible';
import Consultation from './consultation';

const CoursePage = ({ }) => {

    const [isLoading, setIsLoading] = useState(true)
    const [state, setState] = useState(undefined)
    const [practitioner, setPractitioner] = useState('Practitioner')
    const [socialMedia, setSocialMedia] = useState({})
    const [error, setError] = useState(undefined)
    const [usernameError, setUserNameError] = useState(false)
    let { username, courseid } = useParams()
    console.log('state', state)
    const [FAQs, setFAQs] = useState([])



    const initialRequest = async () => {
        setIsLoading(true)

        await axios.get(
            `${baseUrl}/flokaLanding/${username.toLowerCase()}`
        )
            .then(res => {
                console.log('res', res)
                if (res.data?.data === 'Landing page cannot be found') {
                    setUserNameError(true)
                }
            })
            .catch(error => {
                console.log("error", error)
                if (error.response.data.error === 'Landing page cannot be found' ||
                    error.response.data.error === 'Username cannot be found'
                ) {
                    setUserNameError(true)
                    setError(error.response.data.error)
                }
            })



        await axios.get(
            `${baseUrl}/flokaLanding/${username.toLowerCase()}/${courseid}`
        )
            .then((res) => {
                console.log('res', res)
                console.log(res.data)
                setPractitioner(res.data.practitioner)
                setState(res.data.course)
                setSocialMedia(res.data.socialMedia)
                let faqs = res.data.course.courseOverview.FAQs ?
                    res.data.course.courseOverview.FAQs?.filter(faq => faq.question && faq.answer)
                    : []
                setFAQs(faqs)

            })
            .catch((err) => {
                console.log(err.response)
                setError(err.response)

            })


        setIsLoading(false)
    }

    useEffect(() => {

        initialRequest()

        //Google analytics event tracking:
        GAEventsTracker("Page View", `/${username}/course/${courseid}`, `${username} ${courseid}`)

    }, [])

    const navigateToSection = (id) => {
        if (id === 'introduction') {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        } else {
            const section = document.getElementById(id);
            section.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }


    useEffect(() => {


        if (state?.courseid && state?.practitionerid) {

            let newUserFlag = localStorage.getItem(`new_user_${state.courseid}`)

            if (newUserFlag) {
                newUserFlag = false
            } else {
                localStorage.setItem(`new_user_${state.courseid}`, true)
                newUserFlag = true
            }

            Mixpanel.track(`/coursePageVisit/${state.courseid}`, {
                "new_user": newUserFlag
            })


        }


    }, [state])


    return (
        <>
            {!isLoading && !error && state &&
                <>
                    {/* For SEO */}
                    <Helmet>
                        <title>{practitioner} - {state.courseOverview.courseName}</title>
                        <meta
                            name='description'
                            content={`${state.courseOverview.courseDescription}`}
                        />
                        <meta
                            name='keywords'
                            content={`${state.courseOverview.courseTags.join(', ')}, ${state.courseOverview.courseName}`}
                        />
                        <link rel='canonical' href={`/${username}/course/${courseid}`} />
                    </Helmet>

                    <Introduction
                        state={state}
                        FAQs={FAQs}
                        username={username}
                        courseid={courseid}
                        navigateToSection={navigateToSection}
                    />
                    <Experience
                        state={state}
                    />
                    <Learning
                        state={state}
                        username={username}
                        courseid={courseid}
                    />
                    <Modules
                        state={state}
                        username={username}
                        courseid={courseid}
                        practitioner={practitioner}
                    />
                    <Testimonial
                        state={state}
                    />
                    <Instructor
                        state={state}
                        socialMedia={socialMedia}
                        practitioner={practitioner}
                        username={username}
                        usernameError={usernameError}
                    />
                    {
                        FAQs.length > 0
                        &&
                        <FAQ
                            state={state}
                        />
                    }
                    <Accessible
                        state={state}
                        username={username}
                        courseid={courseid}
                    />
                    {
                        state.courseOverview.courseCTA
                        &&
                        state.courseOverview.courseCTA.consultation
                        &&
                        <Consultation
                            state={state}
                            practitioner={practitioner}
                        />
                    }
                </>
            }

            {error && error === 'Course cannot be found' && <ErrorNoCourse />}
            {error && error === 'Username cannot be found' && <ErrorNoCourse />}
            {isLoading && !error && <LoadingSpinner />}
        </>
    )
}

export default CoursePage;